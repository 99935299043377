import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appLongPress]',
})
export class LongPressDirective implements OnDestroy {
  @Input() public longPressThresholdMs = 500;
  @Output() public longPressDown = new EventEmitter();
  @Output() public longPressUp = new EventEmitter();

  private mouseDownSubscription: Subscription;
  private mouseUpSubscription: Subscription;
  private touchStartSubscription: Subscription;
  private touchEndSubscription: Subscription;
  private timer: any | undefined;
  private longPressDetected = false;

  constructor(elementRef: ElementRef) {
    this.mouseDownSubscription = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousedown'
    )
      .pipe(
        filter((event) => event.button === 0) // Only allow left button (Primary button)
      )
      .subscribe(() => this.setTimer(this));
    this.mouseUpSubscription = fromEvent<MouseEvent>(window, 'mouseup')
      .pipe(
        filter((event) => event.button === 0) // Only allow left button (Primary button)
      )
      .subscribe(() => this.resetTimer(this));
    this.touchStartSubscription = fromEvent(
      elementRef.nativeElement,
      'touchstart'
    ).subscribe(() => this.setTimer(this));
    this.touchEndSubscription = fromEvent(
      elementRef.nativeElement,
      'touchend'
    ).subscribe(() => this.resetTimer(this));
  }

  public ngOnDestroy(): void {
    this.mouseDownSubscription?.unsubscribe();
    this.mouseUpSubscription?.unsubscribe();
    this.touchStartSubscription?.unsubscribe();
    this.touchEndSubscription?.unsubscribe();
  }

  private setTimer(self: this): void {
    self.timer = setTimeout(() => {
      self.longPressDown.emit();
      this.longPressDetected = true;
    }, self.longPressThresholdMs);
  }

  private resetTimer(self: this): void {
    if (self.timer) {
      if (self.longPressDetected) {
        self.longPressDetected = false;
        self.longPressUp.emit();
      }
      clearTimeout(self.timer);
    }
  }
}
