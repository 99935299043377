<div
  class="modal-content"
  [class.modal-full-height]="fullscreenModal"
  [style.--xl-card-body-padding]="fullscreenModal ? '0' : ''"
>
  <div id="xl-master-home-card-wrapper">
    <ng-container>
      <app-xl-home-card
        [homeCard]="homeCard"
        [showCloseButton]="true"
        [displayAsCard]="false"
        [qrCodeWidth]="300"
        [linkTarget]="linkTarget"
        (closeClicked)="closeModal()"
      ></app-xl-home-card>
    </ng-container>
  </div>
</div>
