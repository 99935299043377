export enum SubscriptionSTOMPKey {
  QANDA = 'qanda',
  VOTINGMASTER = 'votingMaster',
  VOTING = 'voting',
  CUSTOMERSERVICE = 'customerService',
  BREAKDELAY = 'breakDelay',
  MEETING = 'meeting',
  ROOM = 'room',
  EVENT = 'event',
  HEARTBEAT = 'heartbeat',
  MANAGERSETTINGS = 'managerSettings',
}
