import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutesSeconds',
})
export class HoursMinutesSecondsPipe implements PipeTransform {
  public transform(
    inputTime: number,
    inputUnit: 'seconds' | 'minutes' = 'seconds'
  ): string {
    // Example:
    // 128 min = 2:08
    // 128 seconds = 2:08
    // 3600 seconds = 1:00:00
    // 3730 seconds = 1:02:10
    const isNegative = inputTime < 0;
    let time = inputTime;

    if (isNegative) {
      time *= -1;
    }

    let hrs = 0;
    let mins = 0;
    let secs = 0;
    if (inputUnit === 'minutes') {
      hrs = Math.floor(time / 60);
      mins = time % 60;
      secs = 0;
    } else {
      hrs = Math.floor(time / 3600);
      mins = Math.floor((time % 3600) / 60);
      secs = Math.floor(time % 60);
    }

    let displayTime = '';

    if (hrs > 0) {
      displayTime += hrs + ':' + (mins < 10 ? '0' : '');
    }

    displayTime += mins + ':' + (secs < 10 ? '0' : '');
    displayTime += secs;

    if (isNegative) {
      displayTime = '-' + displayTime;
    }

    return displayTime;
  }
}
