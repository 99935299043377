import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  public errorMsg: string;
  constructor(private _router: ActivatedRoute) {}

  public ngOnInit(): void {
    this._router.queryParams.subscribe((params) => {
      this.errorMsg = params['errorMsg'];
    });
  }
}
