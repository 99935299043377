import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IFrameMessage,
  MessageType,
} from '../../../../ionic/src/app/models/iframe-event.model';
import { HomeCard } from '../../../models/home-card.model';
import {
  ParticipantQrCodeInfo,
  QrCodeInfoService,
} from '../../../services/qr-code-info.service';

@Component({
  selector: 'app-xl-home-card',
  templateUrl: './xl-home-card.component.html',
  styleUrls: ['./xl-home-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XlHomeCardComponent implements OnInit, AfterViewInit {
  @Input() public homeCard: HomeCard;
  @Input() public displayAsCard = true;
  @Input() public showCloseButton = false;
  @Input() public qrCodeWidth = 150;
  @Input() public linkTarget: 'iframe' | 'tab' | 'window' = 'iframe';

  @Output() public closeClicked: EventEmitter<void> = new EventEmitter();

  @ViewChild('homeCardBody') private elementRef: ElementRef;

  public participantQrCodeInfo: ParticipantQrCodeInfo;

  constructor(private qrCodeInfoService: QrCodeInfoService) {}

  public ngOnInit(): void {
    if (!this.homeCard) {
      this.participantQrCodeInfo =
        this.qrCodeInfoService.getParticipantQrCodeInfo();
    }
  }

  public onCloseClicked(): void {
    this.closeClicked.emit();
  }

  public ngAfterViewInit() {
    if (this.linkTarget === 'tab' || this.linkTarget === 'iframe') {
      return;
    }

    const anchors = this.elementRef?.nativeElement.querySelectorAll('a') ?? [];
    anchors.forEach((anchor: HTMLAnchorElement) => {
      anchor.onclick = (event: MouseEvent) => {
        event.preventDefault();
        const href = anchor.getAttribute('href');
        window.parent.postMessage(
          IFrameMessage.construct('bh-participant', MessageType.LINK_CLICKED, {
            href,
          }),
          '*'
        );
        return false;
      };
    });
  }
}
