import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Room } from '../models/room.model';
import { ApplicationHttpClient } from './application-http-client';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  private url = 'api/room/';

  constructor(private http: ApplicationHttpClient) {}

  public updateRoom(room: Room): Observable<Room> {
    return this.http.put<Room>(this.url, room);
  }

  public createRoom(room: Room): Observable<Room> {
    return this.http.post<Room>(this.url, room);
  }

  public deleteRoom(roomId: string): Observable<void> {
    return this.http.delete<void>(this.url + roomId);
  }
}
