import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { LocalStorageService } from 'projects/shared/services/local-storage.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { ResettableService } from '../../../services/resettable.service';
import { LogoutModalComponent } from '../../logout-modal/logout-modal.component';

export enum NavItemType {
  COMMON = 'common',
  LOGOUT = 'logout',
  BADGE = 'badge',
  SPACER = 'spacer',
  TEMPLATE = 'template',
}

export interface NavItem {
  routerLink: string;
  title: string;
  iconStyleClass: string;
  type: NavItemType;
  useCustomIcon: boolean;
  iconSrc: string;
  hideResponsive?: boolean;
  action?: () => void;
  templateRef?: TemplateRef<any>;
}

export interface SideNavOptions {
  style: 'row' | 'column';
}

@Component({
  selector: 'app-generic-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  @Input() public navItems: NavItem[];
  @Input() public doneBeforeLogout: Observable<void>;
  @Input() public logoutModalBody: string;
  @Output() public doBeforeLogout: EventEmitter<void> = new EventEmitter();
  @Output() public itemClicked: EventEmitter<void> = new EventEmitter();

  public navItemTypes = NavItemType;

  private modalRef: MDBModalRef;

  constructor(
    private modalService: MDBModalService,
    private authService: AuthService,
    private resettableService: ResettableService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  public logout(): void {
    this.modalRef = this.modalService.show(LogoutModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-dialog-centered',
      data: {
        title: 'global.logout',
        body: this.logoutModalBody
          ? this.logoutModalBody
          : 'global.logout-message',
      },
    });

    this.modalRef.content.confirmed
      .pipe(take(1))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          if (this.doneBeforeLogout) {
            this.doneBeforeLogout.subscribe(() => {
              // Tasks before logout are done.
              this.performFinalLogout();
            });
            this.doBeforeLogout.emit();
          } else {
            // No callback implemented, do logout immediately.
            this.performFinalLogout();
          }
        }
      });
  }

  private performFinalLogout(): void {
    const isBeyondAdmin = this.localStorageService.loadIsBeyondAdmin();

    this.authService.logout(() => {
      this.resettableService.resetNow.next(true);

      if (isBeyondAdmin) {
        this.router.navigate(['beyond-admin-login']);
      } else {
        this.router.navigate(['login']);
      }
    });
  }
}
