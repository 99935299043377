import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
  FILE_UPLOADABLE_SERVICE,
  FileUploadableService,
} from '../models/file-uploadable-service.model';
import { ImageFile } from '../models/image-file.model';
import {
  JobConfig,
  JobResponse,
  JobResultType,
} from '../modules/image-processing/job-chain/model/job-chain';
import { JobChainService } from '../modules/image-processing/job-chain/services/job-chain.service';
import { BhToastService } from './bh-toast.service';

@Injectable()
export class FileUploadState implements OnDestroy {
  public autoUpload = false;
  public showFileInput = false;
  public onSuccess: Subject<ImageFile[]> = new Subject<ImageFile[]>();
  public onError: Subject<boolean> = new Subject<boolean>();
  public isUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  jobConfig: JobConfig<File>;
  jobSubscription: Subscription;

  public files: File[] = [];
  public selectedFilesDisplay = '';
  public uploadRetries = 0;
  public MAX_UPLOAD_RETRIES = 3;
  public allowedMimeType: RegExp;

  constructor(
    @Inject(FILE_UPLOADABLE_SERVICE)
    private fileService: FileUploadableService<ImageFile>,
    private toastService: BhToastService,
    private jobChainService: JobChainService<File>
  ) {}

  public ngOnDestroy(): void {
    this.jobSubscription?.unsubscribe();
  }

  public setupUpload(
    autoUpload: boolean,
    showFileInput: boolean,
    allowedMimeType: RegExp,
    jobConfig: JobConfig<File>
  ): void {
    this.autoUpload = autoUpload;
    this.showFileInput = showFileInput;
    this.allowedMimeType = allowedMimeType;
    this.jobConfig = jobConfig;
  }

  public triggerUpload(hotelId: string): void {
    this.jobSubscription?.unsubscribe();
    if (!this.files || this.files.length === 0) {
      return;
    }

    if (this.jobConfig && this.jobConfig.jobs?.length > 0) {
      const allDoneSub = new Subject<JobResponse<File>[]>();
      this.jobSubscription = allDoneSub.subscribe((jobResponses) => {
        const processedFiles = [];
        for (const jobResponse of jobResponses) {
          const hasFailedOrCancelledJobs = !!jobResponse.results.find(
            (jobResult) => {
              return (
                jobResult.type === JobResultType.CANCEL ||
                jobResult.type === JobResultType.ERROR
              );
            }
          );
          if (!hasFailedOrCancelledJobs) {
            processedFiles.push(jobResponse.item);
          }
        }

        if (processedFiles.length > 0) {
          // Image processing done.
          this.startUpload(processedFiles, hotelId);
        } else {
          // If the file input is not displayed, the user can not see that files are still in the pipeline.
          // This would lead to a misleading toast message that says the file is already selected.
          // In this case we need to clear the list of selected files even if the image processing is cancelled.
          if (!this.showFileInput) {
            this.clearFiles();
          }
        }
      });

      this.jobChainService.processJobsForItems(
        this.files,
        this.jobConfig,
        allDoneSub
      );
    } else {
      this.startUpload(this.files, hotelId);
    }
  }

  private startUpload(files: File[], id: string): void {
    this.isUploading.next(true);

    this.fileService.uploadFiles(files, id).subscribe(
      (response) => {
        console.log('response', response);
        this.handleUploadSuccess(response);
      },
      (_: HttpErrorResponse) => {
        this.handleUploadError();
      }
    );
  }

  public onFilesDisplayTextChange(): void {
    this.updateSelectedFilesDisplay();
  }

  public onFileInput(event: any, id: string): void {
    let files: File[] = Object.assign([], event.target.files);

    // Show warning toast if files with not allowed mime type has been selected.
    files.find((file) => {
      if (this.allowedMimeType && !this.allowedMimeType.test(file.type)) {
        this.toastService.error('global.files.invalid-format');
        return true;
      }

      return false;
    });

    // Filter all files with allowed mime types.
    files = files.filter((file) => {
      return (
        !this.allowedMimeType ||
        (this.allowedMimeType && this.allowedMimeType.test(file.type))
      );
    });

    // If the automatic upload is enabled clear the file list to prevent multiple uploads of the same file.
    // This can happen when an upload is still running but the user selects the next file which would trigger
    // an upload of the current file list (including old files) again.
    if (this.autoUpload) {
      this.clearFiles();
    }

    this.addFiles(files);
    this.setupSelectedFilesDisplay();

    if (this.autoUpload === true) {
      this.triggerUpload(id);
    }
  }

  public onClearButtonClick(): void {
    this.clearFiles();
  }

  private handleUploadSuccess(response: ImageFile[]): void {
    this.toastService.success('global.file-upload.success');
    this.uploadRetries = 0;
    this.clearFiles();
    this.onSuccess.next(response);
    this.isUploading.next(false);
  }

  private handleUploadError(error?: HttpErrorResponse): void {
    this.toastService.error('global.file-upload.error');
    this.onError.next();
    this.isUploading.next(false);
    // If the file input field is invisible (not used) clear all selected files, because there is no other option to do this.
    if (!this.showFileInput) {
      this.clearFiles();
    }
  }

  private setupSelectedFilesDisplay(): void {
    let files = '';
    for (let i = 0; i < this.files.length; i++) {
      files += this.files[i].name;
      if (!(this.files.length - 1 === i)) {
        files += ', ';
      }
    }

    this.selectedFilesDisplay = files;
  }

  public clearFiles(): void {
    this.files = [];
    this.selectedFilesDisplay = '';
  }

  private updateSelectedFilesDisplay(): void {
    // Split files display text into single file names.
    let splitted: string[] = this.selectedFilesDisplay
      .split(',')
      .map((value) => value.trim());

    // Delete files which are not included in the display text anymore.
    this.files = this.files.filter((file) => {
      return splitted.find((value) => value === file.name);
    });

    // Delete the text from the display for which no more files
    // with the corresponding name can be found.
    splitted = splitted.filter((value) => {
      return this.files.find((file) => file.name === value);
    });

    this.selectedFilesDisplay = splitted.toString();
  }

  private addFiles(files: File[]): void {
    const newFiles = files.filter((f1) => {
      return !this.files.find((f2) => {
        const sameFile =
          f1.name === f2.name &&
          f1.type === f2.type &&
          f1.size === f2.size &&
          f1.lastModified === f2.lastModified;
        if (sameFile) {
          this.toastService.success('global.files.already-selected');
        }
        return sameFile;
      });
    });

    this.files.push(...newFiles);
  }
}
