import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StompSubscription } from '@stomp/stompjs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { SubscriptionSTOMPKey } from '../models/subscriptionSTOMPKey.model';
import { Voting } from '../models/voting.model';
import { ApplicationHttpClient } from './application-http-client';
import { ApplicationWebsocketClient } from './application-websocket-client';
import { BhToastService } from './bh-toast.service';
import { LocalStorageService } from './local-storage.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ResettableService } from './resettable.service';
import { VotingCountdownService } from './voting-countdown.service';

@Injectable({
  providedIn: 'root',
})
export class VotingService {
  private _url = 'api/vote/';
  private _websocketSubscriptions: StompSubscription[] = [];

  public previousVotingTimer: number = undefined;
  public voting$: BehaviorSubject<Voting> = new BehaviorSubject(undefined);
  public votingMaster$: BehaviorSubject<Voting> = new BehaviorSubject(
    undefined
  );
  public showVotingResult$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _http: ApplicationHttpClient,
    private _websocket: ApplicationWebsocketClient,
    private toastService: BhToastService,
    private _votingCountdown: VotingCountdownService,
    private _resettableService: ResettableService,
    private _localStorageService: LocalStorageService,
    private _loggerService: LoggerService,
    private localizationService: LocalizationService
  ) {
    this._resettableService.resetNow.subscribe((_) => {
      this._clearVotingService();
    });
  }

  public startVotingWebSocketConnection(isParticipant: boolean): void {
    if (this._localStorageService.loadMeeting() === null) {
      this._loggerService.logDebugWarn(
        'WARNING: Trying to establish the voting websocket connection before meetingId has loaded.'
      );
    }
    // Check if the client is currently set
    this.getCurrentActiveVoting().subscribe((voting) => {
      if (voting) {
        this.previousVotingTimer = voting.timer;
        this.voting$.next(voting);
        this.votingMaster$.next(voting);
      }
    });

    if (!isParticipant) {
      this._websocket.subscribeToTopic(SubscriptionSTOMPKey.VOTINGMASTER, () =>
        this.subscribeToVotingMasterWebsocketConnection()
      );
    }
    this._websocket.subscribeToTopic(SubscriptionSTOMPKey.VOTING, () =>
      this.subscribeToVotingWebsocketConnection()
    );
  }

  private _clearVotingService(): void {
    if (!(this.voting$ && this.votingMaster$)) {
      return;
    }

    // voting$ includes votingMaster$ => only one has to be stopped (since they represent the same voting)
    // voting$ sends on the websocket topic where the participants listen to
    const voting = this.voting$.getValue();
    if (voting && voting.active) {
      voting.active = false;
      this.voting$.next(voting);

      const votingMaster = this.votingMaster$?.getValue();
      if (votingMaster) {
        votingMaster.active = false;
        this.votingMaster$.next(votingMaster);
      }

      this.stopVoting(voting.id).subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      );
    }

    this._votingCountdown.stopCountdownEarly();
    this.showVotingResult$.next(false);
    this._websocketSubscriptions.forEach((websocketSubscription) => {
      websocketSubscription?.unsubscribe();
    });
    this._websocketSubscriptions = [];
    this._websocket.disconnectOfSubscription(SubscriptionSTOMPKey.VOTINGMASTER);
    this._websocket.disconnectOfSubscription(SubscriptionSTOMPKey.VOTING);
  }

  public subscribeToVotingWebsocketConnection(): void {
    this._loggerService.logDebug(
      'INFO: Establishing voting websocket connection for meetingId: ' +
        this._localStorageService.loadMeeting()
    );
    const meetingId = this._localStorageService.loadMeeting();

    const websocketSubscription = this._websocket.client.subscribe(
      '/topic/vote/'.concat(meetingId),
      (response) => {
        const voting: Voting = JSON.parse(response.body) as Voting;
        if (voting) {
          this.previousVotingTimer = voting.timer;
          this.voting$.next(voting);
          if (!voting.active) {
            this.toastService.info('master.voting-done');
          }
        }
      }
    );
    this._websocketSubscriptions.push(websocketSubscription);
  }

  public subscribeToVotingMasterWebsocketConnection(): void {
    this._loggerService.logDebug(
      'INFO: Establishing voting websocket connection for master for meetingId: ' +
        this._localStorageService.loadMeeting()
    );
    const meetingId = this._localStorageService.loadMeeting();

    const websocketSubscription = this._websocket.client.subscribe(
      '/topic/master/vote/'.concat(meetingId),
      (response) => {
        const votingMaster: Voting = JSON.parse(response.body) as Voting;
        if (votingMaster) {
          this.votingMaster$.next(votingMaster);
        }
      }
    );
    this._websocketSubscriptions.push(websocketSubscription);
  }

  public getCurrentActiveVoting(): Observable<Voting> {
    if (this._localStorageService.loadMeeting() !== null) {
      return this._http.get<Voting>(
        this._url.concat(this._localStorageService.loadMeeting())
      );
    } else {
      return of(null);
    }
  }

  public computeRemainingTimer(voting: Voting): Observable<number> {
    return this.getCurrentTimeInMillisFromServer().pipe(
      take(1),
      map((currentTimeInMillis) => {
        const now = new Date();
        if (!currentTimeInMillis) {
          currentTimeInMillis = now.getTime();
        }
        currentTimeInMillis += now.getTimezoneOffset() * 60 * 1000;

        return Math.max(
          Math.floor(
            voting.timer -
              (currentTimeInMillis - Date.parse(voting.created)) / 1000
          ),
          0
        );
      })
    );
  }

  // The created voting will update voting$
  public createVoting(voting: Voting): Observable<Voting> {
    return this._http.post<Voting>(this._url, voting);
  }

  // The updated voting will update voting$
  public stopVoting(votingId: string): Observable<Voting> {
    return this._http.put<Voting>(
      this._url.concat(this._localStorageService.loadMeeting(), '/', votingId),
      null
    );
  }

  // Send
  public sendMailWithAllVotings(
    email: string,
    mailLang?: string
  ): Observable<void> {
    const lang = mailLang ? mailLang : this.localizationService.currentLangKey;

    return this._http.get<void>(
      this._url.concat('email/', this._localStorageService.loadMeeting()),
      {
        params: new HttpParams().set('email', email).set('lang', lang),
      }
    );
  }

  private getCurrentTimeInMillisFromServer(): Observable<number> {
    return this._http.get<number>(this._url.concat('current-time'), null);
  }
}
