import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { App } from '../environments/app';
import { environment } from '../environments/environment';
import { ApplicationHttpClient } from '../services/application-http-client';
import { ApplicationWebsocketClient } from '../services/application-websocket-client';
import { AuthService } from '../services/auth.service';
import { BhToastService } from '../services/bh-toast.service';
import { ConnectionService } from '../services/connection.service';
import { CustomerServiceService } from '../services/customer-service.service';
import { EnvironmentService } from '../services/environment.service';
import { ExportService } from '../services/export.service';
import { FeedbackService } from '../services/feedback.service';
import { FileUploadState } from '../services/file-upload-state.service';
import { HeartbeatService } from '../services/heartbeat.service';
import { LocalStorageService } from '../services/local-storage.service';
import { LocalizationService } from '../services/localization.service';
import { MusicService } from '../services/music.service';
import { PlayerService } from '../services/player.service';
import { QandaService } from '../services/qanda.service';
import { ResettableService } from '../services/resettable.service';
import { VotingCountdownService } from '../services/voting-countdown.service';
import { VotingService } from '../services/voting.service';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  providers: [
    // Services with state
    VotingService,
    VotingCountdownService,
    ResettableService,
    PlayerService,
    MusicService,
    LocalStorageService,
    ExportService,
    EnvironmentService,
    CustomerServiceService,
    ConnectionService,
    HeartbeatService,
    QandaService,
    ApplicationWebsocketClient,
    AuthService,
    FeedbackService,
    LocalizationService,
    BhToastService,
    FileUploadState,
    // No state but should also be instanciated only once.
    ApplicationHttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ConnectionService, LocalizationService, LocalStorageService],
      multi: true,
    },
  ],
  declarations: [],
  exports: [],
})
/**
 * This module is provided only once in every app (AppModule)
 * and provides all injectables with global states.
 */
export class ProjectModule {}

export function appInitializerFactory(
  connectionService: ConnectionService,
  localizationService: LocalizationService,
  localStorageService: LocalStorageService
): any {
  return () => {
    // Initialize the connection service for all apps.
    connectionService.init();
    // Translate the app.
    switch (environment.app) {
      case App.MANAGER_APP:
      case App.MASTER_APP:
        const lang = localStorageService.loadSelectedLanguage();
        console.log(
          'ProjectModule: translate ' + environment.app + ' to ' + lang
        );
        if (lang) {
          localizationService.translateApp(lang);
        } else {
          localizationService.translateAppDefault();
        }
        break;
      case App.PARTICIPANT_APP:
        console.log('ProjectModule: translate PARTICIPANT_APP');
        // Translate app in browser language or default.
        localizationService.translateAppDefault();
        break;
      default:
        console.log('ProjectModule: translate ' + environment.app + ' to EN');
        localizationService.translateApp('en');
    }
  };
}
