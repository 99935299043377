import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
export class BhToastService {
  constructor(
    private localizationService: LocalizationService,
    private toastr: ToastrService
  ) {}

  public error(message: string, title?: string): void {
    const messageTranslated =
      this.localizationService.getTranslationForKey(message);
    const titleTranslated = title
      ? this.localizationService.getTranslationForKey(title)
      : undefined;
    this.toastr.error(messageTranslated, titleTranslated);
  }

  public warning(message: string, title?: string): void {
    const messageTranslated =
      this.localizationService.getTranslationForKey(message);
    const titleTranslated = title
      ? this.localizationService.getTranslationForKey(title)
      : undefined;
    this.toastr.warning(messageTranslated, titleTranslated);
  }

  public success(message: string, title?: string): void {
    const messageTranslated =
      this.localizationService.getTranslationForKey(message);
    const titleTranslated = title
      ? this.localizationService.getTranslationForKey(title)
      : undefined;
    this.toastr.success(messageTranslated, titleTranslated);
  }

  public successChunked(messageParts: string[], titleParts?: string[]): void {
    let messageTranslated = '';

    messageParts.forEach((part) => {
      messageTranslated += this.localizationService.getTranslationForKey(part);
    });

    let titleTranslated = '';

    titleParts?.forEach((part) => {
      titleTranslated += this.localizationService.getTranslationForKey(part);
    });

    this.toastr.success(messageTranslated, titleTranslated);
  }

  public info(message: string, title?: string): void {
    const messageTranslated =
      this.localizationService.getTranslationForKey(message);
    const titleTranslated = title
      ? this.localizationService.getTranslationForKey(title)
      : undefined;
    this.toastr.info(messageTranslated, titleTranslated);
  }

  public infoChunked(messageParts: string[], titleParts?: string[]): void {
    let messageTranslated = '';

    messageParts.forEach((part) => {
      messageTranslated += this.localizationService.getTranslationForKey(part);
    });

    let titleTranslated = '';

    titleParts?.forEach((part) => {
      titleTranslated += this.localizationService.getTranslationForKey(part);
    });

    this.toastr.info(messageTranslated, titleTranslated);
  }
}
