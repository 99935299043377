<div class="relative-Container d-flex justify-content-center">
  <circle-progress
    [percent]="votingCountdownService.percent$ | async"
    [radius]="82"
    [title]="(votingCountdownService.remainingTime$ | async).toString()"
    [outerStrokeWidth]="10"
    [innerStrokeWidth]="6"
    [animation]="true"
    [animationDuration]="300"
    [titleFontSize]="'52'"
  ></circle-progress>
</div>
