export interface QandA {
  id?: string;
  question: string;
  state?: QandAType;
  meetingId?: string;
}

export enum QandAType {
  OPEN = 'open',
  ANSWERED = 'answered',
  SAVED = 'saved',
}

export interface QandAEvent {
  question: QandA;
  oldType?: QandAType;
  action: EventAction;
}

export enum EventAction {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface GroupedQuestion {
  time: number;
  totalNumberOfQuestions: number;
}
