import { Injectable } from '@angular/core';
import { ParticipantAppFeedbackChoice } from '../models/participant-app-feedback.model';
import { SUPPORTED_LANGUAGE_TYPE } from '../util/util';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _hotelIdPlaceholder = 'hotelId';
  private _roomIdPlaceholder = 'roomId';
  private _meetingIdPlaceholder = 'meetingId';
  private _meetingStartDatePlaceholder = 'meetingStartDate';
  private _roomNamePlaceholder = 'roomName';
  private _isBeyondAdminPlaceholder = 'isBeyondAdmin';
  private _isIonicAppPlaceholder = 'isIonicApp';
  private _selectedLanguage = 'selected_language';
  private _meetingIdFeedbackPlaceholder = 'meetingIdFeedback';
  private _pushTokenPlaceholder = 'pushToken';
  private _submittedLastFeedbackPlaceholder = 'submittedLastFeedback';
  private _lastFeedbackChoicePlaceholder = 'lastFeedbackChoice';
  private _pwaModal = 'pwaModal';

  constructor() {}

  public saveRoom(room: string): void {
    localStorage.setItem(this._roomIdPlaceholder, room);
  }

  public removeRoom(): void {
    localStorage.removeItem(this._roomIdPlaceholder);
  }

  public saveRoomName(room: string): void {
    localStorage.setItem(this._roomNamePlaceholder, room);
  }

  public loadRoomName(): string {
    return localStorage.getItem(this._roomNamePlaceholder);
  }

  public loadRoom(): string {
    return localStorage.getItem(this._roomIdPlaceholder);
  }

  public removeHotel(): void {
    localStorage.removeItem(this._hotelIdPlaceholder);
  }

  public saveHotel(hotel: string): void {
    localStorage.setItem(this._hotelIdPlaceholder, hotel);
  }

  public loadHotel(): string {
    return localStorage.getItem(this._hotelIdPlaceholder);
  }

  public saveMeeting(meeting: string): void {
    localStorage.setItem(this._meetingIdPlaceholder, meeting);
  }

  public loadMeeting(): string {
    return localStorage.getItem(this._meetingIdPlaceholder);
  }

  public removeMeeting(): void {
    localStorage.removeItem(this._meetingIdPlaceholder);
  }

  public saveMeetingStartDate(startDate: string): void {
    localStorage.setItem(this._meetingStartDatePlaceholder, startDate);
  }

  public loadMeetingStartDate(): string {
    return localStorage.getItem(this._meetingStartDatePlaceholder);
  }

  public removeMeetingStartDate(): void {
    localStorage.removeItem(this._meetingStartDatePlaceholder);
  }

  public saveIsBeyondAdmin(isBeyondAdmin: boolean): void {
    localStorage.setItem(this._isBeyondAdminPlaceholder, String(isBeyondAdmin));
  }

  public loadIsBeyondAdmin(): boolean {
    const stringValue = localStorage.getItem(this._isBeyondAdminPlaceholder);
    return stringValue === 'true';
  }

  public saveIsIonicApp(isIonicApp: boolean): void {
    localStorage.setItem(this._isIonicAppPlaceholder, String(isIonicApp));
  }

  public loadIsIonicApp(): boolean {
    const stringValue = localStorage.getItem(this._isIonicAppPlaceholder);
    return stringValue === 'true';
  }

  public saveSelectedLanguage(lang: SUPPORTED_LANGUAGE_TYPE): void {
    localStorage.setItem(this._selectedLanguage, lang);
  }

  public loadSelectedLanguage(): SUPPORTED_LANGUAGE_TYPE {
    return localStorage.getItem(
      this._selectedLanguage
    ) as SUPPORTED_LANGUAGE_TYPE;
  }

  public saveMeetingIdFeedback(meetingId: string): void {
    localStorage.setItem(this._meetingIdFeedbackPlaceholder, meetingId);
  }

  public loadMeetingIdFeedback(): string {
    return localStorage.getItem(this._meetingIdFeedbackPlaceholder);
  }

  public removeMeetingIdFeedback(): void {
    localStorage.removeItem(this._meetingIdFeedbackPlaceholder);
  }

  public savePushToken(pushToken: string): void {
    localStorage.setItem(this._pushTokenPlaceholder, pushToken);
  }

  public loadPushToken(): string {
    return localStorage.getItem(this._pushTokenPlaceholder);
  }

  public removePushToken(): void {
    localStorage.removeItem(this._pushTokenPlaceholder);
  }

  public saveSubmittedLastFeedback(timestamp: number): void {
    if (!timestamp) {
      return;
    }
    localStorage.setItem(
      this._submittedLastFeedbackPlaceholder,
      timestamp.toString()
    );
  }

  public loadSubmittedLastFeedback(): number {
    const timestamp = localStorage.getItem(
      this._submittedLastFeedbackPlaceholder
    );
    return timestamp ? Number(timestamp) : null;
  }

  public saveLastFeedbackChoice(choice: ParticipantAppFeedbackChoice): void {
    localStorage.setItem(this._lastFeedbackChoicePlaceholder, choice);
  }

  public loadLastFeedbackChoice(): ParticipantAppFeedbackChoice {
    return localStorage.getItem(
      this._lastFeedbackChoicePlaceholder
    ) as ParticipantAppFeedbackChoice;
  }

  public loadPwaModal(): boolean {
    const stringValue = localStorage.getItem(this._pwaModal);
    return stringValue === 'true';
  }

  public savePwaModal(pwaModal: boolean): void {
    localStorage.setItem(this._pwaModal, String(pwaModal));
  }
}
