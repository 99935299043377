<!-- Current Time -->
<div>
  {{ currentTime | localizedDate: 'dd LLL H:mm' }}
  <!-- Running timer -->
  <div *ngIf="isTimerRunning" style="font-size: small">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-bell fa-sm mr-1"></i>
      <span>
        {{
          timerService.currentTime$ | async | localizedDate: 'HH:mm:ss':'UTC'
        }}
      </span>
    </div>
  </div>
</div>
