import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneFilter',
})
export class TimeZoneFilterPipe implements PipeTransform {
  transform(timeZones: string[], filterBy?: string): string[] {
    if (filterBy) {
      return timeZones.filter((timeZone) =>
        timeZone.toLowerCase().includes(filterBy.toLowerCase())
      );
    } else {
      return timeZones;
    }
  }
}
