import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
})
export class ObjectKeysPipe<T> implements PipeTransform {
  /**
   * Returns all keys of the given object except those passed in the first argument.
   * @param value Object which properties/keys should be returned.
   * @param exept Keys that are to be excluded.
   * @returns All keys of the specified object except the specified ones to be excluded.
   */
  public transform(value: T, exept?: string[]): string[] {
    const keys = Object.keys(value).filter((k) => {
      return exept?.length > 0 ? !exept.includes(k) : true;
    });
    return keys;
  }
}
