import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupedFeedback } from '../models/feedback.model';
import { GroupedQuestion } from '../models/qanda.model';
import { AbstractUser } from '../models/user.model';
import { GroupedVoting } from '../models/voting.model';
import { ApplicationHttpClient } from './application-http-client';

@Injectable({
  providedIn: 'root',
})
export class HotelAdminService {
  private url = 'api/hotel-admin/';

  constructor(private http: ApplicationHttpClient) {}

  public logoutDevice(heartbeatId: string): Observable<void> {
    return this.http.post<void>(this.url + 'logout/' + heartbeatId, {});
  }

  public getHotelUsers(hotelId: string): Observable<AbstractUser[]> {
    return this.http.get<AbstractUser[]>(this.url + 'users/' + hotelId);
  }

  public updateUser(user: AbstractUser): Observable<AbstractUser> {
    return this.http.post<AbstractUser>(this.url + 'user/update', user);
  }

  public createUser(user: AbstractUser): Observable<AbstractUser> {
    return this.http.post<AbstractUser>(this.url + 'user/create', user);
  }

  public deleteUser(user: AbstractUser): Observable<void> {
    return this.http.post<void>(this.url + 'user/delete', user);
  }

  public getGroupedVotings(
    hotelId: string,
    meetingId: string
  ): Observable<GroupedVoting[]> {
    return this.http.get<GroupedVoting[]>(
      this.url + 'analytics/votings/' + hotelId + '/' + meetingId
    );
  }

  public getGroupedQuestions(
    hotelId: string,
    meetingId: string
  ): Observable<GroupedQuestion[]> {
    return this.http.get<GroupedQuestion[]>(
      this.url + 'analytics/questions/' + hotelId + '/' + meetingId
    );
  }

  public getGroupedFeedback(
    hotelId: string,
    meetingId: string
  ): Observable<GroupedFeedback[]> {
    return this.http.get<GroupedFeedback[]>(
      this.url + 'analytics/feedback/' + hotelId + '/' + meetingId
    );
  }

  public sendMailWithAllFeedback(
    hotelId: string,
    meetingId: string,
    email: string
  ): Observable<void> {
    return this.http.get<void>(
      this.url + 'email/feedback/' + hotelId + '/' + meetingId,
      {
        params: new HttpParams().set('email', email),
      }
    );
  }

  public sendMailWithAllVotings(
    hotelId: string,
    meetingId: string,
    email: string
  ): Observable<void> {
    return this.http.get<void>(
      this.url + 'email/votings/' + hotelId + '/' + meetingId,
      {
        params: new HttpParams().set('email', email),
      }
    );
  }
}
