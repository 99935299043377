import { Component } from '@angular/core';
import { LocalStorageService } from 'projects/shared/services/local-storage.service';
import { LocalizationService } from 'projects/shared/services/localization.service';
import {
  SUPPORTED_LANGUAGE_TYPE,
  SUPPORTED_LANGUAGES,
} from 'projects/shared/util/util';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  public selectedLanguage: SUPPORTED_LANGUAGE_TYPE;
  public supportedLanguages = SUPPORTED_LANGUAGES;

  // Flag codes from https://www.alt-codes.net/flags
  public languageFlags: Map<SUPPORTED_LANGUAGE_TYPE, string> = new Map([
    ['en', '&#127482&#127480'],
    ['de', '&#127465&#127466'],
  ]);

  constructor(
    private localizationService: LocalizationService,
    private localStorageService: LocalStorageService
  ) {
    this.loadSelectedLanguage();
  }

  private loadSelectedLanguage(): void {
    const selectedLanguage = this.localStorageService.loadSelectedLanguage();
    this.selectedLanguage = selectedLanguage
      ? selectedLanguage
      : this.localizationService.currentLangKey;
  }

  public onSelectLanguage(lang: SUPPORTED_LANGUAGE_TYPE): void {
    this.selectedLanguage = lang;
    this.localStorageService.saveSelectedLanguage(lang);
    // App is translated on window reload
    // Window reload fixes DOMException issue and that every component is translated
    window.location.reload();
  }
}
