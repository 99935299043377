import { Injectable, OnDestroy } from '@angular/core';
import { StompSubscription } from '@stomp/stompjs';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  DeviceInfo,
  DeviceInfoWebSocketEvent,
  Heartbeat,
} from '../models/heartbeat.model';
import { SubscriptionSTOMPKey } from '../models/subscriptionSTOMPKey.model';
import { ApplicationHttpClient } from './application-http-client';
import { ApplicationWebsocketClient } from './application-websocket-client';
import { LocalStorageService } from './local-storage.service';
import { ResettableService } from './resettable.service';

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService implements OnDestroy {
  private url = 'api/heartbeat';

  public changeInHeartbeats: BehaviorSubject<DeviceInfoWebSocketEvent> =
    new BehaviorSubject<DeviceInfoWebSocketEvent>(undefined);
  private websocketSubscription: StompSubscription;
  private websocketSubscriptions: StompSubscription[] = [];

  constructor(
    private _http: ApplicationHttpClient,
    private localStorageService: LocalStorageService,
    private websocket: ApplicationWebsocketClient,
    private resettableService: ResettableService
  ) {
    this.resettableService.resetNow.subscribe((_) => {
      this.clearWebsocketSubscription();
    });
  }

  public ngOnDestroy(): void {
    this.clearWebsocketSubscription();
  }

  public deleteHeartbeat(heartbeatId: string): Observable<void> {
    return this._http.delete(this.url + '/' + heartbeatId);
  }

  public deleteHeartbeats(ids: string[]): Observable<void> {
    return this._http.post(this.url + '/batch/delete', ids);
  }

  public setHeartbeat(
    heartbeat: Heartbeat,
    ping: boolean = false
  ): Observable<Heartbeat | void> {
    return ping
      ? this._http.post<void>(this.url + '?ping=true', undefined)
      : this._http.post<Heartbeat>(this.url, heartbeat);
  }

  public getHeartbeats(hotelId?: number): Observable<Heartbeat[]> {
    return this._http.get<Heartbeat[]>(
      this.url +
        '/' +
        (hotelId ? hotelId : this.localStorageService.loadHotel())
    );
  }

  private clearWebsocketSubscription(): void {
    this.websocketSubscriptions?.forEach((websocketSubscription) => {
      websocketSubscription?.unsubscribe();
    });
    this.websocketSubscription?.unsubscribe();
    this.websocket?.disconnectOfSubscription(SubscriptionSTOMPKey.HEARTBEAT);
    this.websocketSubscriptions = [];
  }

  public startWebSocketConnection(): void {
    this.websocket.subscribeToTopic(SubscriptionSTOMPKey.HEARTBEAT, () =>
      this.subscribeToHeartbeatChangeWebsocketConnection()
    );
  }

  public subscribeToHeartbeatChangeWebsocketConnection(): void {
    this.websocketSubscription = this.websocket.client.subscribe(
      '/topic/heartbeat/' + this.localStorageService.loadHotel(),
      (response) => {
        const heartbeatEvent: DeviceInfoWebSocketEvent = Object.assign(
          new DeviceInfoWebSocketEvent(),
          JSON.parse(response.body)
        );

        this.changeInHeartbeats?.next(heartbeatEvent);
      }
    );
  }

  public getDeviceInfoList(hotelId?: string): Observable<DeviceInfo[]> {
    return this._http.get<DeviceInfo[]>(
      this.url +
        '/device-info/' +
        (hotelId ? hotelId : this.localStorageService.loadHotel()),
      {
        withCredentials: true,
      }
    );
  }
}
