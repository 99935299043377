import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlayerService } from '../../../../shared/services/player.service';
import { ResettableService } from '../../../../shared/services/resettable.service';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  public currentTime$ = new BehaviorSubject<Date>(new Date(0));
  public timerIsRunning$ = new BehaviorSubject<boolean>(false);
  private timerId: ReturnType<typeof setInterval>;
  private doGong = false;

  constructor(
    private songService: PlayerService,
    private resettableService: ResettableService
  ) {
    this.setTimer(0);

    this.resettableService.resetNow.subscribe((_) => {
      if (this.timerIsRunning$.getValue()) {
        clearInterval(this.timerId);
      }
      this.resetTimer();
    });
  }

  public setTimer(seconds: number): void {
    this.pauseTimer();
    this.currentTime$.next(new Date(seconds * 1000));
  }

  public startTimer(): void {
    if (this.timerIsRunning$.value) {
      return;
    }
    this.doGong = true;
    this.timerIsRunning$.next(true);

    this.timerId = setInterval(() => {
      const currentTime = this.currentTime$.getValue();
      this.currentTime$.next(new Date(currentTime.getTime() - 1000));
      if (this.currentTime$.getValue().getTime() <= 0) {
        clearInterval(this.timerId);
        this.resetTimer();
        if (this.doGong) {
          this.doGong = false;
          this.songService.playGongSound();
        }
      }
    }, 1000);
  }

  public pauseTimer(): void {
    this.timerIsRunning$.next(false);
    clearInterval(this.timerId);
  }

  public resetTimer(): void {
    this.timerIsRunning$.next(false);
    this.currentTime$.next(new Date(0));
  }
}
