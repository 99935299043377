import { EventType } from './event.model';
import { Room } from './room.model';

export interface HasDelay {
  delay: number;
}

export interface BaseService {
  id?: string;
  type?: EventType;
  status: ServiceStatus;
  active: boolean;
  employee: string;
}

export interface AbstractService extends BaseService {
  meetingId?: string;
  watchId?: string;
  editedByManager: boolean;
  created?: number;
  updated?: number;
  room?: Room;
}

export interface MessageService {
  metadata: { [key: string]: string };
}

export interface Service extends AbstractService, MessageService {}

export interface BreakDelay extends AbstractService, HasDelay {}

export class ServiceBreakDelay {
  constructor(public services: Service[], public breakDelay: BreakDelay) {}
}

export enum ServiceStatus {
  EMPTY = '',
  REQUESTED = 'Service Requested',
  INPROGRESS = 'Service In Progress',
  KNOCKING = 'Service Requesting Entrance',
  CANCELEDBYCUSTOMER = 'Service Canceled by Customer', // previous 'CANCEL'
  CANCELEDBYSTAFF = 'Service Rejected by Staff',
  ENTER = 'Service Can Enter',
  DONE = 'Service Done',
  CANCELBYMANAGER = 'Service Canceled by Manager',
}
