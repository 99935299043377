import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  constructor(private localStorageService: LocalStorageService) {}

  public isIonicApp(): boolean {
    return this.localStorageService.loadIsIonicApp();
  }
}
