import { Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[appBlankOrIframeTarget]' })
export class BlankOrIframeTargetDirective {
  private isFullyBrowser = 'fully' in window;

  constructor(private el: ElementRef) {
    this.el.nativeElement.setAttribute('target', this.getTarget());
  }

  public getTarget(): string {
    return this.isFullyBrowser ? 'iframe-overlay' : '_blank';
  }
}
