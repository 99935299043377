<ng-container *ngIf="isClickable">
  <div
    class="qr-container d-flex justify-content-center w-100"
    [style.width.px]="qrCodeWidth"
    (click)="onQRCodeClicked(url)"
  >
    <ng-container *ngTemplateOutlet="qrCode"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!isClickable">
  <div
    class="d-flex justify-content-center w-100"
    [style.width.px]="qrCodeWidth"
  >
    <ng-container *ngTemplateOutlet="qrCode"></ng-container>
  </div>
</ng-container>

<ng-template #qrCode>
  <qrcode
    #qrCode
    [qrdata]="url"
    [width]="qrCodeWidth"
    [errorCorrectionLevel]="'M'"
    [margin]="0"
    [colorLight]="'#00000000'"
  >
  </qrcode>
</ng-template>
