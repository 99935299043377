<ng-container *ngIf="homeCard; else participantQrCode">
  <!-- Workaround for mdb to always inject correct .btn css class for consistent styling -->
  <div mdbBtn style="display: none"></div>
  <div class="xl-master-home-card-container">
    <ng-container *ngIf="homeCard.title; else missingTitle">
      <div
        class="xl-master-home-card-title"
        [class.card-header]="displayAsCard"
      >
        <p
          class="modal-title w-100 font-weight-bold"
          [innerHTML]="
            homeCard.title | appSafeHtmlWithIframeTarget : linkTarget
          "
        ></p>
        <button
          *ngIf="showCloseButton"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCloseClicked()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </ng-container>

    <ng-template #missingTitle>
      <div *ngIf="showCloseButton" class="close-button-container">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCloseClicked()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </ng-template>

    <div class="xl-card-body">
      <ng-container *ngIf="homeCard.mainImageUrl">
        <div class="d-flex justify-content-center">
          <img [src]="homeCard.mainImageUrl" alt="Image"/>
        </div>
      </ng-container>
      <ng-container *ngIf="homeCard.body">
        <div class="mt-2 ql-snow">
          <div
            #homeCardBody
            class="ql-editor"
            [innerHTML]="homeCard.body | appSafeHtmlWithIframeTarget"
          ></div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #participantQrCode>
  <div class="xl-master-home-card-container">
    <div class="xl-master-home-card-title" [class.card-header]="displayAsCard">
      <p class="font-weight-bold">
        <i class="far fa-envelope fa-lg pt-1"></i> Participant Qr Code
      </p>
      <button
        *ngIf="showCloseButton"
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="onCloseClicked()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="xl-card-body">
      <app-participant-qr-info-body
        [url]="participantQrCodeInfo.baseUrl"
        [urlWithToken]="participantQrCodeInfo.urlWithToken"
        [meetingPin]="participantQrCodeInfo.meetingPin"
        [showSendToEmail]="true"
        [qrCodeWidth]="qrCodeWidth"
      ></app-participant-qr-info-body>
    </div>
  </div>
</ng-template>
