<div #dropdown class="btn-group w-100" mdbDropdown [autoClose]="true">
  <a
    mdbDropdownToggle
    mdbWavesEffect
    type="button"
    class="dropdown-toggle waves-light align-items-center w-100 d-flex"
    mdbWavesEffect
  >
    <span class="w-100">
      {{
        selectedTimeZone?.length > 0
          ? selectedTimeZone
          : ('global.enter-time-zone' | translate)
      }}
    </span>
  </a>
  <div
    class="dropdown-menu dropdown-primary"
    style="overflow-y: scroll"
    [style.max-height.px]="maxHeight"
  >
    <div class="md-form my-0 mx-2">
      <input
        type="search"
        class="form-control"
        id="filterInput"
        [(ngModel)]="filterBy"
        mdbInput
        appStopClickPropagation
      />
      <label for="filterInput">{{ 'global.search' | translate }}</label>
    </div>
    <a
      *ngFor="let timeZone of timeZones | timeZoneFilter: filterBy"
      class="dropdown-item px-2"
      [class.active]="selectedTimeZone === timeZone"
      (click)="onSelectTimeZone(timeZone); dropdown.hide()"
    >
      {{ timeZone }}
    </a>
  </div>
</div>
