import { Pipe, PipeTransform } from '@angular/core';
import { DeviceInfo, DeviceType } from 'projects/shared/models/heartbeat.model';

import { DeviceSort } from '../models/device-sort';
import { ReminderSetting } from '../models/reminder-setting.model';
import { getDeviceStatusOrder } from '../util/util';

@Pipe({
  name: 'deviceInfoFilter',
  pure: false, // necessary for dynamic sort parameter
})
export class DeviceInfoFilterPipe implements PipeTransform {
  public transform(
    deviceInfoList: DeviceInfo[],
    deviceType: DeviceType,
    reminderSetting?: ReminderSetting,
    sort?: DeviceSort<DeviceInfo, 'status'>
  ): DeviceInfo[] {
    if (!(deviceInfoList?.length > 0)) {
      return [];
    }

    return deviceInfoList?.length > 0
      ? deviceInfoList
          .filter((device) => {
            return device.deviceType === deviceType;
          })
          .sort((device1, device2) => {
            if (sort) {
              let position: number;

              if (sort.sortBy === 'service') {
                // Set defaulte value 'none' for sorting.
                device1.serviceDescription = device1.serviceDescription
                  ? device1.serviceDescription
                  : 'none';
                device2.serviceDescription = device2.serviceDescription
                  ? device2.serviceDescription
                  : 'none';

                position =
                  device1.serviceDescription < device2.serviceDescription
                    ? -1
                    : device1.serviceDescription > device2.serviceDescription
                    ? 1
                    : 0;
              } else if (sort.sortBy === 'status') {
                const device1StatusOrder = getDeviceStatusOrder(
                  device1,
                  reminderSetting
                );
                const device2StatusOrder = getDeviceStatusOrder(
                  device2,
                  reminderSetting
                );

                position =
                  device1StatusOrder < device2StatusOrder
                    ? -1
                    : device1StatusOrder > device2StatusOrder
                    ? 1
                    : 0;
              } else {
                const valueOfSortCriteria1 = this.getPropertyValueByName(
                  device1,
                  sort.sortBy
                );
                const valueOfSortCriteria2 = this.getPropertyValueByName(
                  device2,
                  sort.sortBy
                );

                if (valueOfSortCriteria1 < valueOfSortCriteria2) {
                  position = -1;
                } else if (valueOfSortCriteria1 > valueOfSortCriteria2) {
                  position = 1;
                } else {
                  position = 0;
                }
              }

              return sort.direction === 'asc' ? position : position * -1;
            }

            return 0;
          })
      : [];
  }

  private getPropertyValueByName<T>(object: T, name: string): any {
    const map: Map<string, any> = new Map<string, any>();

    Object.entries(object).forEach((entry) => {
      map.set(entry[0], entry[1]);
    });

    return map.get(name);
  }
}
