import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValueFromMap',
})
export class GetValueFromMapPipe implements PipeTransform {
  public transform<T>(map: Map<T, any>, key: T): any {
    return map.get(key);
  }
}
