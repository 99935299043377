<ul class="nav nav-pills d-flex flex-column flex-nowrap h-100 column-icons">
  <ng-container *ngFor="let item of navItems; let i = index">
    <ng-container [ngSwitch]="item.type">
      <!-- Spacer -->
      <ng-container *ngSwitchCase="navItemTypes.SPACER">
        <li class="nav-item flex-1 d-flex justify-content-end">
          <hr class="my-0 w-100" />
        </li>
      </ng-container>
      <!-- All other types -->
      <ng-container *ngSwitchDefault>
        <li
          class="nav-item waves-light"
          [ngClass]="item.hideResponsive ? 'd-none d-lg-block' : ''"
          mdbWavesEffect
        >
          <div class="text-center">
            <a
              *ngIf="!(item.routerLink?.length > 0)"
              class="nav-link routeless"
              (click)="item.type !== 'logout' ? item.action() : logout()"
            >
              <ng-container
                *ngTemplateOutlet="
                  NAV_ITEM;
                  context: {
                    item: item,
                    activeRoute: false,
                    routeless: true
                  }
                "
              >
              </ng-container>
            </a>
            <a
              *ngIf="item.routerLink?.length > 0"
              class="nav-link"
              [routerLink]="item.routerLink"
              routerLinkActive
              #rla="routerLinkActive"
              [ngClass]="{ active: rla.isActive }"
              [routerLinkActiveOptions]="{ exact: item.type === 'logout' }"
              data-toggle="pill"
              (click)="
                itemClicked.emit();
                item.type !== 'logout' ? item.action() : logout()
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  NAV_ITEM;
                  context: {
                    item: item,
                    activeRoute: rla.isActive,
                    routeless: false
                  }
                "
              >
              </ng-container>
            </a>
          </div>
          <hr
            [ngClass]="item.hideResponsive ? 'd-none d-lg-block' : ''"
            class="my-0 w-100"
          />
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>

<ng-template
  #NAV_ITEM
  let-item="item"
  let-activeRoute="activeRoute"
  let-routeless="routeless"
>
  <div class="d-flex align-items-center nav-item" style="position: relative">
    <i *ngIf="!item.useCustomIcon" [class]="'fa-2x ' + item.iconStyleClass"></i>

    <img
      *ngIf="item.useCustomIcon"
      class="customIcon"
      [src]="item.iconSrc"
      [style.filter]="
        activeRoute && !routeless ? 'brightness(0) invert(1)' : ''
      "
      width="40"
      height="40"
      [alt]="item.title | translate"
    />

    <span class="text-break">{{ item.title | translate }}</span>
    <ng-container [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="navItemTypes.COMMON">
        <ng-content select="[common]"></ng-content>
      </ng-container>
      <ng-container *ngSwitchCase="navItemTypes.BADGE">
        <ng-content select="[badge]"></ng-content>
      </ng-container>
      <ng-container *ngSwitchCase="navItemTypes.TEMPLATE">
        <ng-container [ngTemplateOutlet]="item.templateRef"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="navItemTypes.LOGOUT">
        <ng-content select="[logout]"></ng-content>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
