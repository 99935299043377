import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NavItem } from '../side-nav/side-nav.component';

@Component({
  selector: 'app-nav-wrapper',
  templateUrl: './nav-wrapper.component.html',
  styleUrls: ['./nav-wrapper.component.scss'],
})
export class NavWrapperComponent {
  @Input() public showSideNav: boolean;
  @Input() public showTopNav: boolean;
  @Input() public showBottomNav: boolean;
  @Input() public showLogo: boolean = true;
  @Input() public activateLongPress = false;
  @Input() public sideNavItems: NavItem[] = [];
  @Input() public responsive = false;
  @Input() public logoType: 'black' | 'white' = 'black';

  @Output() public longPress = new EventEmitter();

  @ViewChild('overlay') public overlay: ElementRef<HTMLDivElement>;

  public displaySideNav = false;

  private _showSideOverlay = false;
  public set showSideOverlay(show: boolean) {
    if (show) {
      this._showSideOverlay = true;
    } else {
      setTimeout(() => {
        this._showSideOverlay = false;
      }, 300);
    }
  }
  public get showSideOverlay(): boolean {
    return this._showSideOverlay;
  }

  public onContentClicked(): void {
    if (this.responsive && this.displaySideNav) {
      this.displaySideNav = false;
      this.showSideOverlay = false;
    }
  }

  public changeSideNav(displaySideNav: boolean): void {
    this.showSideOverlay = displaySideNav;
    setTimeout(() => {
      this.displaySideNav = displaySideNav;
    }, 10);
  }
}
