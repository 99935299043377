<div *ngIf="modalTitle" class="modal-header">
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="modalRef.hide()"
  >
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">{{ modalTitle }}</h4>
</div>
<div class="modal-body">
  <!-- Text  -->
  <div
    *ngIf="modalBodyText"
    style="white-space: pre-line"
    [innerHTML]="modalBodyText | appSafeHtmlWithIframeTarget"
  ></div>
  <!-- Qr Code  -->
  <div *ngIf="modalBodyQRCode" class="h-100 w-100">
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <qrcode
        [width]="700"
        [qrdata]="modalBodyQRCode"
        [errorCorrectionLevel]="'M'"
        [margin]="0"
        [colorLight]="'#00000000'"
      >
      </qrcode>
    </div>
  </div>
</div>
