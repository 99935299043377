import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformUTCMilliSecondsToLocalMilliSeconds',
})
export class TransformUTCMilliSecondsToLocalMilliSecondsPipe
  implements PipeTransform
{
  public transform(utcMilliSeconds: number): Date {
    const c = new Date();
    return new Date(utcMilliSeconds - c.getTimezoneOffset() * 60 * 1000);
  }
}
