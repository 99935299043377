import { Pipe, PipeTransform } from '@angular/core';
import { Sort } from '../models/device-sort';

@Pipe({
  name: 'applyFilterAndSort',
})
export class GenericObjectFilterSortPipe implements PipeTransform {
  public transform<T>(
    list: T[],
    sort: Sort<T>,
    filter?: { key: keyof T; value: any }
  ): T[] {
    if (!(list?.length > 0)) {
      return [];
    }

    return list?.length > 0
      ? list
          .filter((object) => {
            return filter ? object[filter.key] === filter.value : true;
          })
          .sort((obj1, obj2) => {
            if (sort) {
              let position: number;

              const valueOfSortCriteria1 = obj1[sort.sortBy];
              const valueOfSortCriteria2 = obj2[sort.sortBy];

              if (valueOfSortCriteria1 < valueOfSortCriteria2) {
                position = -1;
              } else if (valueOfSortCriteria1 > valueOfSortCriteria2) {
                position = 1;
              } else {
                position = 0;
              }

              return sort.direction === 'asc' ? position : position * -1;
            }

            return 0;
          })
      : [];
  }
}
