<mdb-date-picker
  [label]="label"
  [(ngModel)]="date"
  [inline]="true"
  (inputFieldChanged)="inputFieldChanged.emit($event)"
  [options]="{
    closeAfterSelect: true,
    dateFormat: localizationService.preferredDateFormat | lowercase,
    useDateObject: true
  }"
></mdb-date-picker>
