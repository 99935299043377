import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Answer } from '../models/answer.model';
import { ApplicationHttpClient } from './application-http-client';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})

// Participant Voting Answer Service
export class AnswerService {
  private _url = 'api/answer/';

  constructor(
    private _http: ApplicationHttpClient,
    private _localStorageService: LocalStorageService
  ) {}

  public createAnswer(answer: Answer, votingId: string): Observable<Answer> {
    return this._http.post(
      this._url.concat(this._localStorageService.loadMeeting(), '/', votingId),
      answer
    );
  }
}
