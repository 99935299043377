import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-generic-login',
  templateUrl: './generic-login.component.html',
  styleUrls: ['./generic-login.component.scss'],
})
export class GenericLoginComponent implements OnInit {
  public loginForm: FormGroup;
  public returnUrl: string;

  @Input() public loginImpl: ILogin;

  constructor(
    public formBuilder: FormBuilder,
    public authService: AuthService,
    public route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    if (!this.loginImpl) {
      console.error('Login interface is not correctly implemented!');
      return;
    }

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public login(): void {
    this.loginImpl?.login(
      this.loginForm.controls.email.value,
      this.loginForm.controls.password.value,
      this.returnUrl
    );
  }
}

export interface ILogin {
  login(username: string, password: string, returnUrl: string): void;
}
