import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'appSafeHtmlWithIframeTarget' })
export class SafeHtmlWithIframeTargetPipe implements PipeTransform {
  private domParser: DOMParser = new DOMParser();

  constructor(private sanitized: DomSanitizer) {}

  public transform(
    value: string,
    linkTarget: 'iframe' | 'tab' | 'window' = 'iframe'
  ): SafeHtml {
    const htmlElement = this.domParser.parseFromString(value, 'text/html');

    if (linkTarget === 'tab' || linkTarget === 'iframe') {
      const target = linkTarget === 'tab' ? '_blank' : 'iframe-overlay';
      htmlElement.querySelectorAll('a').forEach((anchor) => {
        anchor.setAttribute('target', target);
      });
    }

    return this.sanitized.bypassSecurityTrustHtml(htmlElement.body.innerHTML);
  }
}
