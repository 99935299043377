import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { App } from '../../../../shared/environments/app';
import { AuthService } from '../../../../shared/services/auth.service';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';

@Injectable()
export class HadAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService
  ) {}

  public canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isBeyondAdmin = this.localStorageService.loadIsBeyondAdmin();
    if (!(this.localStorageService.loadHotel()?.length > 0)) {
      if (isBeyondAdmin) {
        this.router.navigate(['/main/locations']);
      } else {
        this.router.navigate(['/login'], {
          queryParams: { returnUrl: state.url },
        });
      }
      return of(false);
    }

    return this.authService.checkCredentials(App.HAD).pipe(
      switchMap(() => {
        const storedHotelId = this.localStorageService.loadHotel();
        if (!storedHotelId) {
          if (isBeyondAdmin) {
            this.router.navigate(['/main/locations']);
          } else {
            this.router.navigate(['/login'], {
              queryParams: { returnUrl: state.url },
            });
          }
          return of(false);
        }
        return this.environmentService.loadManagementData();
      }),
      catchError(() => {
        if (isBeyondAdmin) {
          this.router.navigate(['/beyond-admin-login'], {
            queryParams: { returnUrl: state.url },
          });
        } else {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url },
          });
        }

        return of(false);
      })
    );
  }
}
