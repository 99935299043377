import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BhToastService } from 'projects/shared/services/bh-toast.service';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
})
export class MailComponent {
  @Output() public emailEvent = new EventEmitter<FormControl>();

  public emailControl = new FormControl('');
  public acceptTermsAndPrivacyControl = new FormControl(false);

  constructor(private toastService: BhToastService) {}

  public submitBtnClicked(): void {
    const email = this.emailControl.value;

    // Simple check for valid email address
    if (!email || !email.includes('@')) {
      this.toastService.warning('global.enter-valid-email-address');
      return;
    }

    if (!this.acceptTermsAndPrivacyControl.value) {
      this.toastService.warning(
        'global.accept-terms-and-conditions-and-privacy'
      );
      return;
    }

    this.emailEvent.emit(this.emailControl);
    this.emailControl.setValue('');
  }
}
