<div class="md-form mdb-date-time">
  <input
    (focus)="this.datePicker.openBtnClicked()"
    #input
    class="w-100 picker-opener"
    type="text"
    [value]="showPlaceholder ? inputText : (date | appHotelDate)"
    [disabled]="disabled"
    onkeydown="return false"
  />
  <mdb-date-picker
    #datePicker
    (dateChanged)="onDateChange($event)"
    [options]="myDatePickerOptions"
  ></mdb-date-picker>
  <mdb-time-picker
    #timePicker
    (timeChanged)="onTimeChange($event)"
    [buttonLabel]="'Done'"
    [twelvehour]="false"
    [buttonClear]="false"
  ></mdb-time-picker>
</div>
