<div>
  <input
    type="text"
    block="true"
    class="form-control"
    [formControl]="emailControl"
    placeholder="{{ 'global.your-email-address' | translate }}"
  />
</div>
<p class="mb-0 text-muted">
  {{ 'global.check-spam-box' | translate }}
</p>
<div class="mt-2">
  <div class="d-flex align-items-center">
    <mdb-checkbox
      class="label-pl-25"
      [formControl]="acceptTermsAndPrivacyControl"
    >
      <div
        class="h-100 d-flex align-items-center text-muted font-italic line-height-normal ml-2"
      >
        {{ 'global.privacy-agreement' | translate }}
      </div>
    </mdb-checkbox>
  </div>

  <button
    class="custom-btn-light mt-2"
    (click)="submitBtnClicked()"
    mdbWavesEffect
  >
    {{ 'global.submit' | translate }}
  </button>
</div>
