import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Playlist, RadioStation, Song } from '../models/music.model';
import { Page, Pageable, Sort } from '../models/page';
import { ApplicationHttpClient } from './application-http-client';

@Injectable({
  providedIn: 'root',
})
export class MusicService {
  public playlistsSubject = new BehaviorSubject<Page<Playlist>>(undefined);
  public radioStationsSubject = new BehaviorSubject<Page<RadioStation>>(
    undefined
  );
  public playlists: Playlist[];
  public radioStations: RadioStation[];

  constructor(private http: HttpClient, private _http: ApplicationHttpClient) {}

  public getPlaylistById(id: string): Playlist {
    const origPlaylist: Playlist = this.playlists?.find(
      (pl) => pl.directoryName === id
    );

    return Object.assign(new Playlist(), origPlaylist);
  }

  public getRadioStationByName(name: string): RadioStation {
    const origRadioStation: RadioStation = this.radioStations?.find(
      (station) => station.name === name
    );

    return Object.assign(new RadioStation(), origRadioStation);
  }

  public loadPlaylists(
    pageNumber?: number,
    pageSize?: number,
    name?: string
  ): void {
    this.http
      .get<any>(environment.playlistBaseUrl + '/music_index.json')
      .pipe(
        switchMap((value) => {
          const playlists: Playlist[] = value.playlists.map((playlist: any) =>
            this.mapPlaylistJson(playlist)
          );
          return of(playlists);
        }),
        switchMap((playlists) => {
          return of(
            this.mockPagedPlaylists(pageNumber, pageSize, name, playlists)
          );
        })
      )
      .subscribe((page) => {
        page.filters = {};
        page.filters.name = name;
        this.playlists = page.content;
        this.playlistsSubject.next(page);
      });
  }

  public loadRadioStations(
    pageNumber?: number,
    pageSize?: number,
    name?: string,
    tags?: string
  ): void {
    let params = new HttpParams();

    if (pageNumber >= 0) {
      params = params.append('pageNumber', String(pageNumber));
    }
    if (pageSize >= 0) {
      params = params.append('pageSize', String(pageSize));
    }
    if (name) {
      params = params.append('name', name);
    }
    if (tags) {
      params = params.append('tags', tags);
    }

    this._http
      .get<Page<RadioStation>>(`api/music/radio/all`, {
        params,
      })
      .subscribe((page) => {
        page.filters = {};
        page.filters.name = name;
        page.filters.tags = tags;
        this.radioStations = page.content;
        this.radioStationsSubject.next(page);
      });
  }

  public loadNextRadioStationPage(): void {
    const page = this.radioStationsSubject.getValue();
    this.loadRadioStations(
      page?.pageable?.pageNumber + 1,
      page?.pageable?.pageSize,
      page?.filters?.name,
      page?.filters?.tags
    );
  }

  public loadMoreRadioStations(plus: number): void {
    const page = this.radioStationsSubject.getValue();

    if (page.last) {
      return;
    }

    this.loadRadioStations(
      // Same page.
      page?.pageable?.pageNumber,
      // Higher page size.
      page?.pageable?.pageSize + plus,
      page?.filters?.name,
      page?.filters?.tags
    );
  }

  public loadMorePlaylists(plus: number): void {
    const page = this.playlistsSubject.getValue();

    if (page.last) {
      return;
    }

    this.loadPlaylists(
      // Same page.
      page?.pageable?.pageNumber,
      // Higher page size.
      page?.pageable?.pageSize + plus,
      page?.filters?.name
    );
  }

  private mapPlaylistJson(raw: any, target?: Playlist[]): Playlist {
    const playlist: Playlist = new Playlist();
    playlist.displayName = raw?.displayName;
    playlist.directoryName = raw?.directoryName;
    playlist.coverUrl = raw
      ? environment.playlistBaseUrl + '/' + raw.coverUrl
      : '';
    playlist.songs = raw?.songs?.map((s: any) => this.mapSongJson(s, playlist));
    if (target) {
      target.push(playlist);
    }
    return playlist;
  }

  private mapSongJson(raw: any, playlist: Playlist): Song {
    const song: Song = new Song();
    song.fileName = raw?.fileName;
    song.displayName = raw?.displayName;
    song.duration = raw?.duration;
    song.artist = raw?.artist;
    song.url = `${environment.playlistBaseUrl}/${
      playlist.directoryName
    }/${encodeURI(song.fileName)}`;
    return song;
  }

  private mockPagedPlaylists(
    pageNumber: number,
    pageSize: number,
    name: string,
    playlists: Playlist[]
  ): Page<Playlist> {
    // Mock a filter DB query.
    playlists = playlists.filter((playlist) => {
      if (name?.length > 0) {
        if (playlist.displayName?.length > 0) {
          const playlistName: string = (
            playlist.displayName as string
          ).toLowerCase();
          const searchName: string = name.toLowerCase();
          return playlistName.includes(searchName);
        } else {
          return false;
        }
      }
      return true;
    });

    let playlistsModified: Playlist[] = playlists;

    const unpaged: boolean = !(pageNumber >= 0 && pageSize > 0);

    if (!unpaged) {
      const playlistsToLoad = (pageNumber + 1) * pageSize;
      playlistsModified = playlists.slice(0, playlistsToLoad + 1);
    }

    const page = new Page<Playlist>();
    page.content = playlistsModified;
    page.empty = false;
    page.numberOfElements = playlistsModified.length;
    page.sort = new Sort(false, false, true);
    page.totalElements = playlists.length;
    page.first = !unpaged ? pageNumber === 0 : true;
    page.last = playlistsModified.length === playlists.length;
    page.number = !unpaged ? pageNumber : undefined;
    page.size = !unpaged ? pageSize : undefined;
    page.totalPages = !unpaged
      ? Math.ceil(playlists.length / pageSize)
      : undefined;
    page.pageable = new Pageable(
      page.sort,
      pageNumber * pageSize,
      pageNumber,
      pageSize,
      !unpaged,
      unpaged
    );

    return page;
  }
}
