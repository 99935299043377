<div id="navigationWrapper" [class.responsive]="responsive">
  <div *ngIf="showTopNav" id="topNavbarWrapper" class="fixed-top">
    <app-navbar
      [logoType]="logoType"
      [responsive]="responsive"
      [activateLongPress]="activateLongPress"
      [showLogo]="showLogo"
      (longPress)="longPress.emit()"
      [menuActive]="displaySideNav"
      (menuClicked)="changeSideNav(!displaySideNav)"
    >
      <ng-content
        select="[topNavContent]"
        ngProjectAs="[topNavContent]"
      ></ng-content>
    </app-navbar>
  </div>
  <div id="belowNavbarWrapper">
    <div id="betweenWrapper">
      <div
        *ngIf="showSideNav"
        id="sidenavWrapper"
        [class.active]="displaySideNav"
      >
        <app-generic-side-nav
          [navItems]="sideNavItems"
          (itemClicked)="changeSideNav(false)"
        >
          <ng-content select="[badge]" ngProjectAs="[badge]"></ng-content>
          <ng-content select="[common]" ngProjectAs="[common]"></ng-content>
          <ng-content select="[logout]" ngProjectAs="[logout]"></ng-content>
        </app-generic-side-nav>
      </div>

      <div id="contentWrapper">
        <ng-content select="[content]"></ng-content>
      </div>
      <div
        #overlay
        *ngIf="showSideOverlay"
        id="overlay"
        class="d-lg-none"
        [style.opacity]="displaySideNav ? '1' : '0'"
        (click)="onContentClicked()"
      ></div>
    </div>
    <ng-content *ngIf="showBottomNav" select="[bottomNav]"></ng-content>
  </div>
</div>
