export class Song {
  public displayName: string;
  public fileName: string;
  public duration: number;
  public artist: string;

  get title(): string {
    return this.displayName;
  }

  // Not in JSON:
  public url: string;
  public audio: HTMLAudioElement;
  public type: AudioType;
  public loop: boolean;
}

export class Playlist implements Audio {
  public displayName: string;
  public directoryName: string;
  public coverUrl: string;
  public songs: Song[];

  // Not in JSON:
  public loop: boolean;

  get title(): string {
    return this.displayName;
  }

  get type(): AudioType {
    return AudioType.MUSIC;
  }
}

export class RadioStation implements Audio {
  public name: string;
  public url: string;
  public coverUrl: string;
  public tags: string[];

  get title(): string {
    return this.name;
  }

  get type(): AudioType {
    return AudioType.RADIO;
  }
}

export enum AudioType {
  RADIO,
  MUSIC,
}

export interface Audio {
  title: string;
  type: AudioType;
}
