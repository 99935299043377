import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IMessage, StompSubscription } from '@stomp/stompjs';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { HomeCard } from '../models/home-card.model';
import { Hotel } from '../models/hotel.model';
import {
  Meeting,
  MeetingEndDateUpdateEvent,
  MeetingNameUpdateEvent,
} from '../models/meeting.model';
import { ParticipantCredentials } from '../models/participant-credentials';
import { Room } from '../models/room.model';
import { SubscriptionSTOMPKey } from '../models/subscriptionSTOMPKey.model';
import { isMeeting } from '../util/util';
import { ApplicationHttpClient } from './application-http-client';
import { ApplicationWebsocketClient } from './application-websocket-client';
import { AuthService } from './auth.service';
import { BhToastService } from './bh-toast.service';
import { LocalStorageService } from './local-storage.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { PlayerService } from './player.service';
import { ResettableService } from './resettable.service';
import { HotelStatistic } from '../models/hotel-statistic.model';
import { ServiceStatistic } from '../models/service-statistic.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private _hotelUrl = 'api/hotel/';
  private _meetingUrl = 'api/meeting/';
  private _roomUrl = 'api/room/';
  private _analyticsUrl = 'api/analytics/';

  private _websocketSubscription: StompSubscription;
  private _roomInfoWebsocketSubscription: StompSubscription;

  private _masterHomeCards$ = new BehaviorSubject<HomeCard[]>([]);
  private _meetingName$ = new BehaviorSubject('');
  private _meetingEndDate$: BehaviorSubject<number> = new BehaviorSubject(0);

  public hotel: Hotel;
  public meeting: Meeting;
  public room: Room;

  // Credentials for participant
  public participantCredentials: ParticipantCredentials;

  constructor(
    private _http: ApplicationHttpClient,
    private _localStorageService: LocalStorageService,
    private toastService: BhToastService,
    private _websocket: ApplicationWebsocketClient,
    private _router: Router,
    private _resettableService: ResettableService,
    private _playerService: PlayerService,
    private _loggerService: LoggerService,
    private _authService: AuthService,
    private localizationService: LocalizationService
  ) {}

  public startWebSocketConnection(isRoomConnection: boolean): void {
    // isRoomConnection === true: Websocket connection for master to receive every new meeting for the room
    // isRoomConnection === false: Websocket connection for participant to receive when the meeting has ended

    if (isRoomConnection && this._localStorageService.loadRoom() === null) {
      this._loggerService.logDebugWarn(
        'WARNING: Trying to establish a room websocket connection before roomId has loaded.'
      );
      return;
    }
    if (!isRoomConnection && this._localStorageService.loadMeeting() === null) {
      this._loggerService.logDebugWarn(
        'WARNING: Trying to establish a meeting websocket connection before meetingId has loaded.'
      );
      return;
    }

    // Check if the client is currently set
    if (isRoomConnection) {
      this._websocket.subscribeToTopic(SubscriptionSTOMPKey.ROOM, () =>
        this.subscribeToRoomWebsocketConnection()
      );
    } else {
      this._websocket.subscribeToTopic(SubscriptionSTOMPKey.MEETING, () =>
        this.subscribeToMeetingWebsocketConnection()
      );
    }
  }

  public subscribeToRoomWebsocketConnection(): void {
    this._loggerService.logDebug(
      'INFO: Establishing room websocket connection with roomId: ' +
        this._localStorageService.loadRoom()
    );
    this._websocketSubscription = this._websocket.client.subscribe(
      '/topic/room/' + this._localStorageService.loadRoom(),
      (response) => this.handleRoomTopicResponse(response)
    );
  }

  public getMasterHomeCards(): Observable<HomeCard[]> {
    return this._masterHomeCards$;
  }

  public getMeetingName(): Observable<string> {
    return this._meetingName$;
  }

  public setMeetingName(name: string): void {
    this._meetingName$.next(name);
  }

  public getMeetingEndDate(): Observable<number> {
    return this._meetingEndDate$;
  }
  private handleRoomTopicResponse(response: IMessage): void {
    const body = response.body;
    if (body.includes('roomDTO')) {
      this._playerService.playGongSound();
      return;
    }

    if (body.includes('reload-master-home-cards')) {
      this.reloadMasterHomeCards(this._localStorageService.loadRoom());
      return;
    }

    if (body.includes('delete-room')) {
      this._localStorageService.removeRoom();
      this.reloadPage('/settings');
      return;
    }

    const parsedBody: unknown = JSON.parse(body);

    if (isMeeting(parsedBody)) {
      this.toastService.info('global.started-new-meeting');
      this.meeting = parsedBody as Meeting;
      this._localStorageService.saveMeeting(this.meeting.id);
      this._resettableService.resetNow.next(true); // resets all non environment open websockets
      this._meetingName$.next(this.meeting.name);
      this._meetingEndDate$.next(this.meeting.endTime);
      this.reloadPage('/main/home');
      return;
    }
    if (
      this.isMeetingNameUpdate(parsedBody) &&
      this.meeting.id === parsedBody.id
    ) {
      this._meetingName$.next(parsedBody.meetingName);
      return;
    }
    if (
      this.isMeetingEndDateUpdate(parsedBody) &&
      this.meeting.id === parsedBody.id
    ) {
      this.meeting.endTime = Number(parsedBody.endTime);
      this._meetingEndDate$.next(parsedBody.endTime);
    }
  }

  private isMeetingNameUpdate(obj: unknown): obj is MeetingNameUpdateEvent {
    return typeof obj === 'object' && obj !== null && 'meetingName' in obj;
  }

  private isMeetingEndDateUpdate(
    obj: unknown
  ): obj is MeetingEndDateUpdateEvent {
    return typeof obj === 'object' && obj !== null && 'endTime' in obj;
  }

  private reloadMasterHomeCards(roomId: string): void {
    this._http
      .get<HomeCard[]>(`api/room/${roomId}/master-home-cards`)
      .pipe(take(1))
      .subscribe((masterHomeCards) =>
        this._masterHomeCards$.next(masterHomeCards)
      );
  }

  public subscribeToMeetingWebsocketConnection(): void {
    this._loggerService.logDebug(
      'INFO: Establishing meeting websocket connection with meetingId: ' +
        this._localStorageService.loadMeeting()
    );
    this._websocketSubscription = this._websocket.client.subscribe(
      '/topic/meeting/' + this._localStorageService.loadMeeting(),
      (response) => {
        const meeting: Meeting = JSON.parse(response.body) as Meeting;
        // The incoming meeting is a non active meeting
        if (meeting) {
          // Clean up
          this._resettableService.resetNow.next(true); // resets all non environment open websockets
          localStorage.removeItem('LastVotingId');
          this.disconnectWebSocketConnection();
          this._websocket.disconnectOfSubscription(
            SubscriptionSTOMPKey.MEETING
          );
          this._authService.loggedIn = false;
          this.reloadPage('/meeting-ended');
        }
      }
    );
  }

  public disconnectWebSocketConnection(): void {
    this._websocketSubscription?.unsubscribe();
    this._roomInfoWebsocketSubscription?.unsubscribe();
    this._websocketSubscription = null;
    this._roomInfoWebsocketSubscription = null;
  }

  // Force page reload regardless of reuse strategy
  // Can be replaced by this._router.navigateByUrl(url, onSameUrlNavigation: 'reload') in newer angular version
  public reloadPage(url: string): void {
    this._router.navigateByUrl(url).then(() => {
      window.location.reload();
    });
  }

  public endActiveMeetingByRoomId(roomId: string): Observable<Meeting> {
    return this._http
      .post<Meeting>(this._meetingUrl + 'end/byroom/' + roomId, {})
      .pipe(
        map((response) => {
          this.meeting = response;
          this._localStorageService.saveMeeting(response.id);
          return response;
        })
      );
  }

  public deleteMeeting(meetingId: string): Observable<void> {
    return this._http.delete<void>(this._meetingUrl + meetingId);
  }

  public joinMeeting(
    pin: string
  ): Observable<{ meetingId: string; meetingApiKey: string }> {
    return this._http.get(this._meetingUrl + 'join/' + pin);
  }

  public endMeeting(mailLang?: string): Observable<Meeting> {
    const lang = mailLang ? mailLang : this.localizationService.currentLangKey;

    return this._http
      .post<Meeting>(
        this._meetingUrl + 'end/' + this.meeting.id + '?lang=' + lang,
        {}
      )
      .pipe(
        map((response) => {
          this.meeting = response;
          this._localStorageService.saveMeeting(response.id);
          return response;
        })
      );
  }

  public retrieveData(): Observable<any> {
    const meeting: Meeting = {
      name: this._localStorageService.loadRoomName(),
      roomId: this._localStorageService.loadRoom(),
      startTime: new Date().getTime(),
    };
    return forkJoin([
      this.getHotel(),
      this.createMeeting(meeting),
      this.getLoadedRoom(),
    ]);
  }

  public loadHotelData(): Observable<Hotel> {
    return this.getHotel();
  }

  public loadMasterAppData(): Observable<any> {
    return forkJoin([
      this.getHotel(),
      this.getMeetingForCurrentRoom(),
      this.getLoadedRoom(),
    ]).pipe(
      map(([hotel, meeting, room]) => {
        this.hotel = hotel;
        this.meeting = meeting;
        this.room = room;
        this._masterHomeCards$.next(room.masterHomeCards);
        this._meetingName$.next(meeting.name);
        return [hotel, meeting, room];
      })
    );
  }

  public loadManagementData(): Observable<boolean> {
    return this.getHotel().pipe(
      map((hotel) => {
        this.hotel = hotel;
        this._localStorageService.saveHotel(this.hotel.id);
        return true;
      })
    );
  }

  public checkSettingsParticipant(): boolean {
    if (this._localStorageService.loadMeeting() == null) {
      return false;
    }
    return true;
  }

  public saveSettingsParticipant(meeting: string): void {
    this._localStorageService.saveMeeting(meeting);
  }

  public checkSettingsMaster(): boolean {
    if (this._localStorageService.loadRoom() == null) {
      this.toastService.error('global.missing-room-credentials');
      return false;
    }

    if (this._localStorageService.loadHotel() == null) {
      this.toastService.error('global.missing-hotel-credentials');
      return false;
    }
    return true;
  }

  public checkSettingsHad(): boolean {
    if (this._localStorageService.loadHotel() == null) {
      this.toastService.error('global.missing-hotel-credentials');
      return false;
    }
    return true;
  }

  public getMeeting(id: string): Observable<Meeting> {
    return this._http.get<Meeting>(this._meetingUrl + id);
  }

  public getMeetingBasic(id: string): Observable<Meeting> {
    return this._http.get<Meeting>(this._meetingUrl + id + '/participant');
  }

  public getMeetingForCurrentRoom(): Observable<Meeting> {
    return this._http
      .get<Meeting>(
        this._meetingUrl + 'room/' + this._localStorageService.loadRoom()
      )
      .pipe(
        map((response) => {
          this.meeting = response;
          this._localStorageService.saveMeeting(response.id);
          return response;
        })
      );
  }

  public getRoomForMeeting(): Observable<Room> {
    return this._http
      .get<Room>(
        this._meetingUrl + this._localStorageService.loadMeeting() + '/room'
      )
      .pipe(
        map((room) => {
          this.room = room;
          return room;
        })
      );
  }

  public getAllMeetings(): Observable<Meeting[]> {
    return this._http.get<Meeting[]>(
      this._meetingUrl + 'hotel/' + this._localStorageService.loadHotel()
    );
  }

  public getAllActiveMeetings(): Observable<Meeting[]> {
    return this._http.get<Meeting[]>(
      this._meetingUrl +
        'hotel/' +
        this._localStorageService.loadHotel() +
        '/active'
    );
  }
  public getAllUpcomingMeetings(): Observable<Meeting[]> {
    return this._http.get<Meeting[]>(
      this._meetingUrl +
        'hotel/' +
        this._localStorageService.loadHotel() +
        '/upcoming'
    );
  }

  public getAllPastMeetings(): Observable<Meeting[]> {
    return this._http.get<Meeting[]>(
      this._meetingUrl +
        'hotel/' +
        this._localStorageService.loadHotel() +
        '/past'
    );
  }
  public createMeeting(meeting: Meeting): Observable<Meeting> {
    return this._http.post<Meeting>(this._meetingUrl, meeting).pipe(
      map((response) => {
        this.meeting = response;
        this._localStorageService.saveMeeting(response.id);
        return response;
      })
    );
  }

  public scheduleMeeting(meeting: Meeting): Observable<Meeting> {
    return this._http
      .post<Meeting>(this._meetingUrl + 'schedule/', meeting)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public updateMeeting(meeting: Meeting): Observable<Meeting> {
    return this._http.put<Meeting>(this._meetingUrl, meeting);
  }

  public updateHotel(hotel: Hotel): Observable<Hotel> {
    return this._http
      .put<Hotel>(this._hotelUrl, hotel)
      .pipe(map((response) => (this.hotel = response)));
  }

  public loadHotel(): Observable<Hotel> {
    return this.hotel ? of(this.hotel) : this.getHotel();
  }

  public reloadHotel(): Observable<Hotel> {
    return this.getHotel();
  }

  public getHotel(): Observable<Hotel> {
    return this._http
      .get<Hotel>(this._hotelUrl + this._localStorageService.loadHotel())
      .pipe(
        map((response) => {
          this.hotel = response;
          return this.hotel;
        })
      );
  }

  public getRoom(roomId: string): Observable<Room> {
    return this._http.get<Room>(this._roomUrl + roomId);
  }

  public getLoadedRoom(): Observable<Room> {
    return this._http
      .get<Room>(this._roomUrl + this._localStorageService.loadRoom(), {
        params: { masterHomeCards: 'true' },
      })
      .pipe(map((response) => (this.room = response)));
  }

  public getAllHotelRooms(): Observable<Room[]> {
    return this._http.get<Room[]>(
      this._roomUrl + 'hotel/' + this._localStorageService.loadHotel()
    );
  }

  public getHotelStatistic(
    hotelId: string,
    from?: string,
    until?: string
  ): Observable<HotelStatistic> {
    return this._http.get<HotelStatistic>(this._analyticsUrl + 'hotel', {
      params: { hotelId, from, until },
    });
  }

  public getServiceStatistic(
    hotelId: string,
    from?: string,
    until?: string
  ): Observable<ServiceStatistic> {
    return this._http.get<ServiceStatistic>(this._analyticsUrl + 'service', {
      params: { hotelId, from, until },
    });
  }
}
