import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../services/localization.service';

@Pipe({
  name: 'translateAndReplace',
})
export class TranslateAndReplaceInTextPipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  public transform(
    text: string,
    replacements: string[],
    placeHolders: string[]
  ): string {
    text = this.localizationService.getTranslationForKey(text);

    placeHolders.forEach((placeholder, index) => {
      const replacement = this.localizationService.getTranslationForKey(
        replacements[index]
      );
      text = text?.replace(placeholder, replacement);
    });

    return text;
  }
}
