import { Pipe, PipeTransform } from '@angular/core';
import { EmailUserRole } from '../models/user.model';
import { LocalizationService } from '../services/localization.service';

@Pipe({
  name: 'userRoleName',
})
export class UserRoleNamePipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  public transform(userRole: EmailUserRole): string {
    switch (userRole) {
      case EmailUserRole.HOTEL_ADMIN:
        return this.localizationService.getTranslationForKey(
          'global.userRole.hotel-admin'
        );
      case EmailUserRole.MANAGER:
        return this.localizationService.getTranslationForKey(
          'global.userRole.manager'
        );
      case EmailUserRole.XSCREEN_ADMIN:
        return this.localizationService.getTranslationForKey(
          'global.userRole.xscreen-admin'
        );
      case EmailUserRole.STAFF:
        return this.localizationService.getTranslationForKey(
          'global.userRole.staff'
        );
    }
  }
}
