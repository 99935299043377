import { Pipe, PipeTransform } from '@angular/core';

import { ServiceStatus } from '../models/service.model';
import { LocalizationService } from '../services/localization.service';
import { getServiceStatusLocalizationKey } from '../util/util';

@Pipe({
  name: 'serviceStatusTranslation',
})
export class ServiceStatusTranslationPipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  public transform(status: ServiceStatus): string {
    if (!status) {
      return status;
    }
    const statusLocalizationKey = getServiceStatusLocalizationKey(status);
    return this.localizationService.getTranslationForKey(statusLocalizationKey);
  }
}
