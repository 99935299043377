import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { BlockUIModule } from 'ng-block-ui';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {
  MDBBootstrapModulesPro,
  MDBSpinningPreloader,
} from 'ng-uikit-pro-standard';
import { TimeZoneSelectionComponent } from 'projects/had/src/app/components/time-zone-selection/time-zone-selection.component';
import { HasDeviceStatusPipe } from 'projects/manager-app/src/app/pipes/device-status.pipe';

import { BackButtonComponent } from '../components/back-button/back-button.component';
import { BackToStartComponent } from '../components/back-to-start/back-to-start.component';
import { CustomerServiceOutdoorModalComponent } from '../components/customer-service-outdoor-modal/customer-service-outdoor-modal.component';
import { DatePickerComponent } from '../components/date-picker/date-picker.component';
import { DateTimePickerComponent } from '../components/date-time-picker/date-time-picker.component';
import { DeviceStatusIconComponent } from '../components/device-status-icon/device-status-icon.component';
import { ErrorPageComponent } from '../components/error-page/error-page.component';
import { FloatingButtonComponent } from '../components/floating-button/floating-button.component';
import { GenericLoginComponent } from '../components/generic-login/generic-login.component';
import { GenericModalComponent } from '../components/generic-modal/generic-modal.component';
import { HomeCardModalComponent } from '../components/home-card/home-card-modal/home-card-modal.component';
import { HomeCardComponent } from '../components/home-card/home-card.component';
import { XlHomeCardComponent } from '../components/home-card/xl-home-card/xl-home-card.component';
import { IframeOverlayContainerComponent } from '../components/iframe-overlay/iframe-overlay-container.component';
import { ImprintPrivacyQrFullScreenModalComponent } from '../components/imprint-privacy-qr-full-screen-modal/imprint-privacy-qr-full-screen-modal.component';
import { LanguageDropdownComponent } from '../components/language-dropdown/language-dropdown.component';
import { LogoutModalComponent } from '../components/logout-modal/logout-modal.component';
import { MailComponent } from '../components/mail/mail.component';
import { NavWrapperComponent } from '../components/navigation/nav-wrapper/nav-wrapper.component';
import { NavbarComponent } from '../components/navigation/navbar/navbar.component';
import { SideNavComponent } from '../components/navigation/side-nav/side-nav.component';
import { ParticipantCarouselComponent } from '../components/participant-carousel/participant-carousel.component';
import { ProgressRingComponent } from '../components/progress-ring/progress-ring.component';
import { QrCodeComponent } from '../components/qr-code/qr-code.component';
import { ParticipantQrInfoBodyComponent } from '../components/qr-info/participant-qr-info-body/participant-qr-info-body.component';
import { QrInfoComponent } from '../components/qr-info/qr-info.component';
import { TimeComponent } from '../components/time/time.component';
import { UnauthorizedPageComponent } from '../components/unauthorized-page/unauthorized-page.component';
import { VolumeComponent } from '../components/volume/volume.component';
import { BlankOrIframeTargetDirective } from '../directives/blank-or-iframe-target.directive';
import { LongPressDirective } from '../directives/long-press.directive';
import { FitScreenOrientationDirective } from '../directives/screen-orientation.directive';
import { StopClickPropagationDirective } from '../directives/stop-click-propagation.directive';
import { LoggedOutGuard } from '../guards/logged-out-guard';
import { BadgeCountPipe } from '../pipes/badge-count.pipe';
import { DeviceInfoFilterPipe } from '../pipes/device-info-filter.pipe';
import { ObjectKeysPipe } from '../pipes/enum-to-array.pipe';
import { GenericObjectFilterSortPipe } from '../pipes/generic-object-filter-sort.pipe';
import { GetValueFromMapPipe } from '../pipes/get-value-from-map.pipe';
import { HeartbeatFilterPipe } from '../pipes/heartbeat-filter.pipe';
import { HotelDatePipe } from '../pipes/hotel-date.pipe';
import { HoursMinutesSecondsPipe } from '../pipes/hours-minutes-seconds.pipe';
import { LocalizedDatePipe } from '../pipes/localized-date.pipe';
import { MapToValuesPipe } from '../pipes/map-to-values.pipe';
import { TranslateAndReplaceInTextPipe } from '../pipes/replace-in-text.pipe';
import { SafeHtmlWithIframeTargetPipe } from '../pipes/safe-html-with-iframe-target.pipe';
import { ServiceStatusTranslationPipe } from '../pipes/service-status-translation.pipe';
import { TimeZoneFilterPipe } from '../pipes/time-zone-filter.pipe';
import { TransformUTCMilliSecondsToLocalMilliSecondsPipe } from '../pipes/transform-UTC-milli-seconds-to-local-milli-seconds.pipe';
import { TranslateEventTypePipe } from '../pipes/translate-event-type.pipe';
import { UserRoleNamePipe } from '../pipes/user-role-name.pipe';
import { AnswerService } from '../services/answer.service';
import { BreakDelayHttpService } from '../services/break-delay-http.service';
import { DeviceMacAddressInfoService } from '../services/device-mac-address-info.service';
import { GlobalService } from '../services/global.service';
import { HotelAdminService } from '../services/hotel-admin.service';
import { LocationService } from '../services/hotel.service';
import { LoggerService } from '../services/logger.service';
import { MailService } from '../services/mail.service';
import { ParticipantService } from '../services/participant.service';
import { ReminderSettingService } from '../services/reminder-setting.service';
import { RoomService } from '../services/room.service';
import { ServiceHttpService } from '../services/service-http.service';
import { FileUploadModule } from './file-upload/FileModule.module';

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    NgCircleProgressModule.forRoot({
      outerStrokeColor: '#309ED9',
      innerStrokeColor: '#0e4187',
      titleColor: '#000000',
      titleFontSize: '40',
      showSubtitle: false,
      showUnits: false,
      clockwise: false,
      startFromZero: false,
    }),
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    HammerModule,
    NgbModule,
    FontAwesomeModule,
    BlockUIModule.forRoot(),
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    FileUploadModule,
  ],
  entryComponents: [ImprintPrivacyQrFullScreenModalComponent],
  providers: [
    MDBSpinningPreloader,
    // Services without state.
    ServiceHttpService,
    MailService,
    BreakDelayHttpService,
    AnswerService,
    LoggerService,
    HotelAdminService,
    LocationService,
    RoomService,
    GlobalService,
    ParticipantService,
    ReminderSettingService,
    DeviceMacAddressInfoService,
    // Guards
    LoggedOutGuard,
    // Directives
    BlankOrIframeTargetDirective,
  ],
  declarations: [
    // Components
    ProgressRingComponent,
    UnauthorizedPageComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    ErrorPageComponent,
    ParticipantCarouselComponent,
    MailComponent,
    QrInfoComponent,
    GenericModalComponent,
    GenericLoginComponent,
    SideNavComponent,
    LogoutModalComponent,
    NavWrapperComponent,
    NavbarComponent,
    TimeComponent,
    VolumeComponent,
    BackButtonComponent,
    ImprintPrivacyQrFullScreenModalComponent,
    BackToStartComponent,
    QrCodeComponent,
    CustomerServiceOutdoorModalComponent,
    LanguageDropdownComponent,
    TimeZoneSelectionComponent,
    DeviceStatusIconComponent,
    HomeCardComponent,
    HomeCardModalComponent,
    XlHomeCardComponent,
    IframeOverlayContainerComponent,
    ParticipantQrInfoBodyComponent,
    FloatingButtonComponent,
    // Pipes
    BadgeCountPipe,
    GetValueFromMapPipe,
    HoursMinutesSecondsPipe,
    MapToValuesPipe,
    TranslateEventTypePipe,
    HeartbeatFilterPipe,
    DeviceInfoFilterPipe,
    GenericObjectFilterSortPipe,
    UserRoleNamePipe,
    ObjectKeysPipe,
    TranslateAndReplaceInTextPipe,
    TransformUTCMilliSecondsToLocalMilliSecondsPipe,
    HasDeviceStatusPipe,
    ServiceStatusTranslationPipe,
    LocalizedDatePipe,
    TimeZoneFilterPipe,
    HotelDatePipe,
    SafeHtmlWithIframeTargetPipe,
    // Directives
    StopClickPropagationDirective,
    FitScreenOrientationDirective,
    LongPressDirective,
    BlankOrIframeTargetDirective,
  ],
  exports: [
    // Modules
    NgCircleProgressModule,
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    HammerModule,
    NgbModule,
    FontAwesomeModule,
    BlockUIModule,
    MDBBootstrapModulesPro,
    TranslateModule,
    FileUploadModule,
    // Components
    DatePickerComponent,
    DateTimePickerComponent,
    ProgressRingComponent,
    UnauthorizedPageComponent,
    ErrorPageComponent,
    ParticipantCarouselComponent,
    MailComponent,
    QrInfoComponent,
    GenericModalComponent,
    GenericLoginComponent,
    SideNavComponent,
    LogoutModalComponent,
    NavWrapperComponent,
    NavbarComponent,
    TimeComponent,
    VolumeComponent,
    BackButtonComponent,
    BackToStartComponent,
    QrCodeComponent,
    CustomerServiceOutdoorModalComponent,
    LanguageDropdownComponent,
    TimeZoneSelectionComponent,
    DeviceStatusIconComponent,
    HomeCardComponent,
    HomeCardModalComponent,
    XlHomeCardComponent,
    IframeOverlayContainerComponent,
    ParticipantQrInfoBodyComponent,
    FloatingButtonComponent,
    // Pipes
    BadgeCountPipe,
    GetValueFromMapPipe,
    HoursMinutesSecondsPipe,
    MapToValuesPipe,
    TranslateEventTypePipe,
    HeartbeatFilterPipe,
    DeviceInfoFilterPipe,
    GenericObjectFilterSortPipe,
    UserRoleNamePipe,
    ObjectKeysPipe,
    TranslateAndReplaceInTextPipe,
    TransformUTCMilliSecondsToLocalMilliSecondsPipe,
    HasDeviceStatusPipe,
    ServiceStatusTranslationPipe,
    LocalizedDatePipe,
    TimeZoneFilterPipe,
    HotelDatePipe,
    SafeHtmlWithIframeTargetPipe,
    // Directives
    StopClickPropagationDirective,
    FitScreenOrientationDirective,
    LongPressDirective,
    BlankOrIframeTargetDirective,
  ],
})
/**
 * This module should be provided in every module which needs the
 * shared components, pipes, modules, directives or stateless services.
 */
export class SharedModule {}
