import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakDelay } from '../models/service.model';
import { ApplicationHttpClient } from './application-http-client';

@Injectable({
  providedIn: 'root',
})
export class BreakDelayHttpService {
  private _url = 'api/break/';

  constructor(private _http: ApplicationHttpClient) {}

  // REST Requests Break Delay
  public updateBreak(breakDelay: BreakDelay): Observable<BreakDelay> {
    return this._http.put<BreakDelay>(this._url, breakDelay);
  }

  public createBreak(breakDelay: BreakDelay): Observable<BreakDelay> {
    return this._http.post<BreakDelay>(this._url, breakDelay);
  }
}
