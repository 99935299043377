import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ILogin } from '../../../../../../../shared/components/generic-login/generic-login.component';
import { App } from '../../../../../../../shared/environments/app';
import { AuthService } from '../../../../../../../shared/services/auth.service';
import { EnvironmentService } from '../../../../../../../shared/services/environment.service';
import { LocalStorageService } from '../../../../../../../shared/services/local-storage.service';

@Component({
  selector: 'app-had-login',
  templateUrl: './had-login.component.html',
  styleUrls: ['./had-login.component.scss'],
})
export class HALoginComponent implements OnInit, ILogin {
  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnInit(): void {}

  public login(username: string, password: string, returnUrl: string): void {
    this.authService.loginHad(username, password).subscribe(
      (hotelId) => {
        this.handleLoginSuccess(hotelId, returnUrl);
      },
      () => this.toastr.error('Wrong credentials')
    );
  }

  private handleLoginSuccess(hotelId: string, returnUrl: string): void {
    this.authService.checkCredentials(App.HAD).subscribe(() => {
      this.localStorageService.saveHotel(hotelId);
      this.authService.login(false);
      this.environmentService.loadHotelData().subscribe(
        (_) => {
          this.router.navigateByUrl(returnUrl);
        },
        () => this.toastr.error('Could not load hotel data.')
      );
    });
  }
}
