import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ApplicationHttpClient } from './application-http-client';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  public disableCSVExportBtn$: Subject<boolean> = new Subject();

  constructor(
    private _http: ApplicationHttpClient,
    private _environtmentService: EnvironmentService,
    private _toastrService: ToastrService
  ) {}

  public exportEventsAsCSV(): void {
    this.disableCSVExportBtn$.next(true);
    this._http
      .get('api/csv-export/' + this._environtmentService.hotel.id)
      .subscribe(
        (_) => {
          this._toastrService.success(
            'If you do not see the email in your inbox please also check your spam folder.',
            'Email sent'
          );
          this.disableCSVExportBtn$.next(false);
        },
        (_) => {
          this._toastrService.error('Could not sent email.');
          this.disableCSVExportBtn$.next(false);
        }
      );
  }
}
