import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { App } from '../../../../shared/environments/app';
import { AuthService } from '../../../../shared/services/auth.service';

@Injectable()
export class BadAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.checkCredentials(App.XAD).pipe(
      map(() => true),
      catchError((_) => {
        this.authService.logout();
        this.router.navigate(['/beyond-admin-login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
