export class Page<T> {
  public content: T[];
  public empty: boolean;
  public first: boolean;
  public last: boolean;
  public number: number;
  public numberOfElements: number;
  public size: number;
  public totalElements: number;
  public totalPages: number;
  public pageable: Pageable;
  public sort: Sort;

  // Not in JSON.
  public filters: any;
}

export class Pageable {
  public sort: Sort;
  public offset: number;
  public pageNumber: number;
  public pageSize: number;
  public paged: boolean;
  public unpaged: boolean;

  constructor(
    sort?: Sort,
    offset?: number,
    pageNumber?: number,
    pageSize?: number,
    paged?: boolean,
    unpaged?: boolean
  ) {
    this.sort = sort;
    this.offset = offset;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.paged = paged;
    this.unpaged = unpaged;
  }
}

export class Sort {
  public empty: boolean;
  public sorted: boolean;
  public unsorted: boolean;

  constructor(empty?: boolean, sorted?: boolean, unsorted?: boolean) {
    this.empty = empty;
    this.sorted = sorted;
    this.unsorted = unsorted;
  }
}
