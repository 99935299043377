import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { StompSubscription } from '@stomp/stompjs';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { CustomerServiceOutdoorModalComponent } from '../components/customer-service-outdoor-modal/customer-service-outdoor-modal.component';
import { EventType } from '../models/event.model';
import { BreakDelay, Service, ServiceStatus } from '../models/service.model';
import { SubscriptionSTOMPKey } from '../models/subscriptionSTOMPKey.model';
import { getServiceStatusLocalizationKey } from '../util/util';
import { ApplicationWebsocketClient } from './application-websocket-client';
import { BhToastService } from './bh-toast.service';
import { BreakDelayHttpService } from './break-delay-http.service';
import { EnvironmentService } from './environment.service';
import { LocalizationService } from './localization.service';
import { ResettableService } from './resettable.service';
import { ServiceHttpService } from './service-http.service';

@Injectable()
export class CustomerServiceService implements OnDestroy {
  private _modalInterval: any;
  private _modalRef: MDBModalRef;
  private _websocketSubscriptions: StompSubscription[] = [];

  public service$: BehaviorSubject<Service> = new BehaviorSubject<Service>(
    undefined
  );
  public breakDelay$: BehaviorSubject<BreakDelay> =
    new BehaviorSubject<BreakDelay>(undefined);

  public isBreakDelayToggled = false;
  public isBreakDelayActive = false;
  public isKnockingActive = false;

  private isRequesting = false;

  constructor(
    private toastService: BhToastService,
    private _modalService: MDBModalService,
    private _serviceHttpService: ServiceHttpService,
    private _breakDelayHttpService: BreakDelayHttpService,
    private _environmentService: EnvironmentService,
    private _websocket: ApplicationWebsocketClient,
    private _resettableService: ResettableService,
    private localizationService: LocalizationService
  ) {
    this._resettableService.resetNow.subscribe(() => {
      this.clearWebsocketSubscriptions();
    });
  }

  public ngOnDestroy(): void {
    this.deleteModal();
  }

  private clearWebsocketSubscriptions(): void {
    this._websocketSubscriptions.forEach((websocketSubscription) => {
      websocketSubscription?.unsubscribe();
    });
    this._websocket.disconnectOfSubscription(
      SubscriptionSTOMPKey.CUSTOMERSERVICE
    );
    this._websocket.disconnectOfSubscription(SubscriptionSTOMPKey.BREAKDELAY);
  }

  public startConnection(): void {
    this.service$ = new BehaviorSubject(this.defaultService);
    this.breakDelay$ = new BehaviorSubject(this.defaultBreakDelay);

    this._websocket.subscribeToTopic(SubscriptionSTOMPKey.CUSTOMERSERVICE, () =>
      this.subscribeToServiceWebSocketConnection()
    );
    this._websocket.subscribeToTopic(SubscriptionSTOMPKey.BREAKDELAY, () =>
      this.subscribeToBreakDelayWebSocketConnection()
    );
  }

  public subscribeToServiceWebSocketConnection(): void {
    const meetingId = this._environmentService.meeting.id;
    const websocketSubscription = this._websocket.client.subscribe(
      '/topic/service/' + meetingId,
      (response) => {
        const service = JSON.parse(response.body) as Service;
        this.handleNewService(service);
      }
    );
    this._websocketSubscriptions.push(websocketSubscription);
  }

  public subscribeToBreakDelayWebSocketConnection(): void {
    const meetingId = this._environmentService.meeting.id;
    const websocketSubscription = this._websocket.client.subscribe(
      '/topic/breakdelay/' + meetingId,
      (response) => {
        const breakDelay = JSON.parse(response.body) as BreakDelay;
        this.handleNewBreakDelay(breakDelay);
      }
    );
    this._websocketSubscriptions.push(websocketSubscription);
  }

  public loadServiceBreakDelay(): void {
    this._serviceHttpService
      .getServiceBreakDelay(this._environmentService.meeting.id)
      .subscribe(
        (response) => {
          const service = response.services.find(
            (responseService) =>
              responseService.type !== EventType.GUEST_ENTERED_ROOM
          );
          const breakDelay = response.breakDelay;

          if (!this.isRequesting) {
            this.handleNewService(service);
            this.handleNewBreakDelay(breakDelay);
          }
        },
        (error: HttpErrorResponse) => {
          this.toastService.error(
            'global.could-not-fetch-data',
            'global.error'
          );
        }
      );
  }

  public handleNewService(service: Service): void {
    if (service == null) {
      this.service$.next(this.defaultService);
      return;
    }

    if (service.type === EventType.GUEST_ENTERED_ROOM) {
      return;
    }

    if (!service.active) {
      this.service$.next(this.defaultService);
    } else {
      this.service$.next(service);
    }

    let shouldDisplayToast = true;

    if (
      service.type === EventType.OUTDOOR &&
      service.status === ServiceStatus.KNOCKING
    ) {
      this.isKnockingActive = true;
      this.starModalTimer();
      shouldDisplayToast = false;
    } else {
      // If a service status has been changed while the 'Can Enter' modal is displayed => Close the modal and clean the timer.
      this.isKnockingActive = false;
      this.deleteModal();
    }

    if (shouldDisplayToast) {
      this.displayNewServiceToast(service);
    }
  }

  private displayNewServiceToast(service: Service): void {
    if (service.type === EventType.GUEST_ENTERED_ROOM) {
      return;
    }

    if (service.status === ServiceStatus.CANCELEDBYCUSTOMER) {
      this.toastService.error('global.service-status.canceled_by_customer');
    } else if (
      service.status === ServiceStatus.CANCELEDBYSTAFF ||
      service.status === ServiceStatus.CANCELBYMANAGER
    ) {
      this.toastService.info('global.service-status.canceled_by_staff');
    } else if (service.status === ServiceStatus.DONE) {
      this.toastService.success('global.service-status.done');
    } else {
      const statusLocalizationKey = getServiceStatusLocalizationKey(
        service.status
      );
      const localizedStatus = this.localizationService.getTranslationForKey(
        statusLocalizationKey
      );

      this.toastService.infoChunked([
        `${service.type}`,
        'global.service-status.other',
        ` ${localizedStatus}`,
      ]);
    }
  }

  public handleNewBreakDelay(breakDelay: BreakDelay): void {
    if (breakDelay == null) {
      this.breakDelay$.next(this.defaultBreakDelay);

      this.isBreakDelayToggled = false;
      this.isBreakDelayActive = false;

      return;
    }

    if (!breakDelay.active) {
      this.breakDelay$.next(this.defaultBreakDelay);

      this.isBreakDelayToggled = false;
      this.isBreakDelayActive = false;
    } else {
      this.breakDelay$.next(breakDelay);

      this.isBreakDelayActive = true;
      this.isBreakDelayToggled = true;
    }

    if (breakDelay && breakDelay.status === ServiceStatus.CANCELEDBYCUSTOMER) {
      this.toastService.error('global.break-delay-status.canceled_by_customer');
    } else if (
      breakDelay &&
      breakDelay.status === ServiceStatus.CANCELEDBYSTAFF
    ) {
      this.toastService.success('global.break-delay-status.canceled_by_staff');
    } else if (breakDelay && breakDelay.status === ServiceStatus.DONE) {
      this.toastService.success('global.break-delay-status.done');
    }
  }

  public handleBreakDelayCancelClick(): void {
    if (this.isBreakDelayActive) {
      const currentBreakDelay: BreakDelay = { ...this.breakDelay$.getValue() };
      currentBreakDelay.active = false;
      currentBreakDelay.status = ServiceStatus.CANCELEDBYCUSTOMER;
      this.updateBreakDelay(currentBreakDelay);
    } else {
      this.isBreakDelayToggled = false;
    }
  }

  public breakDelayPlus(): void {
    const breakDelay = this.breakDelay$.value;
    breakDelay.delay += 5;
    this.breakDelay$.next(breakDelay);
  }

  public breakDelayMinus(): void {
    const breakDelay = this.breakDelay$.value;
    breakDelay.delay -= 5;
    this.breakDelay$.next(breakDelay);
  }

  public createBreakDelay(breakDelay: BreakDelay): void {
    if (breakDelay.delay === 0) {
      this.toastService.info('master.select-valid-time');
      return;
    }

    this.isBreakDelayActive = true;

    breakDelay.status = ServiceStatus.REQUESTED;
    this.isRequesting = true;
    this._breakDelayHttpService
      .createBreak(breakDelay)
      .pipe(
        finalize(() => {
          this.isRequesting = false;
        })
      )
      .subscribe(
        (updatedBreakDelay) => {
          this.handleNewBreakDelay(updatedBreakDelay);
        },
        (error: HttpErrorResponse) => {
          this.toastService.error('global.create-break-delay.error');

          this.breakDelay$.next(this.defaultBreakDelay);
        }
      );
  }

  // Includes cancel and update BreakDelay
  public updateBreakDelay(breakDelay: BreakDelay): void {
    if (breakDelay.delay === 0) {
      this.toastService.info('master.select-valid-time');
      return;
    }

    const previousBreakDelay = { ...this.breakDelay$.getValue() };

    this.isRequesting = true;

    if (breakDelay.active) {
      this.isBreakDelayToggled = false;
      this.isBreakDelayActive = false;
    }
    this._breakDelayHttpService
      .updateBreak(breakDelay)
      .pipe(
        finalize(() => {
          this.isRequesting = false;
        })
      )
      .subscribe(
        (updatedBreakDelay) => {
          this.handleNewBreakDelay(updatedBreakDelay);
        },
        (error: HttpErrorResponse) => {
          this.toastService.error('global.update-break-delay.error');

          this.breakDelay$.next(previousBreakDelay);
          if (previousBreakDelay.active) {
            this.isBreakDelayToggled = true;
            this.isBreakDelayActive = true;
          }
        }
      );
  }

  // Includes cancel and update BreakDelay by Manager
  public updateBreakDelayByManager(breakDelay: BreakDelay): void {
    this._breakDelayHttpService
      .updateBreak(breakDelay)
      .pipe(take(1))
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          this.toastService.error('global.update-break-delay.error');
        }
      );
  }

  public createService(service: Service, type: EventType): void {
    this.isKnockingActive = false;
    service.type = type;
    service.status = ServiceStatus.REQUESTED;

    this.isRequesting = true;

    this._serviceHttpService
      .createService(service)
      .pipe(
        finalize(() => {
          this.isRequesting = false;
        })
      )
      .subscribe(
        (updatedService) => {
          this.handleNewService(updatedService);
        },
        (error: HttpErrorResponse) => {
          this.toastService.error('global.create-service.error');

          this.service$.next(this.defaultService);
        }
      );
  }

  // Includes cancel and update Service
  public updateService(service: Service): void {
    const previousService = { ...this.service$.getValue() };

    this.isRequesting = true;

    this._serviceHttpService
      .updateService(service)
      .pipe(
        finalize(() => {
          this.isRequesting = false;
        })
      )
      .subscribe(
        (updatedService) => {
          this.handleNewService(updatedService);
        },
        (error: HttpErrorResponse) => {
          this.toastService.error('global.update-service.error');

          this.service$.next(previousService);

          if (
            previousService.type === EventType.OUTDOOR &&
            previousService.status === ServiceStatus.KNOCKING
          ) {
            this.isKnockingActive = true;
            this.starModalTimer();
          }
        }
      );
  }

  // Includes cancel and update Service by Manager
  public updateServiceByManager(service: Service): void {
    this._serviceHttpService
      .updateService(service)
      .pipe(take(1))
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          this.toastService.error('global.update-service.error');
        }
      );
  }

  public starModalTimer(): void {
    if (this._modalInterval) {
      return;
    }

    this.showModal();

    this._modalInterval = setInterval(() => {
      this.showModal();
    }, 10 * 1000);
  }

  public showModal(): void {
    const service = this.service$.value;
    if (!this._modalRef && service.status === ServiceStatus.KNOCKING) {
      this._modalRef = this._modalService.show(
        CustomerServiceOutdoorModalComponent,
        {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-dialog-centered',
        }
      );

      this._modalRef.content.isAllowed
        .pipe(take(1))
        .subscribe((isAllowed: boolean) => {
          if (isAllowed) {
            this.serviceStaffEnter();
          }
          this._modalRef = undefined;
        });
    } else if (!this._modalRef) {
      this.deleteModal();
    }
  }

  public deleteModal(): void {
    this._modalRef?.hide();
    this._modalRef = undefined;
    clearInterval(this._modalInterval);
    this._modalInterval = undefined;
  }

  public serviceStaffEnter(): void {
    this.deleteModal();
    const service = { ...this.service$.getValue() };
    service.status = ServiceStatus.ENTER;
    this.updateService(service);
    this.isKnockingActive = false;
  }

  get defaultService(): Service {
    return {
      metadata: {},
      status: ServiceStatus.EMPTY,
      meetingId: this._environmentService.meeting.id,
      active: false,
      employee: '',
      editedByManager: false,
    };
  }

  get defaultBreakDelay(): BreakDelay {
    return {
      delay: 0,
      type: EventType.BREAKDELAY,
      status: ServiceStatus.EMPTY,
      meetingId: this._environmentService.meeting.id,
      active: false,
      employee: '',
      editedByManager: false,
    };
  }
}
