import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DeviceMacAddressInfo } from '../models/device-mac-address-info.model';
import { ApplicationHttpClient } from './application-http-client';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceMacAddressInfoService {
  private url = 'api/device-mac-address-info/';

  constructor(
    private http: ApplicationHttpClient,
    private localStorageService: LocalStorageService
  ) {}

  public getDeviceMacAddressInfoList(): Observable<DeviceMacAddressInfo[]> {
    return this.http.get<DeviceMacAddressInfo[]>(
      this.url + 'all/' + this.localStorageService.loadHotel()
    );
  }

  public getDeviceMacAddressInfo(
    id: string
  ): Observable<DeviceMacAddressInfo[]> {
    return this.http.get<DeviceMacAddressInfo[]>(this.url, {
      params: {
        ...{ id },
      },
    });
  }

  public createOrUpdateDeviceMacAddressInfoInBatch(
    deviceMacAddressInfoList: DeviceMacAddressInfo[]
  ): Observable<DeviceMacAddressInfo[]> {
    return this.http.post<DeviceMacAddressInfo[]>(
      this.url + 'batch/' + this.localStorageService.loadHotel(),
      deviceMacAddressInfoList
    );
  }
}
