import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Feedback } from '../models/feedback.model';
import { ApplicationHttpClient } from './application-http-client';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private _url = 'api/feedback';

  constructor(
    private _http: ApplicationHttpClient,
    private _localStorageService: LocalStorageService
  ) {}

  public saveFeedback(feedback: Feedback): Observable<Feedback> {
    if (feedback.meetingId == null) {
      feedback.meetingId = this._localStorageService.loadMeeting();
    }
    return this._http.post(this._url, feedback);
  }
}
