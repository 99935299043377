import { Pipe, PipeTransform } from '@angular/core';
import { DeviceInfo } from 'projects/shared/models/heartbeat.model';
import { ReminderSetting } from 'projects/shared/models/reminder-setting.model';
import { DeviceStatus, getDeviceStatus } from 'projects/shared/util/util';

@Pipe({
  name: 'hasDeviceStatus',
})
export class HasDeviceStatusPipe implements PipeTransform {
  public transform(
    heartbeat: DeviceInfo,
    reminderSetting: ReminderSetting,
    ...deviceStati: DeviceStatus[]
  ): boolean {
    const deviceStatus: DeviceStatus = getDeviceStatus(
      heartbeat,
      reminderSetting
    );
    return deviceStati.indexOf(deviceStatus) > -1;
  }
}
