import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DeviceInfo } from 'projects/shared/models/heartbeat.model';
import { ReminderSetting } from 'projects/shared/models/reminder-setting.model';
import { DeviceStatus, getDeviceStatus } from 'projects/shared/util/util';

@Component({
  selector: 'app-device-status-icon',
  templateUrl: './device-status-icon.component.html',
  styleUrls: ['./device-status-icon.component.scss'],
})
export class DeviceStatusIconComponent implements AfterViewInit, OnChanges {
  @Input() public deviceInfo: DeviceInfo;
  @Input() public deviceInfos: DeviceInfo[];
  @Input() public deviceStatus: DeviceStatus;
  @Input() public reminderSetting: ReminderSetting;
  @Input() public iconClasses: string[];

  @ViewChild('icon') public hostElement: ElementRef;

  private didInit = false;

  // EXCLAMATION = Device can not connect to server via SSL
  // RED         = Heartbeat older than 5 min.
  // ORANGE      = Heartbeat older than 2 min.
  // YELLOW      = Heartbeat newer than 2 min but user is logged out.
  // GREEN       = Heartbeat newer than 2 min and user is logged in.
  constructor(public renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    this.didInit = true;
    this.renderer.addClass(this.hostElement.nativeElement, 'fas');
    this.updateColor();
  }

  public ngOnChanges(): void {
    if (this.didInit) {
      this.updateColor();
    }
  }

  private updateColor(): void {
    if (!this.deviceInfo && !this.deviceInfos && !this.deviceStatus) {
      return;
    }

    const el = this.hostElement.nativeElement;
    this.renderer.removeClass(el, 'fa-circle');
    this.renderer.removeClass(el, 'fa-circle-exclamation');

    let deviceStatus: DeviceStatus;

    if (this.deviceStatus) {
      deviceStatus = this.deviceStatus;
    } else if (!this.deviceInfos) {
      deviceStatus = getDeviceStatus(this.deviceInfo, this.reminderSetting);
    } else {
      const deviceStati = this.deviceInfos.map((heartbeat) => {
        return getDeviceStatus(heartbeat, this.reminderSetting);
      });

      deviceStatus = deviceStati.includes(DeviceStatus.EXCLAMATION)
        ? DeviceStatus.EXCLAMATION
        : deviceStati.includes(DeviceStatus.RED)
        ? DeviceStatus.RED
        : deviceStati.includes(DeviceStatus.ORANGE)
        ? DeviceStatus.ORANGE
        : deviceStati.includes(DeviceStatus.YELLOW)
        ? DeviceStatus.YELLOW
        : DeviceStatus.GREEN;
    }

    switch (deviceStatus) {
      case DeviceStatus.EXCLAMATION:
        this.renderer.addClass(el, 'fa-circle-exclamation');
        this.renderer.setStyle(el, 'color', 'red');
        break;
      case DeviceStatus.RED:
        this.renderer.addClass(el, 'fa-circle');
        this.renderer.setStyle(el, 'color', 'red');
        break;
      case DeviceStatus.ORANGE:
        this.renderer.addClass(el, 'fa-circle');
        this.renderer.setStyle(el, 'color', 'orange');
        break;
      case DeviceStatus.YELLOW:
        this.renderer.addClass(el, 'fa-circle');
        this.renderer.setStyle(el, 'color', 'yellow');
        break;
      case DeviceStatus.GREEN:
        this.renderer.addClass(el, 'fa-circle');
        this.renderer.setStyle(el, 'color', 'chartreuse');
        break;
    }
  }
}
