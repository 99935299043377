import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  Observable,
  Observer,
  Subscription,
  fromEvent,
  interval,
  merge,
  of,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  timeout,
} from 'rxjs/operators';

import { environment } from '../environments/environment';
import { DeviceType, Heartbeat } from '../models/heartbeat.model';
import { ApplicationWebsocketClient } from './application-websocket-client';
import { GlobalService } from './global.service';
import { HeartbeatService } from './heartbeat.service';
import { LocalStorageService } from './local-storage.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService implements OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;

  private TITLE = environment.title;
  private useHeartbeat = environment.useHeartbeat;

  public connectionObservable: Observable<boolean>;

  private connectionSubscription: Subscription;
  private intervalSubscription: Subscription;
  private routeSubscription: Subscription;
  private websocketConnectionSubscription: Subscription;

  private INTERVAL_TIME_ONLINE = 1 * 60 * 1000; // 1 min
  private INTERVAL_TIME_OFFLINE = 10 * 1000; // 10s
  private TIMEOUT = 15000; // 15s
  private intervalType: 'offline' | 'online';

  constructor(
    private heartbeatService: HeartbeatService,
    private localStorageService: LocalStorageService,
    private globalService: GlobalService,
    private loggerService: LoggerService,
    websocketClient: ApplicationWebsocketClient
  ) {
    this.websocketConnectionSubscription =
      websocketClient.websocketConnection$.subscribe((isConnected) => {
        isConnected ? this.dispatchOnlineEvent() : this.dispatchOfflineEvent();
      });
  }

  public ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.connectionSubscription?.unsubscribe();
    this.websocketConnectionSubscription?.unsubscribe();
  }

  public init(): void {
    console.log('ConnectionService: start listening to connection events ...');

    this.connectionObservable = merge<boolean>(
      // Use .map() to transform the returned Event type into a true/false value.
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      // These custom events are triggert depending on the heartbeat/ping response.
      fromEvent(document, 'offline').pipe(map(() => false)),
      fromEvent(document, 'online').pipe(map(() => true)),
      // Start with emitting current online status.
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete(); // This observable only emits once, so now we end it.
      })
    );

    this.connectionSubscription = this.connectionObservable
      .pipe(distinctUntilChanged())
      .subscribe((online) => {
        if (!online) {
          if (!this.globalService.isIonicApp()) {
            this.blockUI.start('Lost connection to server');
          }
          this.loggerService.logProdWarn(`${this.TITLE}: lost connection`);

          // Perform an additional interval initialisation if the event is sent externally by an HTTP client error (status code 0).
          if (this.intervalType === 'online') {
            // Stop long ping interval and start short.
            this.initInterval('offline');
          }
        } else {
          console.log(`${this.TITLE}: found connection`);
          this.blockUI.reset();
        }
      });

    this.initInterval('online');
  }

  private initInterval(pingIntervalType: 'offline' | 'online'): void {
    this.intervalSubscription?.unsubscribe();
    this.intervalType = pingIntervalType;

    const intervalTime =
      pingIntervalType === 'offline'
        ? this.INTERVAL_TIME_OFFLINE
        : this.INTERVAL_TIME_ONLINE;

    this.intervalSubscription = interval(intervalTime).subscribe((_) => {
      this.tryToSendHeartbeatOrPing();
    });
  }

  private tryToSendHeartbeatOrPing(): void {
    const heartbeat: Heartbeat = this.useHeartbeat
      ? this.createHeartbeat()
      : undefined;

    // Skip heartbeat/ping if no hotel data is set.
    if (this.useHeartbeat) {
      if (
        !(heartbeat.hotelId?.length > 0) ||
        !(heartbeat.roomName?.length > 0)
      ) {
        console.log(
          `${this.TITLE}: Skip ${
            this.useHeartbeat ? 'heartbeat' : 'ping'
          }, because no login data is set.`
        );
        this.dispatchOnlineEvent();
        return;
      }
    }

    this.heartbeatService
      .setHeartbeat(heartbeat, !this.useHeartbeat)
      .pipe(
        timeout(this.TIMEOUT),
        switchMap((_) => of({ successful: true, status: undefined })),
        catchError((error: HttpErrorResponse) => {
          switch (error?.status) {
            // No hotel data set / bad request.
            case 400:
            // No API key set / unauthorized.
            case 401:
            // No user role set / forbidden.
            case 403:
              this.dispatchOnlineEvent();
              return of({ successful: true, status: error.status });
            default:
          }

          this.loggerService.logProdWarn(
            `${this.TITLE}: ${this.useHeartbeat ? 'heartbeat' : 'ping'} failed`
          );
          this.dispatchOfflineEvent();

          return of({ successful: false, status: undefined });
        })
      )
      .subscribe((response) => {
        if (response.successful) {
          console.log(
            `${this.TITLE}: ${
              this.useHeartbeat ? 'heartbeat' : 'ping'
            } succeed${
              response.status
                ? ', but the user is not authorized or hotel data is missing.'
                : ''
            }`
          );
          this.dispatchOnlineEvent();
        }
      });
  }

  private dispatchOfflineEvent(): void {
    document.dispatchEvent(new MessageEvent('offline'));

    if (this.intervalType === 'online') {
      // Stop long interval and start short.
      this.initInterval('offline');
    }
  }

  private dispatchOnlineEvent(): void {
    document.dispatchEvent(new MessageEvent('online'));

    if (this.intervalType === 'offline') {
      // Stop short interval and start long.
      this.initInterval('online');
    }
  }

  private createHeartbeat(): Heartbeat {
    const hotelId = this.localStorageService.loadHotel();
    const roomId = this.localStorageService.loadRoom();
    const roomName = this.localStorageService.loadRoomName();
    const loggedIn = undefined;
    const lastLoggedInEmployee = undefined;
    const utcTime = Math.floor(Date.now() / 1000);

    const heartbeat = new Heartbeat();
    heartbeat.hotelId = hotelId;
    heartbeat.roomName = roomName;
    heartbeat.roomId = roomId;
    heartbeat.deviceType = DeviceType.TABLET;
    heartbeat.updated = utcTime;
    heartbeat.loggedIn = loggedIn;
    heartbeat.lastLoggedInEmployee = lastLoggedInEmployee;

    return heartbeat;
  }
}
