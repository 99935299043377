import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'projects/shared/environments/environment';

@Component({
  selector: 'app-back-to-start',
  templateUrl: './back-to-start.component.html',
  styleUrls: ['./back-to-start.component.scss'],
})
export class BackToStartComponent implements OnInit {
  @Output() public backPressed = new EventEmitter<boolean>();
  
  constructor() {}

  public ngOnInit(): void {}

  public navigateToStartPage(): void {
    this.backPressed.emit(true);
    window.open(environment.participantURL, '_self');
  }
}
