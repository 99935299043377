import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapToValues',
})
export class MapToValuesPipe implements PipeTransform {
  public transform(map: Map<any, any>): any[] {
    return Array.from(map.values());
  }
}
