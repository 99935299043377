<div
  id="iframe-overlay-container"
  [ngClass]="(iframeLoaded$ | async) ? 'd-flex' : 'd-none'"
>
  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="onCloseIframeOverlay()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div id="iframe-container">
    <iframe
      #iframe
      class="flex-1 w-100 h-100"
      id="iframe-overlay"
      name="iframe-overlay"
      title="In-app Browser"
      allow="autoplay encrypted-media"
      sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals"
      allowfullscreen
      [src]="iframeSrc$ | async"
      (load)="onIframeLoaded()"
      (error)="onIframeError($event)"
    ></iframe>
  </div>
</div>
