<ng-container *ngIf="imageFiles$ | async as imageFiles">
  <ng-container *ngIf="!hideEmptyCarousel || imageFiles.length > 0">
    <mdb-carousel
      id="participantHomeCarousel"
      class="carousel slide carousel-fade"
      [animation]="'fade'"
    >
      <ng-container *ngFor="let imageFile of imageFiles">
        <mdb-carousel-item>
          <div class="view w-100 h-100" style="color: transparent !important">
            <img
              class="d-block w-100 h-100"
              [src]="imageFile.url"
              alt="carousel slide"
            />
          </div>
        </mdb-carousel-item>
      </ng-container>
    </mdb-carousel>
  </ng-container>
</ng-container>
