import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
  Audio,
  AudioType,
  Playlist,
  RadioStation,
  Song,
} from '../models/music.model';
import { BhToastService } from './bh-toast.service';
import { MusicService } from './music.service';
import { ResettableService } from './resettable.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerService implements OnDestroy {
  public volumeSubject = new BehaviorSubject<number>(1);
  public mutedSubject = new BehaviorSubject<boolean>(false);

  public $isPlayingSubject = new BehaviorSubject<boolean>(false);
  public $currentSongSubject = new BehaviorSubject<Song>(null);
  public currentAudioSubject = new BehaviorSubject<Audio>(undefined);

  public gong: HTMLAudioElement;
  public player: HTMLAudioElement;

  public currentRadioStation: RadioStation;
  public isPlaying: boolean;
  public currentPlaylist: Playlist;
  public currentSong: Song;

  public currentTime = 0;

  public settingPlaylists: Playlist[] = [];

  public isPlayingSubscription: Subscription;

  constructor(
    private _resettableService: ResettableService,
    private toastService: BhToastService,
    private musicService: MusicService
  ) {
    this.setupGong();
    this.setupPlayer();

    this._resettableService.resetNow.subscribe((_) => {
      this.gong.pause();
      this.gong.currentTime = 0;

      this.volumeSubject.next(1);
      this.mutedSubject.next(false);

      if (this.player) {
        this.player.pause();
      }
    });

    this.isPlayingSubscription = this.$isPlayingSubject.subscribe(
      (isPlaying) => {
        this.isPlaying = isPlaying;
      }
    );
  }

  public ngOnDestroy(): void {
    this.isPlayingSubscription?.unsubscribe();
  }

  public setupGong(): void {
    this.gong = new Audio();
    this.gong.src = '/assets/sounds/gong.mp3';
  }

  public setupPlayer(): void {
    this.player = new Audio();

    this.player.onerror = () => {
      this.toastService.warning('master.cannot-play-song');
      this.$isPlayingSubject.next(false);
    };

    this.player.onended = () => this.onEnded();
    this.player.onplay = () => {
      this.$isPlayingSubject.next(true);
      this.$currentSongSubject.next(this.currentSong);
    };
    this.player.onpause = () => this.$isPlayingSubject.next(false);
  }

  public playGongSound(): void {
    this.gong.load();
    this.gong.play();
  }

  public initPlayer(reset: boolean): void {
    if (!reset && !this.player.paused) {
      return;
    }

    this.player.load();
    this.currentRadioStation = null;
    this.player.src = this.currentSong.url;
  }

  public initRadioStation(radioStation: RadioStation): void {
    if (this.player.paused) {
      this.player.load();

      this.currentRadioStation = radioStation;
      this.currentPlaylist = null;
      this.currentSong = null;

      this.player.src = this.currentRadioStation.url;
    }
  }

  public playOrPauseRadioStation(
    play: boolean,
    radioStation: RadioStation
  ): void {
    this.currentRadioStation = radioStation;
    this.currentPlaylist = null;
    this.currentSong = null;

    if (play) {
      this.player.load();
      this.player.src = this.currentRadioStation.url;
      this.player.currentTime = 0;
      this.player.play();
    } else {
      this.player.pause();
    }

    this.currentAudioSubject.next(this.currentRadioStation);
  }

  private onEnded(): void {
    this.isPlaying = false;

    if (!this.currentPlaylist) {
      return;
    }

    // Play the next song or replay the playlist
    // from the first track if playlist 'loop' is enabled.
    const currentIndex = this.currentPlaylist.songs?.findIndex(
      (song) => song.fileName === this.currentSong?.fileName
    );

    const nextIndex = currentIndex + 1;
    const isLastTrack = currentIndex === this.currentPlaylist.songs.length - 1;

    let nextSong: Song;

    if (isLastTrack) {
      if (this.currentPlaylist?.loop) {
        // Play the playlist again.
        nextSong = this.currentPlaylist.songs[0];
      } else {
        if (this.currentSong?.loop) {
          // Play the song again.
          nextSong = this.currentSong;
        } else {
          // Stop playing.
        }
      }
    } else {
      if (this.currentSong?.loop) {
        // Play the song again.
        nextSong = this.currentSong;
      } else {
        // Check if it's a valid song for this index.
        const isValid = !(nextIndex >= this.currentPlaylist.songs.length);

        if (isValid) {
          // Play the next song.
          nextSong = this.currentPlaylist.songs[nextIndex];
        } else {
          console.error('Invalid music track.');
        }
      }
    }

    if (nextSong) {
      const newSongCopy = Object.assign(new Song(), nextSong);
      this.playOrPause(newSongCopy, this.currentPlaylist);
    }
  }

  public playOrPause(song: Song, playlist: Playlist): void {
    if (!this.player || !song || !playlist) {
      console.error('Invalid player');
      return;
    }

    if (this.currentPlaylist?.directoryName !== playlist.directoryName) {
      this.currentPlaylist = Object.assign(new Playlist(), playlist);
      this.currentRadioStation = null;
    }

    const sameTrack = this.currentSong?.fileName === song.fileName;

    if (sameTrack) {
      if (!this.player.paused) {
        // Pause the track.
        this.player.pause();
        this.currentTime = this.player.currentTime;
        return;
      } else {
        // Resume the paused track.
        this.player.load();
        this.player.currentTime = this.currentTime;
      }
    } else {
      // Play the new track.
      this.player.load();
      this.player.src = song.url;
      this.player.currentTime = 0;
      this.currentTime = 0;
    }

    this.currentSong = Object.assign(new Song(), song);

    this.player.play();
    // this.player.ontimeupdate = (time: any) => {};

    this.currentAudioSubject.next(this.currentPlaylist);
  }

  public playOrPauseCurrentAudio(audio: Audio): void {
    const play = !this.isPlaying;

    if (play) {
      this.player.play();
    } else {
      this.player.pause();
    }
  }

  public playOrPauseAll(play: boolean, playlist: Playlist): void {
    if (!this.player || !playlist) {
      console.error('Invalid player');
      return;
    }

    if (this.currentPlaylist?.directoryName !== playlist.directoryName) {
      this.currentPlaylist = Object.assign(new Playlist(), playlist);
      this.currentRadioStation = null;
    }

    this.player.pause();

    if (play) {
      this.currentSong = Object.assign(
        new Song(),
        this.currentPlaylist.songs[0]
      );
      this.player.src = this.currentSong.url;
      this.player.currentTime = 0;
      this.currentTime = 0;

      this.player.play();
    }

    this.currentAudioSubject.next(this.currentPlaylist);
  }

  private resetTrackLoops(playlist: Playlist): void {
    if (!playlist) {
      return;
    }

    // Disable loop for each track if the playlist loop is active now.
    if (playlist.loop) {
      playlist.songs.forEach((song) => {
        song.loop = false;
      });
    }
  }

  public toggleTrackLoop(
    song: Song,
    playlist: Playlist,
    enable?: boolean
  ): void {
    if (!song || !playlist) {
      return;
    }

    // Update the loop flag of the song in its parent playlist.
    song.loop = enable ? enable : !song.loop;

    this.updateSettingPlaylistSong(song, playlist.directoryName);

    const isCurrentPlaylist =
      this.currentPlaylist?.directoryName === playlist.directoryName;

    // Update the loop flag of the current song of the music player.
    if (isCurrentPlaylist && this.currentSong.fileName === song.fileName) {
      this.currentSong.loop = song.loop;
    }

    if (song.loop) {
      // Disable the loop of the given playlist.
      playlist.loop = false;

      // Disable the loop of the currenty playlist of the music player too if it's the same playlist.
      if (isCurrentPlaylist) {
        this.currentPlaylist.loop = false;
      }

      // Disable the loop for each other song of the given playlist.
      playlist.songs
        .filter((otherSong) => otherSong.fileName !== song.fileName)
        .forEach((otherSong) => {
          otherSong.loop = false;
        });
    }

    this.updateSettingPlaylist(playlist);

    if (isCurrentPlaylist) {
      // Set the loop flag for the currently loaded song of the music player too.
      // For all other songs of the music player set the loop flag to false if the loop flag
      // Disables the loop for any other song in the currently loaded music player playlist
      // if the loop of the currently selected song is set to true,
      // because only one song in the playlist can logically be played in loop.
      this.currentPlaylist.songs.forEach((s) => {
        if (s.fileName === song.fileName) {
          s.loop = song.loop;
        } else {
          s.loop = song.loop === true ? false : s.loop;
        }
      });
    }
  }

  public togglePlaylistLoop(playlist: Playlist, enable?: boolean): void {
    if (!playlist) {
      return;
    }

    // Update the loop flag of this playlist.
    playlist.loop = enable ? enable : !playlist.loop;

    this.resetTrackLoops(playlist);
    this.updateSettingPlaylist(playlist);

    // Update the current playlist.
    if (this.currentPlaylist?.directoryName === playlist.directoryName) {
      this.currentPlaylist = Object.assign(new Playlist(), playlist);
    }
  }

  public muteOrUnmute(mute: boolean): void {
    this.mutedSubject.next(mute);
  }

  public adjustVolume(volume: number): void {
    if (volume > 0) {
      this.muteOrUnmute(false);
    }
    this.volumeSubject.next(volume);
    this.player.volume = volume;
  }

  public getSettingPlaylistOrNewCopyById(id: string): Playlist {
    let settingPlaylist: Playlist = this.settingPlaylists?.find(
      (pl) => pl.directoryName === id
    );
    if (!settingPlaylist) {
      settingPlaylist = this.musicService.getPlaylistById(id);
      // Enable playlist loop as default.
      settingPlaylist.loop = true;
      this.settingPlaylists.push(settingPlaylist);
    }
    return Object.assign(new Playlist(), settingPlaylist);
  }

  public updateSettingPlaylist(playlist: Playlist): void {
    const settingPlaylist = this.settingPlaylists?.find(
      (pl) => pl.directoryName === playlist.directoryName
    );
    if (settingPlaylist) {
      settingPlaylist.loop = playlist.loop;
      settingPlaylist?.songs?.forEach((s1) => {
        playlist.songs
          ?.filter((s2: Song) => s1.fileName === s2.fileName)
          .forEach((s2) => (s1.loop = s2.loop));
      });
    }
  }

  public updateSettingPlaylistSong(song: Song, playlistId: string): void {
    const settingPlaylist = this.settingPlaylists?.find(
      (pl) => pl.directoryName === playlistId
    );

    if (settingPlaylist) {
      settingPlaylist.songs?.forEach((s1) => {
        if (s1.fileName === song.fileName) {
          s1.loop = song.loop;
        }
      });
    }
  }

  public addSettingPlaylist(playlist: Playlist): void {
    if (!this.findSettingPlaylistById(playlist.directoryName)) {
      this.settingPlaylists.push(playlist);
    }
  }

  public findSettingPlaylistById(id: string): Playlist | undefined {
    const origPlaylist: Playlist = this.settingPlaylists?.find(
      (pl) => pl.directoryName === id
    );

    return Object.assign(new Playlist(), origPlaylist);
  }
}
