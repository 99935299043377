import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MDBModalService } from 'ng-uikit-pro-standard';
import { AnchorTarget, HomeCard } from 'projects/shared/models/home-card.model';
import { QrCodeInfoService } from '../../services/qr-code-info.service';
import { HomeCardModalComponent } from './home-card-modal/home-card-modal.component';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeCardComponent implements OnInit {
  @Input() public homeCard: HomeCard;
  @Input() public fullscreenModal = false;
  @Input() public linkTarget: AnchorTarget = 'iframe';

  public imgSrc: SafeResourceUrl;

  public participantURLWithToken: string;

  constructor(
    private qrCodeInfoService: QrCodeInfoService,
    private modalService: MDBModalService
  ) {}

  public ngOnInit(): void {
    if (!this.homeCard) {
      this.participantURLWithToken =
        this.qrCodeInfoService.getParticipantQrCodeInfo().urlWithToken;
    } else {
      this.imgSrc = this.homeCard.previewImageUrl
        ? this.homeCard.previewImageUrl
        : this.homeCard.mainImageUrl
        ? this.homeCard.mainImageUrl
        : null;
      if (!this.homeCard.showTitleInPreview && !this.imgSrc) {
        this.imgSrc = 'assets/BeyondHostIcon.png';
      }
    }
  }

  public onCardClicked(): void {
    if (!this.homeCard) {
      this.modalService.show(HomeCardModalComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-dialog-centered modal-lg modal-dialog-scrollable',
      });
      return;
    }

    let modalClass;

    if (this.fullscreenModal) {
      modalClass = 'modal-frame modal-full-height modal-dialog-scrollable';
    } else {
      let modalSizeClass = 'modal-lg';
      modalSizeClass = this.homeCard.modalSize
        ? `modal-${this.homeCard.modalSize}`
        : 'modal-lg';

      modalClass = `modal-dialog-centered modal-dialog-scrollable ${modalSizeClass}`;
    }

    this.modalService.show(HomeCardModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: modalClass,
      data: {
        homeCard: this.homeCard,
        fullscreenModal: this.fullscreenModal,
        linkTarget: this.linkTarget,
      },
    });
  }
}
