<div class="modal-content">
  <div class="modal-header d-flex justify-content-between">
    <h4
      *ngIf="modalTitleTxt"
      class="modal-title w-100"
      [innerHTML]="modalTitleTxt | translate : modalTitleTxtParam"
    ></h4>
    <div *ngIf="showCancelButtonInHeader" class="col-auto">
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="onCancelButtonClicked()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div [innerHTML]="modalBodyTxt | translate : modalBodyTxtParam"></div>
  </div>
  <div *ngIf="!hideFooter" class="modal-footer">
    <button
      mdbBtn
      type="button"
      class="custom-btn decline-button"
      mdbWavesEffect
      (click)="onCancelButtonClicked()"
    >
      {{ (negativeBtnTxt ? negativeBtnTxt : 'global.cancel') | translate }}
    </button>
    <button
      mdbBtn
      type="button"
      class="custom-btn accept-button"
      mdbWavesEffect
      (click)="onConfirmButtonClicked()"
    >
      {{ (positiveBtnTxt ? positiveBtnTxt : 'global.okay') | translate }}
    </button>
  </div>
</div>
