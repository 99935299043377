<mdb-navbar
  SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg"
  [containerInside]="false"
>
  <div
    id="insideNavbar"
    class="w-100 h-100 d-flex flex-row justify-content-between"
    [class.responsive]="responsive"
  >
    <mdb-navbar-brand>
      <ng-container *ngIf="activateLongPress">
        <img
          height="30px"
          [src]="
            logoType === 'black'
              ? 'assets/BeyondHostLogo.png'
              : 'assets/BeyondHostLogoWhite.png'
          "
          alt="Logo"
          appLongPress
          [longPressThresholdMs]="3000"
          (longPressDown)="longPress.emit()"
        />
      </ng-container>
      <ng-container *ngIf="!activateLongPress && showLogo">
        <img
          height="30px"
          [src]="
            logoType === 'black'
              ? 'assets/BeyondHostLogo.png'
              : 'assets/BeyondHostLogoWhite.png'
          "
          alt="Logo"
        />
      </ng-container>
    </mdb-navbar-brand>

    <a
      *ngIf="responsive"
      id="burgerMenuWrapper"
      (click)="onBurgerMenuClicked()"
    >
      <div id="burgerMenu" [class.active]="menuActive">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </a>

    <ng-content select="[topNavContent]"></ng-content>
  </div>
</mdb-navbar>
