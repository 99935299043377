import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FileUploadableService } from '../models/file-uploadable-service.model';
import { HotelImage } from '../models/hotel-image';
import { FileStorageInfo } from '../models/image-file.model';
import { ParticipantImageConfig } from '../models/participant-image-config.model';
import { ApplicationHttpClient } from './application-http-client';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ImageFileService implements FileUploadableService<HotelImage> {
  private url = 'api/files';
  private publicUrl = 'files';
  private participantMeetingId: string;

  constructor(
    private http: ApplicationHttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.participantMeetingId = this.localStorageService.loadMeeting();
  }

  public uploadFiles(files: File[], hotelId: string): Observable<HotelImage[]> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('file', file);
    });

    const headers = new HttpHeaders({
      hotelId,
    });

    return this.http.post<HotelImage[]>(this.url + '/upload', formData, {
      headers,
    });
  }

  public getFiles(hotelId: string): Observable<HotelImage[]> {
    const headers = new HttpHeaders({
      hotelId,
    });

    return this.http.get<HotelImage[]>(this.url + '/list/images', {
      headers,
    });
  }

  public getFilesAsParticipant(): Observable<ParticipantImageConfig> {
    return this.http.get<ParticipantImageConfig>(
      this.publicUrl + '/list/images/participant/' + this.participantMeetingId
    );
  }

  public deleteFile(hotelId: string, id: string): Observable<void> {
    const headers = new HttpHeaders({
      hotelId,
    });

    return this.http.delete<void>(this.url + '/' + id, {
      headers,
    });
  }

  public getFileStorageInfo(hotelId: string): Observable<FileStorageInfo> {
    const headers = new HttpHeaders({
      hotelId,
    });

    return this.http.get<FileStorageInfo>(this.url + '/storage/info', {
      headers,
    });
  }

  public updateFiles(imageFiles: HotelImage[]): Observable<HotelImage[]> {
    return this.http.put<HotelImage[]>(this.url, imageFiles);
  }
}
