import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badgeCount',
})
export class BadgeCountPipe implements PipeTransform {
  public transform(count: number): string {
    return count > 99 ? '99+' : String(count);
  }
}
