import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivationState, Client } from '@stomp/stompjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject, Subscription, fromEvent } from 'rxjs';

import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { SubscriptionSTOMPKey } from '../models/subscriptionSTOMPKey.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationWebsocketClient implements OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;
  private _url = environment.websocketURL;
  private _client: Client;

  private visibilitySubscription: Subscription;

  public subscriptions: { [key in SubscriptionSTOMPKey]?: () => void } = {};
  public websocketConnection$ = new Subject<boolean>();

  constructor(private _router: Router, private authService: AuthService) {
    this.visibilitySubscription = fromEvent(document, 'visibilitychange')
      .pipe(filter(() => document.visibilityState === 'visible'))
      .subscribe(() => {
        if (!this.client || this._client.state === ActivationState.INACTIVE) {
          this.disconnect();
          this.reconnect();
        }
      });
  }

  public ngOnDestroy(): void {
    this.visibilitySubscription?.unsubscribe();
  }

  get client(): Client {
    if (this._client != null) {
      return this._client;
    }

    this._client = new Client({
      brokerURL: this._url,
      onDisconnect: () => {
        console.log('ApplicationWebsocketClient: onDisconnect()');
        this.handleWebsocketDisconnect();
      },
      onConnect: () => {
        console.log('ApplicationWebsocketClient: onConnect()');
        this.handleWebsocketConnect();

        if (this.blockUI.isActive) {
          this.blockUI.resetGlobal();
        }

        for (const key in this.subscriptions) {
          this.subscriptions[key]();
        }
      },
      onWebSocketError: (state) => {
        // See offline handling in MasterAppComponent via heartbeat.
        console.log('ApplicationWebsocketClient: onWebSocketError()', state);
        this.handleWebsocketDisconnect();
      },
      onStompError: (state) => {
        console.log('ApplicationWebsocketClient: onStompError()', state);
        if (state.headers.message === 'Meeting has ended.') {
          this.authService.loggedIn = false;
          this._router.navigate(['/meeting-ended']);
        }
        this.disconnect();
      },
      onWebSocketClose: (state) => {
        console.log('ApplicationWebsocketClient: onWebSocketClose()', state);
        if (this.authService.loggedIn) {
          this.reconnect();
        }
      },

      reconnectDelay: 2500,
      heartbeatIncoming: 15000,
      heartbeatOutgoing: 15000,
    });

    this._client.activate();
    return this._client;
  }

  public subscribeToTopic(key: string, fun: () => void): void {
    if (this.client.connected) {
      fun();
    }
    this.subscriptions[key] = fun;
  }

  public disconnectOfSubscription(key: string): void {
    this.subscriptions[key] = () => {};
  }

  public disconnect(): void {
    this._client?.deactivate();
    this._client = null;
  }

  private reconnect(): void {
    if (this._client == null) {
      this._client = this.client;
    }
  }

  private handleWebsocketConnect(): void {
    this.websocketConnection$.next(true);
  }

  private handleWebsocketDisconnect(): void {
    if (this.authService.loggedIn) {
      this.websocketConnection$.next(false);
    }
  }
}
