export class AbstractUser {
  public id: string;
  public name: string;
  public email: string;
  public password: string;
  public hotelId: string;
  public userRole: EmailUserRole;
  public prevUserRole: EmailUserRole;

  // Not in DTO:
  public roleName: string;
}

export enum EmailUserRole {
  MANAGER = 'MANAGER',
  XSCREEN_ADMIN = 'XSCREEN_ADMIN',
  HOTEL_ADMIN = 'HOTEL_ADMIN',
  STAFF = 'STAFF',
}

export class HotelAdminUser {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public password: string,
    public hotelId: number
  ) {}
}
export class StaffUser {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public hotelId: number
  ) {}
}
export class ManagerUser {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public password: string,
    public hotelId: number
  ) {}
}
