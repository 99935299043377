import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { LocalizationService } from 'projects/shared/services/localization.service';

@Pipe({
  name: 'appHotelDate',
})
export class HotelDatePipe extends DatePipe implements PipeTransform {
  constructor(
    private environmentService: EnvironmentService,
    public localizationService: LocalizationService
  ) {
    super(localizationService.getCurrentLangCode());
  }

  transform(value: any, args?: any): any {
    const hotelTimeZone = this.environmentService.hotel?.timeZone;
    if (!hotelTimeZone) {
      return super.transform(value, args);
    }
    const isoExtendedZone = moment.tz(hotelTimeZone).format('Z');

    const timeFormat = args ? args : 'dd.MM.yyyy' + ', HH:mm';

    return super.transform(value, timeFormat, isoExtendedZone);
  }
}
