<div class="file-input-list">
  <div class="input-container">
    <input
      type="text"
      placeholder="{{ 'global.file-upload.selected' | translate }}"
      [(ngModel)]="fileUploadState.selectedFilesDisplay"
      (ngModelChange)="fileUploadState.onFilesDisplayTextChange()"
    />
  </div>
  <button class="btn clear-btn">
    <i
      class="far fa-times-circle"
      (click)="fileUploadState.onClearButtonClick()"
    ></i>
  </button>
  <div *ngIf="isUploading" class="progress-container">
    <i class="fas fa-spinner fa-spin fa-2x"></i>
  </div>
  <div *ngIf="!autoUpload || failedUpload" class="button-container">
    <button
      mdbBtn
      class="btn-sm"
      mdbWavesEffect
      (click)="fileUploadState.triggerUpload(id)"
    >
      {{ 'global.file-upload.action' | translate }}
    </button>
  </div>
</div>
