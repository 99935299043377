import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReminderSetting } from '../models/reminder-setting.model';
import { ApplicationHttpClient } from './application-http-client';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReminderSettingService {
  private _url = 'api/reminder-setting/';

  constructor(
    private _http: ApplicationHttpClient,
    private _localStorageService: LocalStorageService
  ) {}

  public updateReminderSetting(
    reminderSetting: ReminderSetting
  ): Observable<ReminderSetting> {
    return this._http.put<ReminderSetting>(this._url, reminderSetting);
  }

  public loadReminderSetting(): Observable<ReminderSetting> {
    return this.getReminderSetting(this._localStorageService.loadHotel());
  }

  public loadMotionSensorCountdown(): Observable<number> {
    return this.getReminderSetting(this._localStorageService.loadHotel()).pipe(
      map((reminderSetting) => reminderSetting.motionSensorCountdown)
    );
  }
  private getReminderSetting(hotelId: string): Observable<ReminderSetting> {
    return this._http.get<ReminderSetting>(this._url + 'hotel/' + hotelId);
  }
}
