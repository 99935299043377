import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { VotingCountdownService } from '../../services/voting-countdown.service';
import { Voting } from './../../../shared/models/voting.model';

@Component({
  selector: 'app-progress-ring',
  templateUrl: './progress-ring.component.html',
  styleUrls: ['./progress-ring.component.scss'],
})
export class ProgressRingComponent implements OnChanges, OnDestroy {
  @Input() public remainingTime = 0;
  @Input() public isVotingActive = false;
  @Input() public voting: Voting;

  public countdownSubscription: Subscription;
  public counter = 0;

  constructor(public votingCountdownService: VotingCountdownService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'remainingTime':
            if (this.isVotingActive) {
              this.countdownSubscription =
                this.votingCountdownService.isRunning$.subscribe(
                  (isRunning) => {
                    if (!isRunning) {
                      this.votingCountdownService.destroyCountdown();
                    }
                  }
                );
            }
            break;
          default:
            break;
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.countdownSubscription?.unsubscribe();
  }
}
