<div class="my-login">
  <form [formGroup]="loginForm">
    <h2 class="custom-header">{{ 'global.login-title' | translate }}</h2>

    <input
      type="email"
      id="loginFormEmail"
      formControlName="email"
      class="form-control mb-4"
      placeholder="{{ 'global.email' | translate }}"
    />

    <input
      type="password"
      id="loginFormPassword"
      formControlName="password"
      class="form-control mb-4"
      autocomplete="on"
      placeholder="{{ 'global.password' | translate }}"
    />

    <button
      mdbBtn
      [block]="true"
      class="my-4 custom-btn"
      type="anmelden"
      (click)="login()"
    >
      {{ 'global.login' | translate }}
    </button>
  </form>
</div>
