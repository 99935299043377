import { Component, Input, OnInit } from '@angular/core';
import { FileUploadState } from '../../../services/file-upload-state.service';

@Component({
  selector: 'app-file-input-list',
  templateUrl: './file-input-list.component.html',
  styleUrls: ['./file-input-list.component.scss'],
})
export class FileInputListComponent implements OnInit {
  @Input() public id: string;
  @Input() public fileUploadState: FileUploadState;
  @Input() public autoUpload: boolean;
  @Input() public isUploading: boolean;
  @Input() public failedUpload: boolean;

  constructor() {}

  public ngOnInit(): void {}
}
