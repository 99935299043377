import { Pipe, PipeTransform } from '@angular/core';
import { EventType } from '../models/event.model';
import { LocalizationService } from '../services/localization.service';
import { getServiceTypeLocalizationKey } from '../util/util';

@Pipe({
  name: 'translateEventType',
})
export class TranslateEventTypePipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  public transform(eventType: EventType, eventDelay?: string): string {
    if (eventType === EventType.BREAKDELAY) {
      return `${this.localizationService.getTranslationForKey(
        getServiceTypeLocalizationKey(eventType)
      )}${eventDelay ? ' (' + eventDelay + ')' : ''}`;
    }

    return this.localizationService.getTranslationForKey(
      getServiceTypeLocalizationKey(eventType)
    );
  }
}
