<div class="file-field">
  <div
    mdbBtn
    [floating]="true"
    class="btn-md file-select-button"
    mdbWavesEffect
  >
    <i
      class="fas"
      [class]="
        showFileInput
          ? 'fa-upload'
          : isUploading
          ? 'fa-spinner fa-spin'
          : 'fa-upload'
      "
      aria-hidden="true"
    ></i>

    <input
      id="fileSelect"
      type="file"
      multiple
      (change)="fileUploadState.onFileInput($event, id)"
      mdbFileSelect
    />
  </div>
  <app-file-input-list
    *ngIf="showFileInput"
    class="w-100 ml-2"
    [id]="id"
    [fileUploadState]="fileUploadState"
    [isUploading]="isUploading"
    [autoUpload]="autoUpload"
    [failedUpload]="failedUpload"
  ></app-file-input-list>
</div>
