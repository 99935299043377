import { App } from 'projects/shared/environments/app';

export const environment = {
  app: App.HAD,
  production: false,
  apiURL: '/backend/',
  hardwareButtonURL: 'http://master-bhdev.weptun.de/backend/',
  masterURL: 'https://master-bhdev.weptun.de',
  participantURL: 'https://participant-bhdev.weptun.de',
  hadURL: 'https://had-bhdev.weptun.de',
  websocketURL: 'wss://had-bhdev.weptun.de/backend/ws',
  playlistBaseUrl: 'https://beyond.host/music',
  title: 'Admin Dashboard',
  useHeartbeat: false,
};
