<div class="d-flex flex-column h-100 justify-content-center align-items-center">
  <div class="btn-group" mdbDropdown>
    <a
      mdbDropdownToggle
      mdbWavesEffect
      type="button"
      class="dropdown-toggle waves-light align-items-center flag-container"
      [innerHtml]="
        languageFlags.get(selectedLanguage)
          ? languageFlags.get(selectedLanguage)
          : languageFlags.get('en')
      "
    ></a>
    <div class="dropdown-menu dropdown-primary">
      <a
        *ngFor="let lang of supportedLanguages"
        class="dropdown-item flag-container"
        [class.active]="selectedLanguage === lang"
        (click)="onSelectLanguage(lang)"
        [innerHtml]="languageFlags.get(lang) ? languageFlags.get(lang) : lang"
      ></a>
    </div>
  </div>
</div>
