import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Service } from '../../models/service.model';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent {
  public confirmed: Subject<boolean> = new Subject();
  public service: Service;

  public title = 'Are you sure?';
  public body = '';

  constructor(private modalRef: MDBModalRef) {}

  public onDeleteButtonClicked(): void {
    this.confirmed.next(true);
    this.modalRef.hide();
  }

  public onCancelButtonClicked(): void {
    this.confirmed.next(false);
    this.modalRef.hide();
  }
}
