<!-- Master -->
<div class="card w-100" *ngIf="displayMaster">
  <div class="card-header font-weight-bold">
    <i class="far fa-envelope fa-lg pt-1"></i> Master QR - Code
  </div>
  <div class="card-body">
    <app-qr-code [url]="masterURL"></app-qr-code>

    <div class="support-text">{{ 'global.qr-info.scan-text' | translate }}</div>
    <app-mail (emailEvent)="receiveSendMasterQRCode($event)"></app-mail>
  </div>
</div>
<!-- Participant -->
<div class="card w-100 h-100" *ngIf="!displayMaster">
  <div class="card-header font-weight-bold d-flex align-items-center">
    <i class="far fa-envelope fa-lg pt-1 mr-2"></i> Participant QR - Code
    <i
      *ngIf="isAdmin"
      class="ml-auto fa-2x fas fa-copy"
      style="cursor: pointer"
      (click)="onCopyClick()"
    ></i>
  </div>
  <div class="card-body" #qrCodeContainer>
    <app-participant-qr-info-body
      [url]="participantURL"
      [urlWithToken]="participantURLWithToken"
      [meetingPin]="meetingPin"
      [showSendToEmail]="!isAdmin"
    ></app-participant-qr-info-body>
  </div>
</div>
