import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BreakDelay,
  Service,
  ServiceBreakDelay,
} from '../models/service.model';
import { ApplicationHttpClient } from './application-http-client';

@Injectable({
  providedIn: 'root',
})
export class ServiceHttpService {
  private _url = 'api/service/';

  constructor(private _http: ApplicationHttpClient) {}

  // Get request for both service and break delay
  public getServiceBreakDelay(meeting: string): Observable<ServiceBreakDelay> {
    return this._http.get<ServiceBreakDelay>('api/combined/meeting/' + meeting);
  }

  public createService(service: Service): Observable<Service> {
    return this._http.post<Service>(this._url, service);
  }

  public updateService(service: Service): Observable<Service> {
    return this._http.put<Service>(this._url, service);
  }

  public getService(): Observable<Service> {
    return this._http.get<Service>(this._url);
  }
}
