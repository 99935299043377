import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { App } from '../environments/app';
import { environment } from '../environments/environment';
import { BA_PROTECTED_ROUTES } from '../util/util';
import { GlobalService } from './global.service';
import { LocalStorageService } from './local-storage.service';
import { ResettableService } from './resettable.service';

export interface IRequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export enum HTTPMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum HTTPStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationHttpClient {
  private api = environment.apiURL;

  // Extending the HttpClient through the Angular DI.
  public constructor(
    public http: HttpClient,
    private router: Router,
    private resetService: ResettableService,
    private globalService: GlobalService,
    private localStorageService: LocalStorageService
  ) {}

  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    if (options == null) {
      options = {};
    }

    options.withCredentials = true;
    return this.http
      .get<T>(this.api + endPoint, options)
      .pipe(
        tap(
          (success) =>
            this.showToast(HTTPStatus.SUCCESS, HTTPMethod.GET, endPoint),
          (err) => {
            this.handleError(err);
            this.showToast(HTTPStatus.ERROR, HTTPMethod.GET, endPoint, err);
          }
        )
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public post<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {
    if (options == null) {
      options = {};
    }

    options.withCredentials = true;

    return this.http.post<T>(this.api + endPoint, params, options).pipe(
      tap(
        (success) =>
          this.showToast(HTTPStatus.SUCCESS, HTTPMethod.POST, endPoint),
        (err) => {
          this.handleError(err);
          this.showToast(HTTPStatus.ERROR, HTTPMethod.POST, endPoint, err);
        }
      )
    );
  }

  public put<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {
    if (options == null) {
      options = {};
    }

    options.withCredentials = true;

    return this.http.put<T>(this.api + endPoint, params, options).pipe(
      tap(
        (success) =>
          this.showToast(HTTPStatus.SUCCESS, HTTPMethod.PUT, endPoint),
        (err) => {
          this.handleError(err);
          this.showToast(HTTPStatus.ERROR, HTTPMethod.PUT, endPoint, err);
        }
      )
    );
  }

  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    if (options == null) {
      options = {};
    }

    options.withCredentials = true;

    return this.http.delete<T>(this.api + endPoint, options).pipe(
      tap(
        (success) =>
          this.showToast(HTTPStatus.SUCCESS, HTTPMethod.DELETE, endPoint),
        (err) => {
          this.handleError(err);
          this.showToast(HTTPStatus.ERROR, HTTPMethod.DELETE, endPoint, err);
        }
      )
    );
  }

  private handleError(err: HttpErrorResponse): void {
    switch (err.status) {
      // case 302:
      //   this.handle302(err);
      //   break;
      case 401:
        this.handle401(err);
        break;
      case 420:
        this.router.navigate(['/meeting-ended']);
        break;
      case 0:
        document.dispatchEvent(new MessageEvent('offline'));
        break;
      default:
    }
  }

  private handle401(err: HttpErrorResponse): void {
    this.resetService.resetNow.next(true);

    const app: App = environment.app;
    switch (app) {
      case App.HAD:
      case App.XAD:
        this.doesRouteRequireBAAuth().subscribe((requiresBAAuth) => {
          let returnUrl: string =
            this.router.routerState?.snapshot?.root?.queryParams?.returnUrl;
          returnUrl = returnUrl ? decodeURIComponent(returnUrl) : returnUrl;

          if (
            requiresBAAuth ||
            this.router.url?.includes('beyond-admin-login')
          ) {
            this.router.navigate(['/beyond-admin-login'], {
              queryParams: { returnUrl },
            });
          } else {
            this.router.navigate(['login'], {
              queryParams: { returnUrl },
            });
          }
        });
        break;
      case App.MANAGER_APP:
        this.router.navigate(['login']);
        break;
      case App.MASTER_APP:
        if (this.router.url !== '/settings/manual') {
          this.router.navigate(['settings']);
        }
        break;
      case App.PARTICIPANT_APP:
        // The ionic app should stay on its landing page.
        // OR user is on meeting has ended page and should not be redirected to error page
        if (
          !this.globalService.isIonicApp() &&
          this.router.url !== '/meeting-ended' &&
          this.router.url !== '/'
        ) {
          this.router.navigate(['/error'], {
            queryParams: {
              errorMsg: 'Please rescan the QR code or login with meeting PIN',
            },
          });
        }
        break;
    }
  }

  // private handle302(err: HttpErrorResponse) {
  //   const app: App = environment.app;
  //   switch (app) {
  //     case App.PARTICIPANT_APP:
  //       this.localStorageService.saveMeeting(err.error.id);
  //       this.localStorageService.saveMeetingStartDate(err.error.startDate);
  //       this.router.navigate(['/meeting-upcoming']);
  //       break;
  //     default:
  //   }
  // }
  public showToast(
    status: HTTPStatus,
    method: HTTPMethod,
    endpoint: string,
    err?: HttpErrorResponse
  ): void {
    if (method === HTTPMethod.GET && status === HTTPStatus.SUCCESS) {
      // Don't display toasts for successful get requests.
      return;
    } else {
      console.log(status, method, endpoint, err);
    }
  }

  public doesRouteRequireBAAuth(): Observable<boolean> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1),
      switchMap((event: NavigationEnd) => {
        const requiresBAAuth =
          BA_PROTECTED_ROUTES.includes(event.url) ||
          this.localStorageService.loadIsBeyondAdmin();
        return of(requiresBAAuth);
      })
    );
  }
}
