import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss'],
})
export class FloatingButtonComponent implements OnInit {
  @Input() public text: string;
  @Input() public disabled = false;
  @Output() public clickEvent = new EventEmitter<void>();

  constructor() {}

  public ngOnInit(): void {}

  public onClick(): void {
    this.clickEvent.emit();
  }
}
