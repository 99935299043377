<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title w-100">
      {{ title | translate | titlecase }}
    </h4>
  </div>
  <div class="modal-body">
    {{ body | translate }}
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      class="custom-btn decline-button"
      mdbWavesEffect
      (click)="onCancelButtonClicked()"
    >
      {{ 'global.no' | translate }}
    </button>
    <button
      mdbBtn
      type="button"
      class="custom-btn accept-button"
      mdbWavesEffect
      (click)="onDeleteButtonClicked()"
    >
      {{ 'global.yes' | translate }}
    </button>
  </div>
</div>
