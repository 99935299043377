import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  public modalTitleTxt: string;
  public modalTitleTxtParam: any = null;
  public modalBodyTxt: string;
  public modalBodyTxtParam: any = null;
  public negativeBtnTxt: string;
  public positiveBtnTxt: string;
  public showCancelButtonInHeader = false;
  public hideFooter = false;

  public confirmClicked$: Subject<boolean> = new Subject();

  constructor(private _modalRef: MDBModalRef) {}

  public onCancelButtonClicked(): void {
    this.confirmClicked$.next(false);
    this._modalRef.hide();
  }

  public onConfirmButtonClicked(): void {
    this.confirmClicked$.next(true);
    this._modalRef.hide();
  }
}
