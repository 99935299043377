<div class="placeholder">
  <div class="floating-button-container">
    <button
      mdbBtn
      type="button"
      class="custom-btn m-0"
      (click)="onClick()"
      mdbWavesEffect
      [disabled]="disabled"
    >
      {{ text | translate }}
    </button>
  </div>
</div>
