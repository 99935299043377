import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMyInputFieldChanged } from 'ng-uikit-pro-standard';
import { LocalizationService } from 'projects/shared/services/localization.service';

@Component({
  selector: 'app-shared-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  // TODO: Localize using options?

  // Date picker model
  private _date: Date;
  @Input() public get date(): Date {
    return this._date;
  }
  public set date(newValue: Date) {
    this._date = newValue;
    this.dateChange.emit(this._date);
  }
  @Output() public dateChange = new EventEmitter<Date>();

  // Label
  @Input() public label: string;

  // Date changed event fired by
  @Output() public inputFieldChanged = new EventEmitter<IMyInputFieldChanged>();

  public constructor(public localizationService: LocalizationService) {}
}
