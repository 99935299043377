import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from './application-http-client';
import { LocalStorageService } from './local-storage.service';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  private _url = 'api/mail/';
  constructor(
    private _http: ApplicationHttpClient,
    private _localStorageService: LocalStorageService,
    private localizationService: LocalizationService
  ) {}

  public sendMasterQREmail(email: string): Observable<void> {
    return this._http.post<void>(
      this._url.concat(this._localStorageService.loadMeeting(), '/', 'master/'),
      email
    );
  }

  public sendParticipantQREmail(
    email: string,
    mailLang?: string
  ): Observable<void> {
    const lang = mailLang ? mailLang : this.localizationService.currentLangKey;

    return this._http.post<void>(
      this._url.concat(
        this._localStorageService.loadMeeting(),
        '/',
        'participant?lang=' + lang
      ),
      email
    );
  }
}
