import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import {
  ClockPickerComponent,
  IMyOptions,
  MDBDatePickerComponent,
} from 'ng-uikit-pro-standard';
import { EnvironmentService } from 'projects/shared/services/environment.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent {
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('datePicker', { static: true }) datePicker: MDBDatePickerComponent;
  @ViewChild('timePicker', { static: true }) timePicker: ClockPickerComponent;

  @Input() disabled: boolean = false;
  @Input() inputText = 'Date and Time';
  @Input() myDatePickerOptions: IMyOptions;
  @Input() showPlaceholder: boolean = true;

  @Output() public dateChange = new EventEmitter<Date>();

  // Date picker model
  @Input() public date: Date;

  constructor(private environmentService: EnvironmentService) {}

  onDateChange = (date) => {
    if (date.jsdate !== null) {
      this.showPlaceholder = false;
      if(!this.date) {
        this.date = new Date();
      }
      this.date.setFullYear(date.jsdate.getFullYear());
      this.date.setMonth(date.jsdate.getMonth());
      this.date.setDate(date.jsdate.getDate());

      this.datePicker.closeBtnClicked(); // close date picker
      this.timePicker.openBtnClicked(); // open time picker
    }
  };

  onTimeChange = (event: string) => {
    let hours = +event.split(':')[0];
    let minutes = +event.split(':')[1];

    const hotelTimeZone = this.environmentService.hotel?.timeZone;
    let date = moment(this.date).tz(hotelTimeZone, true);
    date.hours(hours);
    date.minutes(minutes);

    this.date = date.toDate();
    this.dateChange.emit(this.date);
  };
}
