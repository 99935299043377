<app-qr-code
  #qrCode
  [url]="urlWithToken"
  [qrCodeWidth]="qrCodeWidth"
></app-qr-code>

<div
  *ngIf="meetingPin?.length > 0"
  class="mt-2 d-flex flex-column"
  style="line-height: 100%"
>
  <table class="w-100 mb-2">
    <tr>
      <td class="pr-1">
        <b>{{ ('global.meeting-url' | translate) + ': ' }}</b>
      </td>
      <td class="filler-column">{{ url }}</td>
    </tr>
    <tr>
      <td class="pr-1">
        <b>{{ ('global.meeting-pin' | translate) + ': ' }}</b>
      </td>
      <td class="filler-column">{{ meetingPin }}</td>
    </tr>
  </table>

  <ng-container *ngIf="showSendToEmail">
    <div class="support-text">
      {{ 'global.qr-info.info-text' | translate }}
    </div>
    <app-mail (emailEvent)="receiveSendParticipantQRCode($event)"></app-mail>
  </ng-container>
</div>
