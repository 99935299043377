import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() activateLongPress = false;
  @Input() public showLogo: boolean = true;
  @Input() logoType: 'black' | 'white' = 'black';
  @Input() responsive = false;
  @Input() menuActive = false;
  @Output() longPress = new EventEmitter();
  @Output() menuClicked = new EventEmitter();

  public currentToggleMode: string;

  constructor() {}

  public ngOnInit(): void {}

  public onBurgerMenuClicked(): void {
    this.menuActive = !this.menuActive;
    this.menuClicked.emit();
  }
}
