import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-time-zone-selection',
  templateUrl: './time-zone-selection.component.html',
  styleUrls: ['./time-zone-selection.component.scss'],
})
export class TimeZoneSelectionComponent implements OnInit {
  @Input() public maxHeight: number = 350;
  @Input() public selectedTimeZone: string;
  @Output() public onSelectedTimeZone = new EventEmitter<string>();

  public timeZones: string[] = moment.tz.names();

  filterBy: string = '';

  constructor() {}

  public ngOnInit(): void {}

  public onSelectTimeZone(selectedTimeZone: string): void {
    this.selectedTimeZone = selectedTimeZone;
    this.onSelectedTimeZone.emit(selectedTimeZone);
  }
}
