import { Pipe, PipeTransform } from '@angular/core';
import { DeviceType, Heartbeat } from 'projects/shared/models/heartbeat.model';

@Pipe({
  name: 'heartbeatFilter',
})
export class HeartbeatFilterPipe implements PipeTransform {
  public transform(
    heartbeats: Heartbeat[],
    deviceType: DeviceType
  ): Heartbeat[] {
    return heartbeats?.length > 0
      ? heartbeats.filter((h) => h.deviceType === deviceType)
      : [];
  }
}
