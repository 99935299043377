import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageFile } from './image-file.model';

export interface FileUploadableService<T extends ImageFile> {
  uploadFiles: (files: File[], id: string) => Observable<T[]>;
}

export const FILE_UPLOADABLE_SERVICE = new InjectionToken<
  FileUploadableService<any>
>('file-uploadable-service-provider');
