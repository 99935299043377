import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FileInputModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { ImageFileService } from '../../services/image-file.service';
import { FileInputListComponent } from './file-input-list/file-input-list.component';
import { FileUploadSelectComponent } from './file-upload-select/file-upload-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    FileInputModule,
  ],
  declarations: [FileUploadSelectComponent, FileInputListComponent],
  exports: [
    MDBBootstrapModulesPro,
    FormsModule,
    ReactiveFormsModule,
    FileInputModule,
    TranslateModule,
    FileUploadSelectComponent,
  ],
  providers: [ImageFileService],
})
export class FileUploadModule {}
