import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ImageFile } from '../../models/image-file.model';
import { ImageFileService } from '../../services/image-file.service';

@Component({
  selector: 'app-participant-carousel',
  templateUrl: './participant-carousel.component.html',
  styleUrls: ['./participant-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantCarouselComponent implements OnInit {
  @Input() public hideEmptyCarousel = false;

  // HammerJS Config
  @ViewChild('participantHomeCarousel', { static: true }) public carousel: any;

  @HostListener('swipeleft', ['$event']) public swipePrev(event: any): void {
    this.carousel.nextSlide();
  }

  @HostListener('swiperight', ['$event']) public swipeNext(event: any): void {
    this.carousel.previousSlide();
  }

  public imageFiles$: BehaviorSubject<ImageFile[]> = new BehaviorSubject([]);

  constructor(private imageFileService: ImageFileService) {}

  public ngOnInit(): void {
    this.imageFileService
      .getFilesAsParticipant()
      .pipe(take(1))
      .subscribe((participantImageInfo) => {
        let images: ImageFile[] = [];
        if (participantImageInfo.showHotelImages) {
          images = participantImageInfo.showMeetingImagesAtTheBeginning
            ? [].concat(
                participantImageInfo.meetingImages,
                participantImageInfo.hotelImages
              )
            : [].concat(
                participantImageInfo.hotelImages,
                participantImageInfo.meetingImages
              );
        } else {
          images = participantImageInfo.meetingImages;
        }

        this.imageFiles$.next(images);
      });
  }
}
