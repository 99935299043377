import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { PlayerService } from '../../services/player.service';

@Component({
  selector: 'app-customer-service-outdoor-modal',
  templateUrl: './customer-service-outdoor-modal.component.html',
  styleUrls: ['./customer-service-outdoor-modal.component.scss'],
})
export class CustomerServiceOutdoorModalComponent
  implements OnInit, AfterViewInit
{
  public isAllowed: Subject<boolean> = new Subject();

  constructor(
    public modalRef: MDBModalRef,
    private playerService: PlayerService
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.playerService.playGongSound();
  }

  public onDeclineButtonClicked(): void {
    this.modalRef.hide();
    this.isAllowed.next(false);
  }

  public onAcceptButtonClicked(): void {
    this.modalRef.hide();
    this.isAllowed.next(true);
  }
}
