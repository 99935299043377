import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { HomeCard } from '../../../models/home-card.model';

@Component({
  selector: 'app-master-card-modal',
  templateUrl: './home-card-modal.component.html',
  styleUrls: ['./home-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeCardModalComponent {
  public fullscreenModal = false;
  public homeCard: HomeCard;
  public linkTarget: 'iframe' | 'tab' | 'window';

  constructor(private modalRef: MDBModalRef) {}

  public closeModal(): void {
    this.modalRef.hide();
  }
}
