import { BehaviorSubject } from 'rxjs';
import { BaseService, HasDelay, MessageService } from './service.model';

export enum EventType {
  INDOOR = 'Indoor',
  OUTDOOR = 'Outdoor',
  REFRESH = 'Refresh',
  LOCK = 'Lock',
  BREAKDELAY = 'Break delay',
  MESSAGE = 'Message',
  GUEST_ENTERED_ROOM = 'Guest entered room',
}

export interface BaseEvent extends BaseService, HasDelay, MessageService {
  serviceId: string;
  referenceId: number;
  room: string;
  hotelId: string;
}

export interface EventResponse extends BaseEvent {
  timestamp: string;
  responseTime: number;
}

export interface EventModel extends BaseEvent {
  message?: string;
  timestamp: number;
  responseTime$: BehaviorSubject<number>;
}

export class EventMessageResponse {
  constructor(
    public allEvents: EventResponse[],
    public activeEvents: EventResponse[]
  ) {}
}
