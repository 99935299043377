import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { saveAs } from 'file-saver';
import { ClipboardService } from 'ngx-clipboard';
import { BhToastService } from 'projects/shared/services/bh-toast.service';
import { LocalizationService } from 'projects/shared/services/localization.service';
import { QrCodeInfoService } from '../../services/qr-code-info.service';
import { environment } from './../../environments/environment';
import { MailService } from './../../services/mail.service';
@Component({
  selector: 'app-qr-info',
  templateUrl: './qr-info.component.html',
  styleUrls: ['./qr-info.component.scss'],
})
export class QrInfoComponent implements OnInit {
  @Input() public displayMaster: boolean;
  @Input() public isAdmin: boolean;
  @Input() public textOverQR = '';
  @Input() private meetingId?: string;
  @Input() public meetingPin: string;
  @Input() private meetingApiKey?: string;
  @Input() private qrCodeFilename?: string;

  @ViewChild('qrCodeContainer', { static: false })
  qrCodeContainer: ElementRef<HTMLDivElement>;

  public email: FormControl;
  public masterURL: string;
  public participantURL: string;
  public participantURLWithToken: string;

  constructor(
    private toastService: BhToastService,
    private mailService: MailService,
    private localizationService: LocalizationService,
    private clipboardService: ClipboardService,
    private qrCodeInfoService: QrCodeInfoService
  ) {}

  public ngOnInit(): void {
    if (this.isAdmin) {
      const participantQrCodeInfo =
        this.qrCodeInfoService.getAdminParticipantQrCodeInfo(
          this.meetingId,
          this.meetingApiKey
        );
      this.participantURL = participantQrCodeInfo.baseUrl;
      this.participantURLWithToken = participantQrCodeInfo.urlWithToken;
      if (!this.qrCodeFilename) {
        this.qrCodeFilename = 'QRCode.png';
      } else {
        this.qrCodeFilename =
          'QRCode_' + this.qrCodeFilename.replace(/[^a-zA-Z ]/g, '');
      }
    } else {
      this.masterURL = this.qrCodeInfoService.getMasterUrl();
      const participantQrCodeInfo =
        this.qrCodeInfoService.getParticipantQrCodeInfo();
      this.participantURL = participantQrCodeInfo.baseUrl;
      this.participantURLWithToken = participantQrCodeInfo.urlWithToken;
      this.meetingPin = participantQrCodeInfo.meetingPin;
    }
  }

  public receiveSendParticipantQRCode($event): void {
    const email = $event.value;
    this.mailService.sendParticipantQREmail(email).subscribe(
      (_) => {
        this.toastService.successChunked(['global.mail-sent-to', email]);
      },
      (error: HttpErrorResponse) => {
        this.toastService.success('global.send-mail.error');
        console.log(error);
      }
    );
  }

  public receiveSendMasterQRCode($event): void {
    const email = $event.value;
    this.mailService.sendMasterQREmail(email).subscribe(
      (_) => {
        this.toastService.successChunked(['global.mail-sent-to', email]);
      },
      (error: HttpErrorResponse) => {
        this.toastService.success('global.send-mail.error');
        console.log(error);
      }
    );
  }
  public onCopyClick(): void {
    this.copyParticipantUrlAndPinToClipboard();
    this.saveAsImage();
  }

  private copyParticipantUrlAndPinToClipboard(): void {
    const participantInfoString: string =
      'Participant URL:  ' +
      environment.participantURL +
      '\nParticipant PIN: ' +
      this.meetingPin +
      '\n\n' +
      this.localizationService.getTranslationForKey(
        'global.app-store-links.android'
      ) +
      '\n' +
      this.localizationService.getTranslationForKey(
        'global.app-store-links.iOS'
      );
    this.clipboardService.copy('Participant URL: ' + participantInfoString);
    this.toastService.success('global.meeting.participant-info-copied');
  }

  private saveAsImage(): void {
    this.qrCodeContainer.nativeElement
      .querySelector('canvas')
      .toBlob((blob) => {
        saveAs(blob, this.qrCodeFilename);
      });
  }
}
