import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appFitScreenOrientationDirective]',
})
export class FitScreenOrientationDirective implements OnInit {
  constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    this.fitAspectRatio();
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(_: any): void {
    this.fitAspectRatio();
  }

  private fitAspectRatio(): void {
    const element = this.el?.nativeElement;
    const isHorizontal = window.innerWidth > window.innerHeight;

    if (isHorizontal) {
      element.style.height = '100%';
      element.style.width = 'auto';
    } else {
      element.style.width = '100%';
      element.style.height = 'auto';
    }
  }
}
