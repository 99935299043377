import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlayerService } from '../../services/player.service';

@Component({
  selector: 'app-volume',
  templateUrl: './volume.component.html',
  styleUrls: ['./volume.component.scss'],
})
export class VolumeComponent implements OnDestroy {
  public oldVolume = 100;
  public volumeToggle = 100;

  private volumeSubscription: Subscription;
  private mutedSubscription: Subscription;

  constructor(private playerService: PlayerService) {
    this.volumeSubscription = playerService.volumeSubject.subscribe(
      (volume) => {
        this.volumeToggle = volume * 100;
      }
    );

    this.mutedSubscription = this.playerService.mutedSubject.subscribe(
      (muted) => {
        this.volumeToggle = muted ? 0 : this.oldVolume;
      }
    );
  }

  public ngOnDestroy(): void {
    this.volumeSubscription?.unsubscribe();
    this.mutedSubscription?.unsubscribe();
  }

  public onVolumeValueChange(volume: number): void {
    this.volumeToggle = volume;
    this.oldVolume = volume;
    volume = volume / 100.0;
    this.playerService.adjustVolume(volume);
  }

  public onMuteVolumeClicked(): void {
    this.playerService.adjustVolume(0);
  }

  public onFullVolumeClicked(): void {
    this.volumeToggle = 100;
    this.oldVolume = 100;
    this.playerService.adjustVolume(1);
  }
}
