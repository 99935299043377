<div class="h-100">
  <a class="h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <img
        class="mr-2"
        (click)="onMuteVolumeClicked()"
        src="assets/music/mute.png"
        style="width: 24px; height: 24px"
        alt="Mute"
      />
      <input
        #slider
        class="custom-range"
        type="range"
        value="{{ volumeToggle }}"
        min="0"
        max="100"
        (input)="onVolumeValueChange($any($event.target).value)"
        style="height: 24px"
      />
      <img
        class="ml-2"
        (click)="onFullVolumeClicked()"
        src="assets/music/volume.png"
        style="width: 24px; height: 24px"
        alt="Volume Up"
      />
    </div>
  </a>
</div>
