import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimerService } from '../../../master-app/src/app/services/timer.service';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit, OnDestroy {
  public currentTime = new Date();
  public isTimerRunning = false;

  private timerSubscription: Subscription;

  constructor(public timerService: TimerService) {
    this.timerSubscription = this.timerService.timerIsRunning$.subscribe(
      (running) => {
        this.isTimerRunning = running;
      }
    );
  }

  public ngOnInit(): void {
    setInterval(() => {
      this.currentTime = new Date();
    }, 10 * 1000);
  }

  public ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
  }
}
