import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StompSubscription } from '@stomp/stompjs';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { QandA, QandAEvent, QandAType } from '../models/qanda.model';
import { SubscriptionSTOMPKey } from '../models/subscriptionSTOMPKey.model';
import { ApplicationHttpClient } from './application-http-client';
import { ApplicationWebsocketClient } from './application-websocket-client';
import { LocalStorageService } from './local-storage.service';
import { LocalizationService } from './localization.service';
import { ResettableService } from './resettable.service';

@Injectable({
  providedIn: 'root',
})
export class QandaService {
  public question$ = new Subject<QandAEvent>();
  public openQuestions: number;

  private _websocketSubscription: StompSubscription;

  private _url = 'api/qanda/';

  constructor(
    private http: ApplicationHttpClient,
    private websocket: ApplicationWebsocketClient,
    private resettableService: ResettableService,
    private localStorageService: LocalStorageService,
    private localizationService: LocalizationService
  ) {
    this.resettableService.resetNow.subscribe((_) => {
      this.clearQandAService();
      this.startConnection();
    });
  }

  public startConnection(): void {
    this.websocket.subscribeToTopic(SubscriptionSTOMPKey.QANDA, () => {
      this.subscribeToWebsocketConnection();
    });
  }

  public subscribeToWebsocketConnection(): void {
    this._websocketSubscription = this.websocket.client.subscribe(
      '/topic/qanda/' + this.localStorageService.loadMeeting(),
      (response) => {
        const question = JSON.parse(response.body) as QandAEvent;
        this.question$.next(question);
      }
    );
  }

  public clearQandAService(): void {
    this._websocketSubscription?.unsubscribe();
    this.websocket?.disconnectOfSubscription(SubscriptionSTOMPKey.QANDA);
    this._websocketSubscription = null;
  }

  public getQuestions(): Observable<QandA[]> {
    return this.http
      .get<any>(this._url.concat(this.localStorageService.loadMeeting()))
      .pipe(
        switchMap((questions) => {
          this.openQuestions = questions.filter(
            (question: QandA) => question.state === QandAType.OPEN
          )?.length;
          return of(questions);
        })
      );
  }

  public createQuestion(question: QandA): Observable<QandA> {
    if (question.meetingId == null) {
      question.meetingId = this.localStorageService.loadMeeting();
    }
    return this.http.post<QandA>(this._url, question);
  }

  public updateQuestion(question: QandA): Observable<QandA> {
    return this.http.put<QandA>(this._url, question);
  }

  public deleteQuestion(id: string): Observable<void> {
    return this.http.delete<void>(this._url.concat(id));
  }

  public sendMailWithAllQuestion(
    email: string,
    meetingId?: string,
    mailLang?: string
  ): Observable<void> {
    const lang = mailLang ? mailLang : this.localizationService.currentLangKey;

    console.log('sendMailWithAllQuestion()', email);
    return this.http.get<void>(
      this._url.concat(
        'email/',
        meetingId ? meetingId : this.localStorageService.loadMeeting()
      ),
      { params: new HttpParams().set('email', email).set('lang', lang) }
    );
  }
}
