export enum MessageType {
  LOGIN = 'login',
  PUSH_TOKEN = 'push_token',
  GONG_NOTIFICATION = 'gong_notification',
  IS_IONIC = 'is_ionic',
  START_QR_SCAN = 'start_qr_scan',
  QR_SCAN_RESULT = 'qr_scan_result',
  READY = 'ready',
  LANGUAGE = 'language',
  FEEDBACK = 'feedback',
  FEEDBACK_SUBMITTED = 'feedback_submitted',
  LINK_CLICKED = 'link_clicked',
}

export class IFrameMessage {
  public name: string;
  public type: MessageType;
  public data: any;

  public static construct(
    name: string,
    type: MessageType,
    data: any
  ): IFrameMessage {
    const instance = new IFrameMessage();
    instance.name = name;
    instance.type = type;
    instance.data = data;
    return instance;
  }
}
