import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Hotel } from '../models/hotel.model';
import { ApplicationHttpClient } from './application-http-client';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private url = 'api/hotel/';

  constructor(private http: ApplicationHttpClient) {}

  public getLocations(): Observable<Hotel[]> {
    return this.http.get<Hotel[]>(this.url + 'list');
  }

  public updateLocation(location: Hotel): Observable<Hotel> {
    return this.http.put<Hotel>(this.url, location);
  }

  public createLocation(location: Hotel): Observable<Hotel> {
    return this.http.post<Hotel>(this.url, location);
  }

  public deleteLocation(locationId: string): Observable<void> {
    return this.http.delete<void>(this.url + locationId);
  }
}
