import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { BhToastService } from '../../services/bh-toast.service';
import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-iframe-overlay-container',
  templateUrl: './iframe-overlay-container.component.html',
  styleUrls: ['./iframe-overlay-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IframeOverlayContainerComponent implements OnInit {
  public iframeLoaded$ = new BehaviorSubject<boolean>(false);
  public iframeSrc$: BehaviorSubject<SafeUrl>;

  @ViewChild('iframe', { static: false })
  public iframe: ElementRef<HTMLIFrameElement>;

  constructor(
    private toastService: BhToastService,
    private loggerService: LoggerService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.iframeSrc$ = new BehaviorSubject<SafeUrl>(
      this.sanitizer.bypassSecurityTrustResourceUrl('about:blank')
    );
  }

  public onIframeLoaded(): void {
    // Handles Firefox issue where onload is called immediately after the iframe is created
    try {
      if (
        !this.iframe ||
        this.iframe.nativeElement.contentWindow?.location?.href ===
          'about:blank'
      ) {
        return;
      }
    } catch (e) {
      // In case a 'real' page is loaded, the above check will throw an error
    }
    this.iframeLoaded$.next(true);
  }

  public onIframeError($event): void {
    this.loggerService.logProdError('iframe error', $event);
    this.toastService.error('global.error-loading-iframe');
  }

  public onCloseIframeOverlay(): void {
    this.iframeLoaded$.next(false);
    this.iframeSrc$.next(
      this.sanitizer.bypassSecurityTrustResourceUrl('about:blank')
    );
  }
}
