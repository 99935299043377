import { Routes } from '@angular/router';
import { ErrorPageComponent } from '../components/error-page/error-page.component';
import { UnauthorizedPageComponent } from '../components/unauthorized-page/unauthorized-page.component';

export const sharedRoutes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    pathMatch: 'full',
  }
];
