import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { BhToastService } from '../../../services/bh-toast.service';
import { LoggerService } from '../../../services/logger.service';
import { MailService } from '../../../services/mail.service';

@Component({
  selector: 'app-participant-qr-info-body',
  templateUrl: './participant-qr-info-body.component.html',
  styleUrls: [
    './participant-qr-info-body.component.scss',
    '../qr-info.component.scss',
  ],
})
export class ParticipantQrInfoBodyComponent {
  @Input() public meetingPin: string;
  @Input() public urlWithToken: string;
  @Input() public url: string;
  @Input() public showSendToEmail: boolean;
  @Input() public qrCodeWidth: number = 150;

  constructor(
    private mailService: MailService,
    private toastService: BhToastService,
    private loggerService: LoggerService
  ) {}

  public receiveSendParticipantQRCode($event): void {
    const email = $event.value;
    this.mailService.sendParticipantQREmail(email).subscribe(
      (_) => {
        this.toastService.successChunked(['global.mail-sent-to', email]);
      },
      (error: HttpErrorResponse) => {
        this.toastService.success('global.send-mail.error');
        this.loggerService.logProdError(error);
      }
    );
  }
}
