import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-had-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isFullyBrowser = 'fully' in window;

  private TITLE = environment.title;

  constructor(private titleService: Title) {}

  public ngOnInit(): void {
    this.titleService.setTitle(this.TITLE);
  }
}
