import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ILogin } from '../../../../../../../shared/components/generic-login/generic-login.component';
import { App } from '../../../../../../../shared/environments/app';
import { AuthService } from '../../../../../../../shared/services/auth.service';
import { LocalStorageService } from '../../../../../../../shared/services/local-storage.service';

@Component({
  selector: 'app-bad-login',
  templateUrl: './bad-login.component.html',
  styleUrls: ['./bad-login.component.scss'],
})
export class BALoginComponent implements ILogin {
  constructor(
    public authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService
  ) {}

  public login(username: string, password: string, returnUrl: string): void {
    this.authService.loginBad(username, password).subscribe(
      () => {
        this.handleLoginSuccess(returnUrl);
      },
      () => this.handleLoginError()
    );
  }

  private handleLoginSuccess(returnUrl: string): void {
    this.authService.checkCredentials(App.XAD).subscribe(() => {
      this.authService.login(true);

      const hotelId = this.localStorageService.loadHotel();

      // Navigate by return-URL parameter if it's valid and a hotel is selected.
      if (returnUrl?.length > 0 && hotelId?.length > 0) {
        this.router.navigateByUrl(returnUrl);
      } else {
        this.router.navigate(['/main/locations']);
      }
    });
  }

  private handleLoginError(): void {
    this.toastr.error('Wrong credentials');
    this.localStorageService.saveIsBeyondAdmin(false);
  }
}
