import { DatePipe, registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeExtraDE from '@angular/common/locales/extra/de';
import localeExtraEN from '@angular/common/locales/extra/en';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import {
  DATE_FORMATS,
  SUPPORTED_LANGUAGE_TYPE,
  SUPPORTED_LANGUAGES,
} from '../util/util';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  public readonly LANGUAGE_CODES: Map<string, string> = new Map<string, string>(
    [
      ['en', 'en_US'],
      ['de', 'de_DE'],
    ]
  );
  public currentLangKey: SUPPORTED_LANGUAGE_TYPE = 'en';
  public preferredDateFormat: string;
  public datePipe: DatePipe;

  public currentLang$ = new BehaviorSubject<SUPPORTED_LANGUAGE_TYPE>('en');

  constructor(private translate: TranslateService) {}

  public translateApp(lang: SUPPORTED_LANGUAGE_TYPE): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.preferredDateFormat = this.getPreferredDateFormat(lang);
    this.datePipe = new DatePipe(this.LANGUAGE_CODES.get(lang));
    this.currentLangKey = lang;
    this.currentLang$.next(lang);

    switch (lang) {
      case 'en':
        registerLocaleData(localeEN, 'en-US', localeExtraEN);
        break;
      case 'de':
        registerLocaleData(localeDE, 'de-DE', localeExtraDE);
        break;
      default:
        registerLocaleData(localeEN, 'en-US', localeExtraEN);
    }
  }

  public translateAppDefault(): void {
    this.translateApp(this.getAvailableLang());
  }

  public getTranslationForKey(key: string): string {
    return this.translate.instant(key);
  }

  public getAvailableLang(): SUPPORTED_LANGUAGE_TYPE {
    this.currentLangKey =
      SUPPORTED_LANGUAGES.indexOf(this.translate.getBrowserLang() as any) > -1
        ? (this.translate.getBrowserLang() as SUPPORTED_LANGUAGE_TYPE)
        : SUPPORTED_LANGUAGES[0];
    return this.currentLangKey;
  }

  public getCurrentLangCode(): string {
    return this.LANGUAGE_CODES.get(this.currentLangKey);
  }

  public getDefaultLang(): string {
    return this.translate.getDefaultLang();
  }

  private getPreferredDateFormat(lang: string): string {
    if (DATE_FORMATS.get(lang)) {
      return (this.preferredDateFormat = DATE_FORMATS.get(lang));
    }

    for (const key of DATE_FORMATS.keys()) {
      if (key.includes(lang)) {
        return (this.preferredDateFormat = DATE_FORMATS.get(key));
      }
    }

    return lang;
  }
}
