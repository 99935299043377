import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../services/localization.service';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  transform(
    value: Date | string | number,
    format?: string,
    timezone?: string,
    locale?: string
  ): any {
    return new DatePipe(
      this.localizationService.getCurrentLangCode()
    ).transform(value, format, timezone, locale);
  }
}
