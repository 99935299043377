<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title w-100">
      {{ 'global.service-staff-waiting' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    {{ 'global.may-staff-enter-room' | translate }}
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      type="button"
      class="custom-btn decline-button"
      mdbWavesEffect
      (click)="onDeclineButtonClicked()"
    >
      {{ 'global.decline' | translate }}
    </button>
    <button
      mdbBtn
      type="button"
      class="custom-btn accept-button"
      mdbWavesEffect
      (click)="onAcceptButtonClicked()"
    >
      {{ 'global.accept' | translate }}
    </button>
  </div>
</div>
