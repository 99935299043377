import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedOutGuard } from 'projects/shared/guards/logged-out-guard';
import { sharedRoutes } from '../../../shared/routes/route.routing';
import { BALoginComponent } from './components/pages/login/bad-login/bad-login.component';
import { HALoginComponent } from './components/pages/login/had-login/had-login.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full',
      },
      {
        path: 'main',
        loadChildren: () =>
          import('./modules/main/main.module').then((m) => m.MainModule),
      },
    ],
  },
  {
    path: 'login',
    component: HALoginComponent,
    canActivate: [LoggedOutGuard],
    pathMatch: 'full',
  },
  {
    path: 'beyond-admin-login',
    component: BALoginComponent,
    canActivate: [LoggedOutGuard],
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot([...sharedRoutes, ...routes], {
      // Do only tracing use for debugging
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
