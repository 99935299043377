<ng-container *ngIf="homeCard; else participantQrCode">
  <div class="w-100 h-100">
    <div class="master-home-card-container" (click)="onCardClicked()">
      <ng-container *ngIf="imgSrc">
        <ng-container
          *ngIf="homeCard.title && homeCard.showTitleInPreview"
        >
          <div class="master-home-card-title light-gray-background">
            <p
              class="font-weight-bold title-clamp"
              [innerHTML]="homeCard.title | appSafeHtmlWithIframeTarget"
            ></p>
          </div>
        </ng-container>
        <div class="master-home-card-image d-flex">
          <img
            class="align-self-center"
            draggable="false"
            alt="Preview Image"
            [src]="imgSrc"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="!imgSrc">
        <div
          class="master-home-card-title d-flex justify-content-center h-100 w-100"
        >
          <p
            class="font-weight-bold align-self-center"
            [innerHTML]="homeCard.title | appSafeHtmlWithIframeTarget"
          ></p>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #participantQrCode>
  <div class="w-100 h-100">
    <div class="master-home-card-container" (click)="onCardClicked()">
      <div class="master-home-card-title light-gray-background">
        <p class="font-weight-bold">Participant Qr Code</p>
      </div>
      <div class="master-home-card-image d-flex justify-content-center">
        <qrcode
          [cssClass]="'scaled-down-canvas'"
          #qrCode
          [qrdata]="participantURLWithToken"
          [width]="200"
          [errorCorrectionLevel]="'M'"
          [margin]="0"
          [colorLight]="'#00000000'"
        >
        </qrcode>
      </div>
    </div>
  </div>
</ng-template>
