import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatepickerModule, WavesModule } from 'ng-uikit-pro-standard';
import { ToastrModule } from 'ngx-toastr';
import { ProjectModule } from '../../../shared/modules/project.module';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BadAuthGuard } from './guards/bad-auth-guard';
import { HadAuthGuard } from './guards/had-auth-guard';

@NgModule({
  imports: [
    AppRoutingModule,
    ProjectModule,
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
    DatepickerModule,
    WavesModule,
    FormsModule,
  ],
  declarations: [
    // Components
    AppComponent,
  ],
  providers: [
    // guards
    HadAuthGuard,
    BadAuthGuard,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
