import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-master-imprint-privacy-qr-full-screen-modal',
  templateUrl: './imprint-privacy-qr-full-screen-modal.component.html',
  styleUrls: ['./imprint-privacy-qr-full-screen-modal.component.scss'],
})
export class ImprintPrivacyQrFullScreenModalComponent
  implements OnInit, OnDestroy
{
  @Input() public modalTitle?: string;
  @Input() public modalBodyText?: string;
  @Input() public modalBodyQRCode?: string;
  @Input() public closeTimeoutInMinutes = 4;

  private timeout: ReturnType<typeof setInterval>;

  constructor(public modalRef: MDBModalRef) {}

  public ngOnInit(): void {
    this.timeout = setTimeout(
      () => this.modalRef.hide(),
      this.closeTimeoutInMinutes * 60 * 1000
    );
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}
