import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParticipantAppFeedback } from '../models/participant-app-feedback.model';
import { ApplicationHttpClient } from './application-http-client';

export interface ParticipantPushToken {
  id: string;
  value: string;
  language: 'en' | 'de';
  lastFeedbackSubmitted: number;
  lastFeedbackChoice: ParticipantAppFeedback;
  meetingId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ParticipantService {
  private participantUrl = 'api/participant/';

  constructor(private http: ApplicationHttpClient) {}

  public registerIonicAppForPushNotifications(
    meetingId: string,
    pushToken: string,
    language: 'en' | 'de'
  ): Observable<ParticipantPushToken> {
    const headers = new HttpHeaders({
      pushToken,
    });

    return this.http.get<ParticipantPushToken>(
      `${this.participantUrl}register/${meetingId}${
        language ? `?language=${language}` : ''
      }`,
      {
        headers,
      }
    );
  }

  public sendAppFeedback(
    feedback: ParticipantAppFeedback
  ): Observable<ParticipantAppFeedback> {
    return this.http.post(this.participantUrl + 'feedback', feedback);
  }
}
