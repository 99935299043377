import { Selectable } from './selectable.model';
import { AbstractService } from './service.model';

export enum DeviceType {
  WATCH = 'watch',
  TABLET = 'tablet',
  SERVICE_PHONE = 'service_phone',
}

export class Heartbeat implements Selectable {
  public id?: string;
  public deviceId?: string;
  public hotelId?: string;
  public roomName?: string;
  public roomId?: string;
  public deviceType?: DeviceType;
  public created?: number;
  public updated?: number;
  public batteryCharge?: number;
  public loggedIn?: boolean;
  public lastLoggedInEmployee?: string;

  // Not in DTO.
  public selected: boolean;
}

export class DeviceInfoWebSocketEvent {
  public deviceInfo: DeviceInfo;
  public deleted: boolean;
}

export class DeviceInfo extends Heartbeat {
  public service: AbstractService;
  public serviceDescription: string;
  public isDead = false;

  constructor() {
    super();
  }
}
