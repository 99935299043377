import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LoggerService } from 'projects/shared/services/logger.service';
import { Subscription } from 'rxjs';

import { ImageFile } from '../../../models/image-file.model';
import { BhToastService } from '../../../services/bh-toast.service';
import { FileUploadState } from '../../../services/file-upload-state.service';
import { JobConfig } from '../../image-processing/job-chain/model/job-chain';
import { JobChainService } from '../../image-processing/job-chain/services/job-chain.service';

export interface UploadResponse {
  success: boolean;
  data: ImageFile[] | undefined;
}

@Component({
  selector: 'app-file-upload-select',
  templateUrl: './file-upload-select.component.html',
  styleUrls: ['./file-upload-select.component.scss'],
  providers: [FileUploadState, JobChainService],
})
export class FileUploadSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public id = '';
  @Input() public autoUpload = false;
  @Input() public showFileInput = true;
  @Input() public jobConfig: JobConfig<File> = new JobConfig<File>(
    [],
    false,
    false
  );
  @Input() public allowedMimeType: RegExp;
  @Output() public response = new EventEmitter<UploadResponse>();

  public uploadSuccessSub: Subscription;
  public uploadErrorSub: Subscription;
  public isUploadingSub: Subscription;
  public failedUpload = false;
  public isUploading = false;

  constructor(
    public fileUploadState: FileUploadState,
    private toastService: BhToastService,
    private loggerService: LoggerService
  ) {}

  public ngOnInit(): void {
    this.setupUpload();
    this.isUploadingSub = this.fileUploadState.isUploading.subscribe(
      (isUploading) => {
        this.isUploading = isUploading;
      }
    );
    this.uploadSuccessSub = this.fileUploadState.onSuccess.subscribe(
      (response) => {
        this.response.next({ success: response?.length > 0, data: response });
        this.failedUpload = false;
      }
    );
    this.uploadErrorSub = this.fileUploadState.onError.subscribe((_) => {
      this.response.next({ success: false, data: undefined });
      this.failedUpload = true;
      this.toastService.success('global.file-upload.error');
    });
    if (!this.autoUpload && !this.showFileInput) {
      this.loggerService.logProdWarn('Invalid upload component configuration');
    }
  }

  public ngOnDestroy(): void {
    this.uploadSuccessSub.unsubscribe();
    this.uploadErrorSub.unsubscribe();
    this.isUploadingSub.unsubscribe();
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.setupUpload();
  }

  private setupUpload(): void {
    this.fileUploadState.setupUpload(
      this.autoUpload,
      this.showFileInput,
      this.allowedMimeType,
      this.jobConfig
    );
  }
}
