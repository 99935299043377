import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VotingCountdownService {
  public remainingTime$: BehaviorSubject<number> = new BehaviorSubject(0);
  public percent$: BehaviorSubject<number> = new BehaviorSubject(0);
  public interval: ReturnType<typeof setInterval>;
  public isRunning$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public startCountdown(start: number, remainingTime: number): void {
    if (!this.isRunning$.getValue()) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.isRunning$.next(true);
      this.remainingTime$.next(remainingTime);

      if (this.remainingTime$.getValue() > 0) {
        this.percent$.next((this.remainingTime$.getValue() / start) * 100);
      } else {
        this.percent$.next(0);
      }

      this.interval = setInterval(() => {
        if (this.remainingTime$.getValue() > 0) {
          this.remainingTime$.next(this.remainingTime$.getValue() - 1);
          this.percent$.next((this.remainingTime$.getValue() / start) * 100);
        } else {
          this.percent$.next(0);
          this.isRunning$.next(false);
        }
      }, 1000);
    }
  }

  public destroyCountdown(): void {
    this.remainingTime$.next(0);
    this.percent$.next(0);
    clearInterval(this.interval);
  }

  public stopCountdownEarly(): void {
    this.isRunning$.next(false);
    this.destroyCountdown();
  }
}
