import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { App } from '../environments/app';
import { environment } from '../environments/environment';
import { ParticipantCredentials } from '../models/participant-credentials';
import {
  ApplicationHttpClient,
  IRequestOptions,
} from './application-http-client';
import { LocalStorageService } from './local-storage.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn = false;
  private _urlLogin = 'login';
  private _urlValidate = 'api/validate/';
  public loggedInSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private _http: ApplicationHttpClient,
    private localStorageService: LocalStorageService,
    private loggerService: LoggerService
  ) {}

  public loginMaster(apiKey: string): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.set('API_KEY', apiKey);
    headers = headers.set('USER_ROLE', 'ROLE_MASTER');

    const options: IRequestOptions = {
      headers,
      withCredentials: true,
    };
    return this._http.get(this._urlLogin, options);
  }

  public loginGM(email: string, password: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('E-MAIL', email);
    headers = headers.set('PASSWORD', password);
    headers = headers.set('USER_ROLE', 'ROLE_MANAGER');

    const options: IRequestOptions = {
      headers,
      withCredentials: true,
    };
    return this._http.get(this._urlLogin, options);
  }

  public loginParticipant(
    meetingId: string,
    meetingApiKey: string
  ): Observable<void> {
    let headers = new HttpHeaders();

    headers = headers.set('MEETING_ID', meetingId);
    headers = headers.set('MEETING_API_KEY', meetingApiKey);

    const options: IRequestOptions = {
      headers,
      withCredentials: true,
    };
    return this._http.get(this._urlLogin, options);
  }

  public loginBad(email: string, password: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('E-MAIL', email);
    headers = headers.set('PASSWORD', password);
    headers = headers.set('USER_ROLE', 'ROLE_XSCREEN_ADMIN');

    const options: IRequestOptions = {
      headers,
      withCredentials: true,
    };
    return this._http.get(this._urlLogin, options);
  }

  public loginHad(email: string, password: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('E-MAIL', email);
    headers = headers.set('PASSWORD', password);
    headers = headers.set('USER_ROLE', 'ROLE_HOTEL_ADMIN');

    const options: IRequestOptions = {
      headers,
      withCredentials: true,
    };
    return this._http.get(this._urlLogin, options);
  }

  public checkCredentials(
    app?: App
  ): Observable<void | ParticipantCredentials> {
    let path = '';
    switch (app ? app : environment.app) {
      case App.MASTER_APP:
        path = 'master';
        break;
      case App.MANAGER_APP:
        path = 'manager';
        break;
      case App.PARTICIPANT_APP:
        path = 'participant';
        break;
      case App.HAD:
        path = 'hotel-admin';
        break;
      case App.XAD:
        path = 'xscreen-admin';
        break;
    }

    return this._http.get(this._urlValidate + path, { withCredentials: true });
  }

  public invalidateApiKey(): Observable<void> {
    return this._http.get('api/logout', { withCredentials: true });
  }

  public login(isBeyondAdmin?: boolean): void {
    this.loggedIn = true;
    this.loggedInSubject.next(true);
    if (isBeyondAdmin === true || isBeyondAdmin === false) {
      this.localStorageService.saveIsBeyondAdmin(isBeyondAdmin === true);
    }
  }

  public logout(doAfterLogout?: () => void): void {
    this.invalidateApiKey().subscribe(
      () => {
        this.loggerService.logDebug(
          'API key cookie has been invalidated successfully.'
        );
        this.loggedIn = false;
        this.loggedInSubject.next(false);
        this.localStorageService.saveIsBeyondAdmin(false);

        if (doAfterLogout) {
          doAfterLogout();
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.loggerService.logDebug(
            "Invalidating the API key cookie failed, because it's already invalidated."
          );
        } else {
          this.loggerService.logDebug(
            'Invalidating the API key cookie failed.',
            error
          );
        }
      }
    );
  }
}
