import { Injectable, isDevMode } from '@angular/core';

import { environment } from '../environments/environment';

@Injectable()
export class LoggerService {
  public logDebug(message?: any, ...optionalParams: any[]): void {
    if (isDevMode() || !environment.production) {
      console.log(message, optionalParams);
    }
  }

  public logProd(message?: any, ...optionalParams: any[]): void {
    console.log(message, optionalParams);
  }

  public logDebugWarn(message?: any, ...optionalParams: any[]): void {
    if (isDevMode() || !environment.production) {
      console.warn(message, optionalParams);
    }
  }

  public logProdWarn(message?: any, ...optionalParams: any[]): void {
    console.warn(message, optionalParams);
  }

  public logDebugError(message?: any, ...optionalParams: any[]): void {
    if (isDevMode() || !environment.production) {
      console.error(message, optionalParams);
    }
  }

  public logProdError(message?: any, ...optionalParams: any[]): void {
    console.error(message, optionalParams);
  }
}
