import { Component, Input, ViewChild } from '@angular/core';
import { MDBModalService } from 'ng-uikit-pro-standard';

import { QRCodeComponent } from 'angularx-qrcode';
import { ImprintPrivacyQrFullScreenModalComponent } from '../imprint-privacy-qr-full-screen-modal/imprint-privacy-qr-full-screen-modal.component';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent {
  @Input() public url = '';
  @Input() public qrCodeWidth = 150;
  @Input() public isClickable = true;

  @ViewChild('qrCode') public qrCode: QRCodeComponent;

  constructor(private modalService: MDBModalService) {}

  public onQRCodeClicked(qrCodeURL: string): void {
    this.modalService.show(ImprintPrivacyQrFullScreenModalComponent, {
      backdrop: true,
      keyboard: false,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      data: {
        modalBodyQRCode: qrCodeURL,
      },
      class:
        'modal-frame modal-full-height col-md-10 modal-notify modal-dialog-scrollable',
      animated: false,
    });
  }
}
