import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { EnvironmentService } from './environment.service';
import { LoggerService } from './logger.service';

export interface ParticipantQrCodeInfo {
  baseUrl: string;
  urlWithToken: string;
  meetingPin?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QrCodeInfoService {
  constructor(
    private loggerService: LoggerService,
    private environmentService: EnvironmentService
  ) {}

  public getParticipantQrCodeInfo(): ParticipantQrCodeInfo {
    const meeting = this.environmentService.meeting;

    if (!meeting) {
      this.loggerService.logProdError('Meeting is not defined');
      return null;
    }

    if (!meeting.id || !meeting.meetingApiKey || !meeting.loginPIN) {
      this.loggerService.logProdError(
        'Meeting id, meetingApiKey or loginPIN is not defined'
      );
      return null;
    }

    return this.generateParticipantQrCodeInfo(
      meeting.id,
      meeting.meetingApiKey,
      false,
      meeting.loginPIN
    );
  }

  public getAdminParticipantQrCodeInfo(
    meetingId: string,
    meetingApiKey: string
  ): ParticipantQrCodeInfo {
    return this.generateParticipantQrCodeInfo(meetingId, meetingApiKey, true);
  }

  public getMasterUrl(): string {
    return environment.masterURL;
  }

  private generateParticipantQrCodeInfo(
    meetingId: string,
    meetingApiKey: string,
    hasTrailingSlash: boolean,
    meetingPin?: string
  ) {
    const baseUrl = environment.participantURL;
    const urlWithToken = `${baseUrl}${
      hasTrailingSlash ? '/' : ''
    }main/home?id=${meetingId}&key=${meetingApiKey}`;
    return { baseUrl, urlWithToken, meetingPin };
  }
}
