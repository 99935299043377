import { Observable } from 'rxjs';

export interface IJob<T> {
  response$: Observable<JobResult<T>>;
  item: T;

  getJobResult(item: T, type: JobResultType): JobResult<T>;

  startAutomatedJob(item: T): Observable<JobResult<T>>;
  startJob(item: T): void;
  cancelJob(): void;

  onSuccess(item: T): void;
  onError(item: T): void;
  onSkip(item: T): void;
  onCancel(item: T): void;
}

export class JobResult<T> {
  type: JobResultType;
  data: T;
  jobType: JobType;
}

export enum JobResultType {
  SUCCESS = 'success',
  ERROR = 'error',
  CANCEL = 'cancel',
  SKIP = 'skip',
}

export enum JobType {
  CROP = 'crop',
  RESIZE = 'resize',
}

export class JobResponse<T> {
  item: T;
  results: JobResult<T>[];

  constructor(item: T, results: JobResult<T>[]) {
    this.item = item;
    this.results = results;
  }
}
export interface JobInterruptionListener<T> {
  onJobInterruption(item: T, result: JobResult<T>): void;
}

export class JobConfig<T> {
  jobInterruptionListener: JobInterruptionListener<T>;
  jobs: IJob<T>[];
  stopPipelineOnCancel = false;
  stopPipelineOnError = true;
  processItemsSimultaneously: boolean;

  constructor(
    jobs: IJob<T>[],
    stopPipelineOnCancel: boolean,
    stopPipelineOnError: boolean,
    jobInterruptionListener?: JobInterruptionListener<T>,
    processItemsSimultaneously: boolean = false
  ) {
    this.jobs = jobs;
    this.stopPipelineOnCancel = stopPipelineOnCancel;
    this.stopPipelineOnError = stopPipelineOnError;
    this.jobInterruptionListener = jobInterruptionListener;
    this.processItemsSimultaneously = processItemsSimultaneously;
  }
}
