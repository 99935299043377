import { Subject } from 'rxjs';

import { environment } from '../environments/environment';
import { EventType } from '../models/event.model';
import { DeviceInfo, DeviceType } from '../models/heartbeat.model';
import { Meeting } from '../models/meeting.model';
import { ReminderSetting } from '../models/reminder-setting.model';
import { ServiceStatus } from '../models/service.model';

export function getDeviceStatus(
  deviceInfo: DeviceInfo,
  reminderSetting: ReminderSetting
): DeviceStatus {
  if (deviceInfo.isDead) {
    return DeviceStatus.EXCLAMATION;
  }

  if (deviceInfo.deviceType === DeviceType.SERVICE_PHONE) {
    return deviceInfo.loggedIn ? DeviceStatus.GREEN : DeviceStatus.RED;
  }

  const nowUtc = Math.floor(Date.now() / 1000);
  let deviceStatus: DeviceStatus = DeviceStatus.RED;

  let statusRedTimeframe = 300; // 5 min
  let statusOrangeTimeframe = 120; // 2 min

  if (
    reminderSetting &&
    reminderSetting.useHeartbeatReminderForDeviceStatus &&
    reminderSetting.heartbeatManagerReminder &&
    reminderSetting.heartbeatStaffReminder
  ) {
    statusRedTimeframe = reminderSetting.heartbeatManagerReminder * 60;
    statusOrangeTimeframe = reminderSetting.heartbeatStaffReminder * 60;
  }

  if (deviceInfo.updated < nowUtc - statusRedTimeframe) {
    // Older than 5 min.
    deviceStatus = DeviceStatus.RED;
  } else if (deviceInfo.updated < nowUtc - statusOrangeTimeframe) {
    // Older than 2 min.
    deviceStatus = DeviceStatus.ORANGE;
  } else if (deviceInfo.updated >= nowUtc - statusOrangeTimeframe) {
    deviceStatus =
      !deviceInfo.loggedIn && deviceInfo.deviceType === DeviceType.WATCH
        ? // Newer than 2 min but logged out.
          DeviceStatus.YELLOW
        : // Newer than 2 min and logged in.
          DeviceStatus.GREEN;
  }

  return deviceStatus;
}

export function getDeviceStatusOrder(
  deviceInfo: DeviceInfo,
  reminderSetting: ReminderSetting
): number {
  const deviceStatus: DeviceStatus = getDeviceStatus(
    deviceInfo,
    reminderSetting
  );

  switch (deviceStatus) {
    case DeviceStatus.EXCLAMATION:
      return 4;
    case DeviceStatus.RED:
      return 3;
    case DeviceStatus.YELLOW:
      return 2;
    case DeviceStatus.GREEN:
      return 1;
    default:
      return -1;
  }
}

export enum DeviceStatus {
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  EXCLAMATION = 'exclamation',
}

/**
 * Clones the object using JSON.stringify and does assign the correct data type.
 */
export function deepClone<T>(type: new () => T, itemToClone: any): T {
  if (itemToClone === undefined) {
    return undefined;
  } else if (itemToClone == null) {
    return null;
  }
  const itemClone = JSON.parse(JSON.stringify(itemToClone));
  const itemCloneWithType = Object.assign(new type(), itemClone);
  return itemCloneWithType;
}

/**
 * Clones the object using JSON.stringify.
 */
export function deepClone2<T>(itemToClone: T): T {
  if (itemToClone === undefined) {
    return undefined;
  } else if (itemToClone == null) {
    return null;
  }
  const itemClone = JSON.parse(JSON.stringify(itemToClone));
  return itemClone as T;
}

/**
 * Clones the array using JSON.stringify and does assign the correct data type.
 */
export function deepCloneArray<T>(type: new () => T, arrayToClone: any[]): T[] {
  if (arrayToClone === undefined) {
    return undefined;
  } else if (arrayToClone == null) {
    return null;
  }
  const results: T[] = [];
  arrayToClone.forEach((item) => {
    const clone = deepClone(type, item);
    results.push(clone);
  });
  return results;
}

/**
 * Deep compares two objects using JSON.stringify.
 */
export function deepEquals(item1: any, item2: any): boolean {
  return JSON.stringify(item1) === JSON.stringify(item2);
}

export type Direction = 'asc' | 'desc' | 'constant';
export class Sort<T> {
  constructor(public direction: Direction, public sortBy: keyof T | '') {}
}

export class SortResult<T> {
  constructor(public data: T[], public newSort: Sort<T>) {}
}

/**
 * Returns a sorted copy of the given array and the new sort.
 * @param type Type of the array.
 * @param sortBy Property name of the object T.
 * @param currentSort The current sort or null. Used to invert the new sort.
 * @param data The array of data to be sorted.
 * @param nullValuesAt Use 'end' if null values should be at the end or 'start' if they should be at the start.
 * @returns A sorted copy of the array and the new sort
 * (which should be passed as a parameter the next time the function is called).
 */
export function sort<T>(
  type: new () => T,
  currentSort: Sort<T>,
  data: T[],
  sortBy?: keyof T,
  nullValuesAt?: 'end' | 'start',
  keepDirection?: boolean
): SortResult<T> {
  if (!data || data.length < 1) {
    return new SortResult<T>(data, currentSort);
  }

  if (!sortBy) {
    if (currentSort) {
      sortBy = currentSort.sortBy as keyof T;
    } else {
      console.error(
        'Wrong usage of sort function. Either sortBy or currentSort must be given.'
      );
      return undefined;
    }
  }

  if (!currentSort) {
    // Sort ascending as default.
    currentSort = new Sort('asc', sortBy);
  } else {
    if (!keepDirection) {
      // Invert sort direction if it's the same sort-by criteria.
      if (currentSort.sortBy === sortBy) {
        switch (currentSort.direction) {
          case 'asc':
            currentSort.direction = 'desc';
            break;
          case 'desc':
            currentSort.direction = 'asc';
            break;
          case 'constant':
            currentSort.direction = 'asc';
            break;
        }
      }
    }
    currentSort.sortBy = sortBy;
  }

  const dataCopy = deepCloneArray(type, data);
  const sortDirection = currentSort.direction;

  dataCopy?.sort((r1, r2) => {
    // tslint:disable-next-line:no-string-literal
    let v1: any = r1[sortBy];
    // tslint:disable-next-line:no-string-literal
    let v2: any = r2[sortBy];

    // Used for correct sorting of strings
    // Constructor hack because instanceof doesn`t seem to work
    if (v1.constructor === String && v2.constructor === String) {
      v1 = v1.toLocaleLowerCase() as any;
      v2 = v2.toLocaleLowerCase() as any;
    }

    if (nullValuesAt && (v1 === undefined || v2 === undefined)) {
      return (!v1 ? 1 : -1) * (nullValuesAt === 'end' ? 1 : -1);
    }

    const order: number =
      (v1 < v2 ? -1 : v1 > v2 ? 1 : 0) *
      (sortDirection === 'asc' ? 1 : sortDirection === 'desc' ? -1 : 0);

    return order;
  });

  return new SortResult<T>(dataCopy, currentSort);
}

export const ALLOWED_IMAGE_MIME_TYPES = new RegExp(
  'image/(png|jpeg|bmp|webp|gif)$'
);

export function getBase64StringFromFile(file: File): Subject<any> {
  const result = new Subject<any>();
  const reader = new FileReader();
  reader.onload = (event: any) => {
    result.next(event.target.result);
  };
  reader.readAsDataURL(file);
  return result;
}

export const BA_PROTECTED_ROUTES = ['/beyond-admin-login', '/main/locations'];

export const UUID_REGEX = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);

export function validateQrScanResult(result: {
  hasContent: boolean;
  content: string;
}): boolean {
  if (result.hasContent) {
    // Validate content.
    const hasValidBaseUrl =
      result.content?.length > 0 &&
      result.content?.startsWith(environment.participantURL + 'main/home');

    // Get credentials from URL parametes.
    const url = new URL(result.content);
    const meetingId = url.searchParams?.get('id');
    const meetingApiKey = url.searchParams?.get('key');

    // Validate credentials.
    const hasValidCredentials =
      UUID_REGEX.test(meetingId) && UUID_REGEX.test(meetingApiKey);

    return hasValidBaseUrl && hasValidCredentials;
  }

  return false;
}

export const SUPPORTED_LANGUAGES = ['en', 'de'] as const;
export type SUPPORTED_LANGUAGE_TYPE = 'en' | 'de';

export const DATE_FORMATS = new Map([
  ['af-ZA', 'yyyy/MM/dd'],
  ['am-ET', 'd/M/yyyy'],
  ['ar-AE', 'dd/MM/yyyy'],
  ['ar-BH', 'dd/MM/yyyy'],
  ['ar-DZ', 'dd-MM-yyyy'],
  ['ar-EG', 'dd/MM/yyyy'],
  ['ar-IQ', 'dd/MM/yyyy'],
  ['ar-JO', 'dd/MM/yyyy'],
  ['ar-KW', 'dd/MM/yyyy'],
  ['ar-LB', 'dd/MM/yyyy'],
  ['ar-LY', 'dd/MM/yyyy'],
  ['ar-MA', 'dd-MM-yyyy'],
  ['ar-OM', 'dd/MM/yyyy'],
  ['ar-QA', 'dd/MM/yyyy'],
  ['ar-SA', 'dd/MM/yy'],
  ['ar-SY', 'dd/MM/yyyy'],
  ['ar-TN', 'dd-MM-yyyy'],
  ['ar-YE', 'dd/MM/yyyy'],
  ['arn-CL', 'dd-MM-yyyy'],
  ['as-IN', 'dd-MM-yyyy'],
  ['az-Cyrl-AZ', 'dd.MM.yyyy'],
  ['az-Latn-AZ', 'dd.MM.yyyy'],
  ['ba-RU', 'dd.MM.yy'],
  ['be-BY', 'dd.MM.yyyy'],
  ['bg-BG', 'dd.M.yyyy'],
  ['bn-BD', 'dd-MM-yy'],
  ['bn-IN', 'dd-MM-yy'],
  ['bo-CN', 'yyyy/M/d'],
  ['br-FR', 'dd/MM/yyyy'],
  ['bs-Cyrl-BA', 'd.M.yyyy'],
  ['bs-Latn-BA', 'd.M.yyyy'],
  ['ca-ES', 'dd/MM/yyyy'],
  ['co-FR', 'dd/MM/yyyy'],
  ['cs-CZ', 'd.M.yyyy'],
  ['cy-GB', 'dd/MM/yyyy'],
  ['da-DK', 'dd-MM-yyyy'],
  ['de-AT', 'dd.MM.yyyy'],
  ['de-CH', 'dd.MM.yyyy'],
  ['de-DE', 'dd.MM.yyyy'],
  ['de-LI', 'dd.MM.yyyy'],
  ['de-LU', 'dd.MM.yyyy'],
  ['dsb-DE', 'd. M. yyyy'],
  ['dv-MV', 'dd/MM/yy'],
  ['el-GR', 'd/M/yyyy'],
  ['en-029', 'MM/dd/yyyy'],
  ['en-AU', 'd/MM/yyyy'],
  ['en-BZ', 'dd/MM/yyyy'],
  ['en-CA', 'dd/MM/yyyy'],
  ['en-GB', 'dd/MM/yyyy'],
  ['en-IE', 'dd/MM/yyyy'],
  ['en-IN', 'dd-MM-yyyy'],
  ['en-JM', 'dd/MM/yyyy'],
  ['en-MY', 'd/M/yyyy'],
  ['en-NZ', 'd/MM/yyyy'],
  ['en-PH', 'M/d/yyyy'],
  ['en-SG', 'd/M/yyyy'],
  ['en-TT', 'dd/MM/yyyy'],
  ['en-US', 'M/d/yyyy'],
  ['en-ZA', 'yyyy/MM/dd'],
  ['en-ZW', 'M/d/yyyy'],
  ['es-AR', 'dd/MM/yyyy'],
  ['es-BO', 'dd/MM/yyyy'],
  ['es-CL', 'dd-MM-yyyy'],
  ['es-CO', 'dd/MM/yyyy'],
  ['es-CR', 'dd/MM/yyyy'],
  ['es-DO', 'dd/MM/yyyy'],
  ['es-EC', 'dd/MM/yyyy'],
  ['es-ES', 'dd/MM/yyyy'],
  ['es-GT', 'dd/MM/yyyy'],
  ['es-HN', 'dd/MM/yyyy'],
  ['es-MX', 'dd/MM/yyyy'],
  ['es-NI', 'dd/MM/yyyy'],
  ['es-PA', 'MM/dd/yyyy'],
  ['es-PE', 'dd/MM/yyyy'],
  ['es-PR', 'dd/MM/yyyy'],
  ['es-PY', 'dd/MM/yyyy'],
  ['es-SV', 'dd/MM/yyyy'],
  ['es-US', 'M/d/yyyy'],
  ['es-UY', 'dd/MM/yyyy'],
  ['es-VE', 'dd/MM/yyyy'],
  ['et-EE', 'd.MM.yyyy'],
  ['eu-ES', 'yyyy/MM/dd'],
  ['fa-IR', 'MM/dd/yyyy'],
  ['fi-FI', 'd.M.yyyy'],
  ['fil-PH', 'M/d/yyyy'],
  ['fo-FO', 'dd-MM-yyyy'],
  ['fr-BE', 'd/MM/yyyy'],
  ['fr-CA', 'yyyy-MM-dd'],
  ['fr-CH', 'dd.MM.yyyy'],
  ['fr-FR', 'dd/MM/yyyy'],
  ['fr-LU', 'dd/MM/yyyy'],
  ['fr-MC', 'dd/MM/yyyy'],
  ['fy-NL', 'd-M-yyyy'],
  ['ga-IE', 'dd/MM/yyyy'],
  ['gd-GB', 'dd/MM/yyyy'],
  ['gl-ES', 'dd/MM/yy'],
  ['gsw-FR', 'dd/MM/yyyy'],
  ['gu-IN', 'dd-MM-yy'],
  ['ha-Latn-NG', 'd/M/yyyy'],
  ['he-IL', 'dd/MM/yyyy'],
  ['hi-IN', 'dd-MM-yyyy'],
  ['hr-BA', 'd.M.yyyy.'],
  ['hr-HR', 'd.M.yyyy'],
  ['hsb-DE', 'd. M. yyyy'],
  ['hu-HU', 'yyyy. MM. dd.'],
  ['hy-AM', 'dd.MM.yyyy'],
  ['id-ID', 'dd/MM/yyyy'],
  ['ig-NG', 'd/M/yyyy'],
  ['ii-CN', 'yyyy/M/d'],
  ['is-IS', 'd.M.yyyy'],
  ['it-CH', 'dd.MM.yyyy'],
  ['it-IT', 'dd/MM/yyyy'],
  ['iu-Cans-CA', 'd/M/yyyy'],
  ['iu-Latn-CA', 'd/MM/yyyy'],
  ['ja-JP', 'yyyy/MM/dd'],
  ['ka-GE', 'dd.MM.yyyy'],
  ['kk-KZ', 'dd.MM.yyyy'],
  ['kl-GL', 'dd-MM-yyyy'],
  ['km-KH', 'yyyy-MM-dd'],
  ['kn-IN', 'dd-MM-yy'],
  ['ko-KR', 'yyyy. MM. dd'],
  ['kok-IN', 'dd-MM-yyyy'],
  ['ky-KG', 'dd.MM.yy'],
  ['lb-LU', 'dd/MM/yyyy'],
  ['lo-LA', 'dd/MM/yyyy'],
  ['lt-LT', 'yyyy.MM.dd'],
  ['lv-LV', 'yyyy.MM.dd.'],
  ['mi-NZ', 'dd/MM/yyyy'],
  ['mk-MK', 'dd.MM.yyyy'],
  ['ml-IN', 'dd-MM-yy'],
  ['mn-MN', 'yy.MM.dd'],
  ['mn-Mong-CN', 'yyyy/M/d'],
  ['moh-CA', 'M/d/yyyy'],
  ['mr-IN', 'dd-MM-yyyy'],
  ['ms-BN', 'dd/MM/yyyy'],
  ['ms-MY', 'dd/MM/yyyy'],
  ['mt-MT', 'dd/MM/yyyy'],
  ['nb-NO', 'dd.MM.yyyy'],
  ['ne-NP', 'M/d/yyyy'],
  ['nl-BE', 'd/MM/yyyy'],
  ['nl-NL', 'd-M-yyyy'],
  ['nn-NO', 'dd.MM.yyyy'],
  ['nso-ZA', 'yyyy/MM/dd'],
  ['oc-FR', 'dd/MM/yyyy'],
  ['or-IN', 'dd-MM-yy'],
  ['pa-IN', 'dd-MM-yy'],
  ['pl-PL', 'dd.MM.yyyy'],
  ['prs-AF', 'dd/MM/yy'],
  ['ps-AF', 'dd/MM/yy'],
  ['pt-BR', 'd/M/yyyy'],
  ['pt-PT', 'dd-MM-yyyy'],
  ['qut-GT', 'dd/MM/yyyy'],
  ['quz-BO', 'dd/MM/yyyy'],
  ['quz-EC', 'dd/MM/yyyy'],
  ['quz-PE', 'dd/MM/yyyy'],
  ['rm-CH', 'dd/MM/yyyy'],
  ['ro-RO', 'dd.MM.yyyy'],
  ['ru-RU', 'dd.MM.yyyy'],
  ['rw-RW', 'M/d/yyyy'],
  ['sa-IN', 'dd-MM-yyyy'],
  ['sah-RU', 'MM.dd.yyyy'],
  ['se-FI', 'd.M.yyyy'],
  ['se-NO', 'dd.MM.yyyy'],
  ['se-SE', 'yyyy-MM-dd'],
  ['si-LK', 'yyyy-MM-dd'],
  ['sk-SK', 'd. M. yyyy'],
  ['sl-SI', 'd.M.yyyy'],
  ['sma-NO', 'dd.MM.yyyy'],
  ['sma-SE', 'yyyy-MM-dd'],
  ['smj-NO', 'dd.MM.yyyy'],
  ['smj-SE', 'yyyy-MM-dd'],
  ['smn-FI', 'd.M.yyyy'],
  ['sms-FI', 'd.M.yyyy'],
  ['sq-AL', 'yyyy-MM-dd'],
  ['sr-Cyrl-BA', 'd.M.yyyy'],
  ['sr-Cyrl-CS', 'd.M.yyyy'],
  ['sr-Cyrl-ME', 'd.M.yyyy'],
  ['sr-Cyrl-RS', 'd.M.yyyy'],
  ['sr-Latn-BA', 'd.M.yyyy'],
  ['sr-Latn-CS', 'd.M.yyyy'],
  ['sr-Latn-ME', 'd.M.yyyy'],
  ['sr-Latn-RS', 'd.M.yyyy'],
  ['sv-FI', 'd.M.yyyy'],
  ['sv-SE', 'yyyy-MM-dd'],
  ['sw-KE', 'M/d/yyyy'],
  ['syr-SY', 'dd/MM/yyyy'],
  ['ta-IN', 'dd-MM-yyyy'],
  ['te-IN', 'dd-MM-yy'],
  ['tg-Cyrl-TJ', 'dd.MM.yy'],
  ['th-TH', 'd/M/yyyy'],
  ['tk-TM', 'dd.MM.yy'],
  ['tn-ZA', 'yyyy/MM/dd'],
  ['tr-TR', 'dd.MM.yyyy'],
  ['tt-RU', 'dd.MM.yyyy'],
  ['tzm-Latn-DZ', 'dd-MM-yyyy'],
  ['ug-CN', 'yyyy-M-d'],
  ['uk-UA', 'dd.MM.yyyy'],
  ['ur-PK', 'dd/MM/yyyy'],
  ['uz-Cyrl-UZ', 'dd.MM.yyyy'],
  ['uz-Latn-UZ', 'dd/MM yyyy'],
  ['vi-VN', 'dd/MM/yyyy'],
  ['wo-SN', 'dd/MM/yyyy'],
  ['xh-ZA', 'yyyy/MM/dd'],
  ['yo-NG', 'd/M/yyyy'],
  ['zh-CN', 'yyyy/M/d'],
  ['zh-HK', 'd/M/yyyy'],
  ['zh-MO', 'd/M/yyyy'],
  ['zh-SG', 'd/M/yyyy'],
  ['zh-TW', 'yyyy/M/d'],
  ['zu-ZA', 'yyyy/MM/dd'],
]);

export function getServiceStatusLocalizationKey(status: ServiceStatus): string {
  switch (status) {
    case ServiceStatus.REQUESTED:
      return 'global.service-status.requested';
    case ServiceStatus.INPROGRESS:
      return 'global.service-status.inprogress';
    case ServiceStatus.KNOCKING:
      return 'global.service-status.knocking';
    case ServiceStatus.CANCELEDBYCUSTOMER:
      return 'global.service-status.canceled_by_customer';
    case ServiceStatus.CANCELEDBYSTAFF:
      return 'global.service-status.canceled_by_staff';
    case ServiceStatus.ENTER:
      return 'global.service-status.enter';
    case ServiceStatus.DONE:
      return 'global.service-status.done';
    case ServiceStatus.CANCELBYMANAGER:
      return 'global.service-status.canceled_by_manager';
    case ServiceStatus.EMPTY:
    default:
      return '';
  }
}

export function getServiceTypeLocalizationKey(type: EventType): string {
  switch (type) {
    case EventType.INDOOR:
      return 'global.service-types.indoor';
    case EventType.OUTDOOR:
      return 'global.service-types.outdoor';
    case EventType.REFRESH:
      return 'global.service-types.refresh-room';
    case EventType.LOCK:
      return 'global.service-types.lock-room';
    case EventType.MESSAGE:
      return 'global.service-types.message';
    case EventType.GUEST_ENTERED_ROOM:
      return 'global.service-types.guest-entered-room';
    case EventType.BREAKDELAY:
      return 'global.service-types.break-delay';
    default:
      return '';
  }
}

export function isMeeting(obj: unknown): obj is Meeting {
  const meeting = obj as Meeting;
  return (
    meeting.name !== undefined &&
    meeting.name !== null &&
    meeting.id !== undefined &&
    meeting.roomId !== null
  );
}
